import React from 'react';
import {css, StyleSheet} from "aphrodite";
import ReactModal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';

import theme from "../theme/mellon";

const styles = StyleSheet.create({
    modal: {
        position: 'absolute',
        // inset: 40,
        // border: '1px solid rgb(204, 204, 204)',
        border: 'none',
        // background: 'rgb(255, 255, 255)',
        background: 'transparent',
        overflow: 'auto',
        borderRadius: 4,
        outline: 'none',
        // padding: 9,
        height: '100%',
        width: '100%',
        margin: 'auto',
        zIndex: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    overlay: {
        // position: 'fixed',
        // inset: 0,
        // backgroundColor: 'rgba(157,233,87,0.46)',
        // height: '100%',
        // width: '100%',
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        zIndex: 12,
    },
    title: {
        fontSize: 30,
        fontWeight: "bold",
        // paddingBottom: 10,
        color: theme.palette.primary.main
    },
    modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderBottom: "1px solid",
        borderColor: theme.palette.primary.main
    },
    modalCloseButton: {
        // fontSize: 40,
        cursor: "pointer",
        ':hover': {
            color: theme.palette.primary.dark,
        }
    },
    modalButtonIcon: {
        color: theme.palette.primary.main,
        width: 40,
        height: 40,
        border: '1px solid',
        borderRadius: 5,
        borderColor: theme.palette.primary.main,
        marginLeft: 5,
    },
    modalButtonsContainer: {
        display: 'flex'
    },
    paper: {
        height: '100%',
        width: '100%',
        maxHeight: "calc(100% - 10px)",
        maxWidth: "calc(100% - 10px)",
        backgroundColor: "white",
        borderRadius: '4px',
        boxShadow: theme.shadows[4],
        padding: 10,
        overflow: "auto"
    },
    test: {
        backgroundColor: "red"
    },
    zoomImageContainer: {
        maxHeight: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    zoomImage: {
        maxHeight: '100%',
        maxWidth: '100%'
    }
});


const Zoom = props => {
    const {title, children, isOpen, handleClose, parentId, contentClassName, src} = props;
    const nodeRef = React.useRef(null);

    return (
        <ReactModal
            isOpen={isOpen}
            onClose={handleClose}
            ariaHideApp={false} /* Boolean indicating if the appElement should be hidden */
            parentSelector={() => document.getElementById(parentId)} /* Function that will be called to get the parent element that the modal will be attached to. */
            // className={css(styles.modal)} /* String className to be applied to the modal content. See the `Styles` section for more details. */
            // overlayClassName={css(styles.overlay)} /* String className to be applied to the overlay. See the `Styles` section for more details. */
            // aria-labelledby="transition-modal-title"
            // aria-describedby="transition-modal-description"
            // style={{ overlay: {}, content: {}} } /* Object indicating styles to be used for the modal. It has two keys, `overlay` and `content`. See the `Styles` section for more details. */
            // onAfterOpen={handleAfterOpenFunc} /* Function that will be run after the modal has opened. */
            // onAfterClose={handleAfterCloseFunc} /* Function that will be run after the modal has closed. */}
            // onRequestClose={handleRequestCloseFunc} /* Function that will be run when the modal is requested to be closed (either by clicking on overlay or pressing ESC). Note: It is not called if isOpen is changed by other means. */}
            // closeTimeoutMS={0} /* Number indicating the milliseconds to wait before closing the modal. */}
            // contentLabel={"Example Modal"} /* String indicating how the content container should be announced to screenreaders */
            // portalClassName={"ReactModalPortal"} /* String className to be applied to the portal. See the `Styles` section for more details. */}
            // overlayClassName={"ReactModal__Overlay"} /* String className to be applied to the overlay. See the `Styles` section for more details. */}
            // id={"some-id"} /* String id to be applied to the content div. */
            // bodyOpenClassName={"ReactModal__Body--open"} /* String className to be applied to the modal ownerDocument.body (must be a constant string). This attribute when set as `null` doesn't add any class to document.body. See the `Styles` section for more details. */
            // htmlOpenClassName={"ReactModal__Html--open"} /* String className to be applied to the modal ownerDocument.html (must be a constant string). This attribute is `null` by default. See the `Styles` section for more details. */
            // shouldFocusAfterRender={true} /* Boolean indicating if the modal should be focused after render. */
            // shouldCloseOnOverlayClick={true} /* Boolean indicating if the overlay should close the modal */
            // shouldCloseOnEsc={true} /* Boolean indicating if pressing the esc key should close the modal Note: By disabling the esc key from closing the modal you may introduce an accessibility issue. */
            // shouldReturnFocusAfterClose={true} /* Boolean indicating if the modal should restore focus to the element that had focus prior to its display. */
            // role={"dialog"} /* String indicating the role of the modal, allowing the 'dialog' role to be applied if desired. This attribute is `dialog` by default. */
            // preventScroll={false} /* Boolean indicating if the modal should use the preventScroll flag when restoring focus to the element that had focus prior to its display. */
            // aria={{labelledby: "heading", describedby: "full_description"}} /* Additional aria attributes (optional). */
            // data={{ background: "green" }} /* Additional data attributes (optional). */
            // testId={""} /* String testId that renders a data-testid attribute in the DOM, useful for testing. */
            // overlayRef={setOverlayRef} /* Overlay ref callback. */
            // contentRef={setContentRef} /* Content ref callback. */
            overlayElement={(props, contentElement) => <div className={css(styles.overlay)} >{contentElement}</div>} /* Custom Overlay element. */
            contentElement={(props, childrenElements) => (
                <div className={css(styles.modal)} ref={nodeRef}>
                    <div className={css(styles.paper)}>
                        <div className={css(styles.modalHeader)}>
                            <div id="transition-modal-title" className={css(styles.title)}>{title}</div>
                            <div className={css(styles.modalButtonsContainer)}>
                                <div className={css(styles.modalCloseButton)} onClick={handleClose}>
                                    <CloseIcon className={css(styles.modalButtonIcon)}/>
                                </div>
                            </div>
                        </div>
                        <div className={css(styles.zoomImageContainer)}>
                            <img src={src} className={css(styles.zoomImage)} alt="zoom"/>
                        </div>
                    </div>
                </div>
            )} /* Custom Content element. */
        >
            {children}
        </ReactModal>
    );
}

export default Zoom;
