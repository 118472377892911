import {FormControl, InputLabel} from "@mui/material";
import Select from "@mui/material/Select";
import {css} from "aphrodite";
import styles from "./styles";
import MenuItem from "@mui/material/MenuItem";
import {Vidyo} from "../../../../Models";
import React from "react";

const MicrophoneSelect = (props) => {
  const {microphones, selectedMicrophoneId, selectMicrophoneById} = props
  if ( microphones ) {
    return (
      <FormControl>
        <InputLabel id={'microphoneIdLabel'}>Mic</InputLabel>
        <Select name="microphoneId" id={"microphoneId"} labelId={'microphoneIdLabel'}
                value={selectedMicrophoneId || ''} onChange={selectMicrophoneById}
                className={css(styles.deviceSelect)} label={'Mic'}>
          {Object.keys(microphones)?.map(micId =>
            <MenuItem  value={micId} key={micId}>
              { Vidyo.formatDeviceName(microphones[micId]?.name) }
            </MenuItem >
          )}
        </Select>
      </FormControl>
    )
  }
  return null;
}
export default MicrophoneSelect;
