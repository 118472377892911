import {css} from "aphrodite";
import styles from "./styles";

const scenario1 =
    <div className="scenarioContainer">
        <div className={css(styles.scenarioHeader)}>Σενάριο 1</div>

        <div className={css(styles.phaseTitle)}>1η φάση Αρχική (ΣΥΝΑΙΝΕΣΗ/ΕΝΗΜΕΡΩΣΗ)</div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            Καλημέρα/Καλησπέρα/Χαίρεται, καλώς ήρθατε στην υπηρεσία εξ αποστάσεως ηλεκτρονικής
            ταυτοποίησης του Οργανισμού. <b><u>Συναινείτε</u></b> στη καταγραφή και αποθήκευση της διαδικασίας της εξ αποστάσεως ταυτοποίησης,
            τη λήψη στιγμιότυπων του προσώπου σας και του εγγράφου ταυτοποίησης σας και ενδεχομένως άλλων απαραίτητων πληροφοριών,
            την επεξεργασία και τήρηση όλων των δεδομένων και των αρχείων που απαιτούνται ;
            <u><i>Παύση για αναμονή απάντησης:  η διαδικασία θα προχωρήσει <b>μόνο αν ο ταυτοποιούμενος δώσει καταφατική απάντηση</b> στην ερώτηση.</i></u>
        </div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            Για να προχωρήσουμε στην ταυτοποίηση σας θα ακολουθήσουν κάποιες ενέργειες και θα σας καθοδηγήσω να τις ολοκληρώσουμε.
            Επίσης θα σας θέσω κάποιες ερωτήσεις κατά την διάρκεια της διαδικασίας. Μπορούμε να ξεκινήσουμε;
            <span className={css(styles.highlightYellow)}>
                <b>
                    Ενδεικτικές ενέργειες σε περίπτωση που χρειαζόμαστε περισσότερα στοιχεία για την ποιότητα του ήχου και της εικόνας:
                    Παρακαλώ απομακρυνθείτε/πλησιάστε στο μικρόφωνο και πείτε μου το ονοματεπώνυμό σας / Παρακαλώ απομακρυνθείτε λίγο
                    από την κάμερα/ πλησιάστε λίγο την κάμερα προς το μέρος σας.
                </b>
            </span>
        </div>

        <div className={css(styles.phaseTitle)}>2η φάση Liveness Detection</div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            Παρακαλώ παραμείνετε σε ευθεία θέση απέναντι στην κάμερα του κινητού και σε απόσταση που θα είναι εμφανές το πρόσωπο σας
            και το πάνω μέρος του σώματος σας. Ελάτε λίγο πιο κοντά στην κάμερα/ απομακρυνθείτε ελαφρώς από την κάμερα.
            Ευχαριστώ. <b>Παρακαλώ ανοιγοκλείστε τα μάτια σας.</b> Ευχαριστώ. <b>Παρακαλώ χαμογελάστε.</b> Ευχαριστώ
        </div>

        <div className={css(styles.phaseTitle)}>3η Φάση Επίδειξης Εγγράφου Ταυτοποίησης – Οδηγίες και έλεγχοι</div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            Παρακαλώ <b>επιδείξτε</b> το έγγραφο ταυτοποίησης (<b>ταυτότητα, διαβατήριο</b>,Άδεια παραμονής, ταυτότητα ομογενούς, αστυνομική ταυτότητα)
            και <b>πείτε μου τον αριθμό του</b>.  Παρακαλώ πείτε <b>μου την ημερομηνία και τον τόπο γέννησής σας</b>. Παρακαλώ έχετε διαθέσιμο το έγγραφο
            ταυτοποίησης σας ώστε να σας καθοδηγήσω στην φωτογράφηση και των δύο πλευρών/όψεων του/ των σελίδων που θα σας υποδείξω.
        </div>

        <div className={css(styles.phaseTitle)}>4η Φάση Φωτογράφησης Εγγράφου Ταυτοποίησης</div>
        {/*<div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>*/}
        {/*    <u><b>ΥΠΟΛΟΓΙΣΤΗΣ</b></u> Παρακαλώ <b>τοποθετήστε μπροστά στην κάμερα του υπολογιστή το έγγραφο ταυτοποίηση σας με την μπροστινή όψη/*/}
        {/*    στην σελίδα με την φωτογραφία</b>*/}
        {/*    <br/>*/}
        {/*    και <u>κρατήστε σταθερά χωρίς τα χέρια σας να καλύπτουν κάποιο σημείο</u> ώστε να γίνει η φωτογράφηση. Ευχαριστώ.*/}
        {/*    <b>Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων: Γυρίστε</b> το έγγραφο ταυτοποίησης στη πίσω όψη σε οριζόντια θέση*/}
        {/*    με τρόπο ώστε η υπογραφή να βρίσκεται αριστερά <b>και κρατήστε σταθερά χωρίς τα χέρια σας να καλύπτουν κάποιο σημείο ώστε*/}
        {/*    να γίνει η φωτογράφηση.</b> Ευχαριστώ.*/}
        {/*</div>*/}
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            Παρακαλώ <b>τοποθετήστε σε σταθερή επιφάνεια το έγγραφο ταυτοποίησης</b> σας με την μπροστινή όψη/ στην σελίδα
            με την φωτογραφία ώστε να γίνει η φωτογράφηση.
            <br/>
             Ευχαριστώ.
            <br/>
            <b>Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων:</b>
            {/*Γυρίστε το έγγραφο ταυτοποίησης στη πίσω όψη σε οριζόντια θέση με τρόπο ώστε η υπογραφή να βρίσκεται αριστερά για να γίνει η φωτογράφιση.*/}
            <br/>
            <b>Βήμα 1:</b> Γυρίστε στην πίσω κάμερα του κινητού σας (χρησιμοποιώντας το μπλε κουμπί) για να πάρω φωτογραφία το έγγραφό σας.
            <br/>
            <b>Βήμα 2:</b> Γυρίστε το κινητό και την ταυτότητα/διαβατήριο σε οριζόντια θέση με τρόπο ώστε η υπογραφή να βρίσκεται αριστερά για να πάρω φωτογραφία την εμπρός όψη.
            <br/>
            <b>Βήμα 3:</b> Γυρίστε το κινητό και την ταυτότητα σε κάθετη θέση για να πάρω φωτογραφία την πίσω όψη.
            <br/>
            <b>Βήμα 4:</b> Γυρίστε ξανά στην εμπρός κάμερα για να πάρω φωτογραφία το πρόσωπό σας.
            <br/>
            <b>*Βήμα 2 και 3:</b> Θα πρέπει η φωτογραφία του εγγράφου να λαμβάνει όλη την κάμερα του κινητού
        </div>

        <div className={css(styles.phaseTitle)}>5η Φάση Φωτογράφησης Προσώπου</div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            Θα προχωρήσουμε στην φωτογραφία του προσώπου σας. <b>Παρακαλώ όπως κοιτάξετε ευθεία στην κάμερα του κινητού σας
            και παραμείνετε ακίνητος.</b>
            Σας ευχαριστώ.
            <b>Θα ήθελα τώρα να περάσετε το χέρι σας μπροστά από το πρόσωπο σας.</b> Ευχαριστώ.
            Παρακαλώ <b>περιμένετε να ολοκληρωθεί η διαδικασία ελέγχου.</b> Ευχαριστώ.
        </div>

        <div className={css(styles.phaseTitle)}>6η Φάση Ελέγχων Αυθεντικότητας Εγγράφου Ταυτοποίησης</div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            Κρατήστε <b>το έγγραφο ταυτοποίησής σας από την  πλευρά/ ανοιγμένο στην σελίδα που φέρει την φωτογραφία σας δίπλα
            στο πρόσωπο σας και κοιτάξτε την κάμερα.</b> Παρακαλώ <b>Λυγίστε τώρα το έγγραφο ταυτοποίησής σας.</b> Παρακαλώ <b>περάστε
            το δάχτυλο ή το χέρι σας μπροστά από το έγγραφο ταυτοποίησής σας.</b> Παρακαλώ <b>πείτε μου τον αριθμό του εγγράφου ταυτοποίησής σας.</b>
            Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων: Γυρίστε <b>στο πίσω μέρος του εγγράφου ταυτοποίησής σας
            και πλησιάστε το στην κάμερα. Παρακαλώ πείτε μου την ηλικία σας.</b> Μπορείτε να αφήσετε το έγγραφο ταυτοποίησής σας.
        </div>

        <div className={css(styles.phaseTitle)}>7η Φάση Ελέγχων Νηφαλιότητας και Μη Απειλής ή Εξαναγκασμού</div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            <u>Χωρίς να κοιτάξετε το έγγραφο ταυτοποίησής σας, παρακαλώ πείτε μου <b>τη χώρα έκδοσης του</b> εγγράφου ταυτοποίησής σας.</u>
        </div>

        <div className={css(styles.phaseTitle)}>8η Φάση Αποστολής OTP</div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            <span className={css(styles.highlightYellow)}>Παρακαλώ πείτε μου τον αριθμό του κινητού σας τηλεφώνου</span> για να σας αποσταλεί
            ένας μοναδικός κωδικός/ one time password/ OTP.
            Θα πρέπει να καταχωρήσετε τον αριθμό αυτό πατώντας το μπλε κουμπί στο κάτω μέρος δεξιά της οθόνης του κινητού
            προκειμένου να κάνετε την εισαγωγή του. Ευχαριστώ.
        </div>

        <div className={css(styles.phaseTitle)}>9η Φάση Ολοκλήρωσης Διαδικασίας Ταυτοποίησης</div>
        <div className={css(styles.paragraph)}><div className={css(styles.tab)}></div>
            Ολοκληρώθηκε η διαδικασία της εξ αποστάσεως ηλεκτρονικής ταυτοποίησης. Ευχαριστούμε για τον χρόνο σας.
            <b>Για την εξέλιξη της αίτησης σας θα ενημερωθείτε σύντομα από τον Οργανισμό.</b>
        </div>

    </div>


const arr = [
  '<b>ΚΙΝΗΤΟ ΤΗΛΕΦΩΝΟ</b> Παρακαλώ τοποθετήστε σε σταθερή επιφάνεια το έγγραφο ταυτοποίηση σας με την μπροστινή όψη/ στην σελίδα με την φωτογραφία ώστε να γίνει η φωτογράφηση. Ευχαριστώ. <b>Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων:</b> Γυρίστε το έγγραφο ταυτοποίησης στη πίσω όψη σε οριζόντια θέση με τρόπο ώστε η υπογραφή να βρίσκεται αριστερά για να γίνει η φωτογράφιση.',
]
export default scenario1
