import {FormControl, InputLabel} from "@mui/material";
import Select from "@mui/material/Select";
import {css} from "aphrodite";
import styles from "./styles";
import MenuItem from "@mui/material/MenuItem";
import {Vidyo} from "../../../../Models";
import React from "react";

const SpeakerSelect = (props) => {
  const {speakers, selectedSpeakerId, selectSpeakerById} = props
  if ( speakers ) {
    return (
      <FormControl>
        <InputLabel id={'speakerIdLabel'}>Speaker</InputLabel>
        <Select name="speakerId" id={"speakerId"} labelId={'speakerIdLabel'}
                value={selectedSpeakerId || ''} onChange={selectSpeakerById}
                className={css(styles.deviceSelect)} label={'Speaker'}>
          {Object.keys(speakers)?.map(speakerId =>
            <MenuItem  value={speakerId} key={speakerId}>
              {Vidyo.formatDeviceName(speakers[speakerId]?.name)}
            </MenuItem >
          )}
        </Select>
      </FormControl>
    )
  }
  return null;
}
export default SpeakerSelect
