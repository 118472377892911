/* eslint-disable no-use-before-define */
// eslint-disable-next-line import/no-unresolved
import Cookies from 'js-cookie';
import tokenHasExpired from './tokenHasExpired';
import Logger from "./Logger";
import {isJSON} from './utilities';

const _apiHost = process.env.REACT_APP_API_URL;
const httpRequestLoggingEnabled = process.env.REACT_APP_HTTP_REQUEST_LOGGIN_ENABLED === 'true';

const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

const decodeJwt = (token) => {
    try {
        return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    } catch (e) {
        return null
    }
}

const getUserId = () => {
    const tokens = Cookies.get('tokens');
    if (!tokens) return '';
    const id_Token = parseJwt(JSON.parse(tokens).id_Token);
    return id_Token.sub;
};

const objectToQueryString = (obj) => {
    return Object.keys(obj)
        .map((key) => key + '=' + obj[key])
        .join('&');
};

const generateErrorResponse = (error) => {
    return error.text().then((text) => {
        const errorObject = JSON.parse(text)
        const errorResponse = {
            status: 'error',
            code: errorObject?.StatusCode,
            message: errorObject?.Message,
            exceptionMessage: errorObject?.ExceptionMessage,
        };
        Logger.console("errorResponse", errorResponse)
        return errorResponse
    });
};

/**
 * @param {Response} response The response object
 * @param {Logger} log Optional logger instance
 * @return {String} The Response text
 */
const handleResponse = async (response, log = null) => {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // global.window.location.href = window.location.hostname;
            // global.window.location.href = "";
        } else if (response.status === 500 ) {
            // clear cookie;
            // global.window.location.href = window.location.hostname;
        }
        const errorResult = await generateErrorResponse(response);
        Logger.console("errorResult", errorResult)
        log?.error(errorResult, response.status + " - REQUEST FAILED.")
        return errorResult;
    }

    return response.text().then((text) => {
        log?.success( text,response.status + " - REQUEST SUCCEEDED.")
        try {
            if ( text ) {
                if (isJSON(text)) {
                    return JSON.parse(text);
                }
                return text
            }
            return 'No response text'
        } catch (e) {
            log?.error(e.message, "Request@handleResponse Exception")
            return e.message
        }
    });
};

/**
 * @param {Response} response The response object
 * @param {Logger} log Optional logger instance
 * @return {Blob|null} The blob response
 */
const handleBlobResponse = async (response, log = null) => {
    if (!response.ok) {
        log?.error(response.status + " - REQUEST FAILED.")
        if (response.status === 401) {
        } else if (response.status === 500 ) {
        }
        return null;
    }
    return response.blob().then((blob) => {
        log?.success(`BLOB (binary large object) response`)
        return blob;
    });
};

const defaultHeaders = {
    'Content-Type': 'application/json',
}
const request = async (url, params, method = 'GET') => {
    const log = httpRequestLoggingEnabled ? new Logger({logUuid: true}) : null;
    log?.request(params, method + " " + url)
    const options = {
        method,
        headers: defaultHeaders,
    };
    if (params) {
        if (params.headers) {
            options.headers = params.headers;
        }
        if (method === 'GET' && params.body) {
            url += '?' + objectToQueryString(params.body);
        } else {
            options.body = JSON.stringify(params.body);
        }
    }

    const response = await fetch(_apiHost + url, options);
    if ( params?.isBlob ) {
        return handleBlobResponse(response, log);
    }
    return handleResponse(response, log);
};

const requestRedirectUri = async (url, params, method = 'POST') => {
    const options = {
        method,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    };

    options.body = params.body;

    const response = await fetch(_apiHost + url, options);

    return handleResponse(response);
};

const requestLogin = async (url, params, method = 'POST') => {
    const options = {
        method,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    };

    options.body = params.body;

    const response = await fetch(_apiHost + url, options);

    return handleResponse(response);
};

const login = (url, params) => {
    return requestLogin(url, params);
};


const refreshToken = async () => {
    /** Write your project's refresh token flow  **/
    // const msalInstance = new PublicClientApplication(msalConfig)
    // const accounts = msalInstance.getAllAccounts()
    // const account = accounts[0]

    // if ( account ) {
    //     const request = {
    //         ...loginRequest,
    //         account: account
    //     }
    //     const authResult = await msalInstance.acquireTokenSilent(request)
    //     Logger.log("refreshToken acquireTokenSilent result.accessToken", authResult.accessToken)
    //     Logger.log("expiresOn", authResult.expiresOn)
    //     const tokens = {
    //         tokenType: authResult.tokenType,
    //         accessToken: authResult.accessToken,
    //         expiresOn: authResult.expiresOn,
    //         username: authResult.account.username,
    //         name: authResult.account.name
    //     };
    //
    //     Cookies.set('tokens', tokens);
    // }
};

const get = async (url, params) => {
    if (tokenHasExpired()) {
        await refreshToken();
    }
    return request(url, params);
};

const post = async (url, params) => {
    if (tokenHasExpired()) {
        await refreshToken();
    }
    return request(url, params, 'POST');
};

const put = async (url, params) => {
    if (tokenHasExpired()) {
        await refreshToken();
    }
    return request(url, params, 'PUT');
};

const patch = async (url, params) => {
    if (tokenHasExpired()) {
        await refreshToken();
    }
    return request(url, params, 'PATCH');
};

const remove = async (url, params) => {
    if (tokenHasExpired()) {
        await refreshToken();
    }
    return request(url, params, 'DELETE');
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    requestRedirectUri,
    login,
    get,
    post,
    put,
    patch,
    remove,
    getUserId,
    refreshToken,
    decodeJwt,
    parseJwt,
};
