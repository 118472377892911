import { createTheme } from '@mui/material/styles';
const mellon = createTheme({
    palette: {
        typography: {
            fontFamily:
                '-apple-system,system-ui,BlinkMacSystemFont,' +
                '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        },
        primary: {
            light: '#61e2fc',
            main: '#0e67d8',
            dark: '#0e3dd8',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#000',
            main: '#000',
            dark: '#000',
            contrastText: '#000',
        },
        error: {
            main: '#dc004e',
            light: '#000',
            dark: '#000',
            contrastText: '#F5F5F5',
        },
        divider: '#4d4d4d',
        action: {
            active: '#000',
            hover: '#61e2fc',
            selected: '#01a0cb',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
        },
        text: {
            primary: '#000000',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: '#000000',
            hint: '#000000',
            icon: '#000000',
        },
        common: {
            black: '#000',
            white: '#fff',
        },
        background: {
            default: '#F2F2F2',
            light: '#FFFFFF',
            paper: '#F5F5F5',

        },
    },
    colors: {
        white: '#FFFFFF',
        background: '#F5F5F5',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    height: "100%",
                },
                body: {
                    backgroundColor: '#F5F5F5',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                    height: '100%',
                    overflow: 'auto!important',
                },
                '#root': {
                    height: "100%"
                },
                iframe: {
                    border: "none"
                }
            },
        },
    },
});

export default mellon;
