import Logger from "./Logger";

const downloadFile = (blob, fileName) => {
  try {
    if ( blob?.size > 0 ) {
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      return {
        success: true,
        message: "OK",
      }
      // in case the Blob uses a lot of memory
      // setTimeout(() => URL.revokeObjectURL(link.href), 7000);

    } else {
      Logger.log("Empty file")
      return {
        success: false,
        message: "Received file was empty",
      }
    }
  } catch (e) {
    Logger.log(e)
    return {
      success: false,
      message: e.message,
    }
  }

};
const isObject = (objValue) => {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}

const sleep = ms => new Promise(res => setTimeout(res, ms));
// Checks whether the provided string is empty or only contains white-space characters.
const isBlank = (str) => {
  return /^\s*$/.test(str);
}
const isJSON = (str) => {
  if (typeof str !== 'string' || isBlank(str)) {
    return false;
  }
  str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
  str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']');
  str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
  return (/^[\],:{}\s]*$/).test(str);
}
export {
  downloadFile,
  isObject,
  sleep,
  isJSON
};
