const enContact = require('./en/contact.json');
const elContact = require('./el/contact.json');
const enDashboard = require('./en/dashboard.json');
const elDashboard = require('./el/dashboard.json');
const enReport = require('./en/report.json');
const elReport = require('./el/report.json');
const enDefaultPage = require('./en/defaultpage.json');
const elDefaultPage = require('./el/defaultpage.json');
const enHistory = require('./en/history.json');
const elHistory = require('./el/history.json');
const enLogin = require('./en/login.json');
const elLogin = require('./el/login.json');
const enLogOut = require('./en/logout.json');
const elLogOut = require('./el/logout.json');
const enPay = require('./en/pay.json');
const elPay = require('./el/pay.json');
const enProfile = require('./en/profile.json');
const elProfile = require('./el/profile.json');
const enRegister = require('./en/register.json');
const elRegister = require('./el/register.json');
const enSupport = require('./en/support.json');
const elSupport = require('./el/support.json');

const resources = {
  en: {
    contact: enContact,
    dashboard: enDashboard,
    report: enReport,
    defaultPage: enDefaultPage,
    history: enHistory,
    login: enLogin,
    logOut: enLogOut,
    pay: enPay,
    profile: enProfile,
    register: enRegister,
    support: enSupport,
  },
  el: {
    contact: elContact,
    dashboard: elDashboard,
    report: elReport,
    defaultPage: elDefaultPage,
    history: elHistory,
    login: elLogin,
    logOut: elLogOut,
    pay: elPay,
    profile: elProfile,
    register: elRegister,
    support: elSupport,
  },
};

export default resources;
