import xIcon from '../../../common/icons/x.png'
import cropIcon from '../../../common/icons/crop2.png'
import zoomIcon from '../../../common/icons/zoom.png'
import offIcon from '../../../common/icons/off.png'
import recordIcon from '../../../common/icons/record.svg'
import documentIcon from '../../../common/icons/document.svg'
import upStackArrowIcon from '../../../common/icons/up-stack.svg'
import downStackArrowIcon from '../../../common/icons/down-stack.svg'
import styles from "./styles";
import {css} from "aphrodite";
const CloseIcon = () => <img src={xIcon} alt="xIcon" className={css(styles.screenshotToolbarButtonIconDanger)}/>
const CropIcon = () => <img src={cropIcon} alt="cropIcon" className={css(styles.screenshotToolbarButtonIcon)}/>
const ZoomIcon = () => <img src={zoomIcon} alt="zoomIcon" className={css(styles.screenshotToolbarButtonIcon)}/>
const OffIcon = () => <img src={offIcon} alt="offIcon" className={css(styles.footerToolbarButtonIcon)}/>
const RecordIcon = () => <img src={recordIcon} alt="recordIcon" className={css(styles.recordIcon, styles.blink)}/>
const DocumentIcon = () => <img src={documentIcon} alt="documentIcon" className={css(styles.footerToolbarButtonIcon)}/>
const ExpandIcon = () => <img src={downStackArrowIcon} alt="cropIcon" className={css(styles.sidebarToolbarButtonIcon)}/>
const CollapseIcon = () => <img src={upStackArrowIcon} alt="cropIcon" className={css(styles.sidebarToolbarButtonIcon)}/>

export {
  CloseIcon, CropIcon, ZoomIcon, OffIcon, RecordIcon, DocumentIcon, ExpandIcon, CollapseIcon
}
