import Model from "../Model";
import {dispatchHelper, isObject, Logger, notify} from '../../Utils';
import * as reduxType from "../../State/modules/vidyo/types";
import {createSelector} from "reselect";
import {store} from "../../State/store";
import {vidyoConnectorState, vidyoConnectorStateDescription, vidyoDeviceState} from "./VidyoEnumerations";
import {MdiClient} from "../index";
import uuid from 'react-uuid';

const packetIdentifier = process.env.REACT_APP_VIDYO_CHAT_DTP_IDENTIFIER

const vidyo = () => store.getState().vidyo;
class Vidyo extends Model {
    constructor(data) {
        /** Configure your model here **/
        super(data, { // params object and any properties inside are optional. Include only what you need or nothing at all
            // Only fields in _fillable will be writeable. Empty or absent _fillables  will not evaluate at all
            _fillable: [
                'host', 'roomKey', 'displayName', 'status', 'state', 'version', 'vidyoConnector','isVideoInterceptorActivated',
                'connectionProperties', 'roomName', 'stateDescription', 'connected', 'isVideoBackgroundActive',
                'cameras', 'selectedCameraId', 'isLocalCameraEnabled', 'selectedCameraName', 'cameraViewId', 'remoteCameraViewId',
                'microphones', 'selectedMicrophoneId', 'isLocalMicrophoneEnabled', 'selectedMicrophoneName',
                'speakers', 'selectedSpeakerId', 'isLocalSpeakerEnabled', 'selectedSpeakerName',
                'participant', 'participantName', 'conferenceId', 'callId', 'sendBandwidth', 'receiveBandwidth', 'receivedMessage', 'isRemoteParticipantConnected',
              'sendBandwidthPercent', 'receiveBandwidthPercent',
                'isReceivingIDFront', 'idFrontReceiveProgress',
                'isReceivingIDBack', 'idBackReceiveProgress',
                'isReceivingSelfie', 'selfieReceiveProgress',
            ],
            // Fields in _guarded will not be writeable
            _guarded: [],
            _log: new Logger({printTimestamp: false}),
            // Fields in _hidden will not be included in the "values" (values returns an object with this instance's data fields) result
            _hidden: [
                'idFrontImageBuffer', 'idFrontImageBufferCursor',
                'idBackImageBuffer', 'idBackImageBufferCursor',
                'selfieImageBuffer', 'selfieImageBufferCursor'
            ],
            // Fields in _casts will be type cast before returned. e.g. in the example below id property will be returned as string regardless
            _casts: {
                id: String,
                isPaid: Number,
                paidAt: Boolean
            },
            // Specify custom getters and setters for fields. If a field is not in _overrides, the default getter and setter will be used.
            _overrides : {
                createdAt: {
                    get: () => {
                        return this["_createdAt"].toLocaleString() + " CUSTOM GETTER SUFFIX";
                    },
                    set: (newValue) => {
                        this["_createdAt"] = new Date(newValue)
                    }
                },
                selectedCameraName: {
                    get: () => {
                        return this.getSelectedCamera()?.name
                    }
                },
                selectedMicrophoneName: {
                    get: () => {
                        return this.getSelectedMicrophone()?.name
                    }
                },
                stateDescription: {
                    get: () => {
                        if ( this.state) {
                            if ( vidyoConnectorStateDescription[this.state]) {
                                return vidyoConnectorStateDescription[this.state]
                            } else {
                                return this.state
                            }
                        }
                        return "Not initialized"
                    }
                },
                roomName: {
                    get: () => {
                        let displayName = ''
                        if ( Array.isArray(this.connectionProperties)) {
                            this.connectionProperties.forEach((prop) => {
                                if (prop.name === 'Room.displayName') {
                                    displayName =  prop.value;
                                }
                            });
                        }
                        return displayName;
                    }
                },
                connected: {
                    get: () => {
                        return this.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected
                    }
                }
            }
        });
        this.state = vidyoConnectorState.VIDYO_CONNECTORSTATE_Idle
        if ( !data.cameraViewId) {
            this.cameraViewId = process.env.REACT_APP_VIDYO_LOCAL_CAMERA_VIEW_ID
        }
        if ( !data.remoteCameraViewId) {
            this.remoteCameraViewId = process.env.REACT_APP_VIDYO_REMOTE_CAMERA_VIEW_ID
        }
        if (process.env.REACT_APP_VIDYO_ENABLE_VIDEO_BACKGROUND === 'true' ) {
            if (!(this.isVideoBackgroundActive === false || this.isVideoBackgroundActive === true)) {
                this.isVideoBackgroundActive = (process.env.REACT_APP_VIDYO_DEFAULT_VIDEO_BACKGROUND_STATE === 'enabled');
            }
        }

        this.save();
    }

    get log() {
        return this._log
    }

    initializeVidyoConnector = async () => {
        if ( window.VidyoClientLib ) {
            this.log.info('initializing Vidyo Connector...')
            const vidyoClient = new window.VidyoClientLib.VidyoClient("", initResult => {
                const {state, description} = initResult;
                this.log.info(description, "Vidyo Client initialization description")
                this.state = state;
            })
            this.log.info('VidyoConnector State before creation: ' + this.state)
            this.save()
            const vidyoConnector = await vidyoClient.CreateVidyoConnector({
                viewId: null,
                viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
                remoteParticipants: 2,
                // logFileFilter: 'debug@VidyoClient debug@VidyoSDP debug@VidyoResourceManager all@VidyoSignaling',
                logFileFilter: 'error@VidyoClient',
                logFileName: '',
                userData: 0,
                constraints: {
                    disableGoogleAnalytics: true,
                },
            })
            this.log.info('VidyoConnector instance created')
            Logger.console(vidyoConnector)
            if ( vidyoConnector ) {
                this.vidyoConnector = vidyoConnector;
                this.versionFull = await vidyoConnector.GetVersion();
                this.version = await vidyoConnector.GetVersionWithoutBuildNumber();
                this.save()
                this.registerDeviceListeners()
                this.registerParticipantEventListener()
                this.registerResourceManagerEventListener()
                this.registerMessageEventListener()
            }
            await this.updateVidyoState()
            this.log.info('VidyoConnector State after creation: ' + this.state)
            if (this.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Ready) {
                // await this.vidyoConnector?.SetTCPTransport({enable: true})
                // await vidyo.vidyoConnector?.SetUDPTransport({enable: true})
            }
        }
    }

    destroy = async () => {
        this.vidyoConnector?.Disable()
        this.vidyoConnector?.Destruct()
        this.vidyoConnector = null
    }

    connectToConference = async () => {
        const vidyo = this
        vidyo.vidyoConnector?.SetAdvancedConfiguration({
            loggerURL: '',
            extData: '',
            extDataType: '',
            showStatisticsOverlay: process.env.REACT_APP_VIDYO_SHOW_STATISTICS_OVERLAY === "true"
        });

        const connectResult = await vidyo.vidyoConnector?.ConnectToRoomAsGuest({
            host: vidyo.host,
            roomKey: vidyo.roomKey ,
            displayName: vidyo.displayName,
            // roomPin: null,
            onSuccess() {
                if ( vidyo.handleConnectSuccess ) {
                    vidyo.handleConnectSuccess()
                }
                vidyo._handleConnectSuccess()
                vidyo.save()

            },
            onDisconnected(reason) {
                if ( vidyo.handleDisconnect ) {
                    vidyo.handleDisconnect(reason)
                }
                vidyo._handleDisconnect(reason)
                vidyo.save()
            },
            onFailure(reason) {
                if ( vidyo.handleConnectFailure ) {
                    vidyo.handleConnectFailure(reason)
                }
                vidyo._handleConnectFailure(reason)
                vidyo.save()
            }
        })

        vidyo.log.info(connectResult, "ConnectToRoomAsGuest Result")
    }

    disconnect = async () => {
        if ( this.vidyoConnector ) {
            this.vidyoConnector?.Disconnect();
            await this.updateVidyoState()

        }
    }

    _handleConnectSuccess = async (reason) => {
        // this.connectionProperties = await this.vidyoConnector?.GetConnectionProperties();
        this.updateVidyoState()
    }

    _handleDisconnect = (reason) => {
        this.log.info(reason, '_handleDisconnect reason')
        this.participantName = ''
        this.conferenceId = ''
        this.callId = ''
        this.availableBandwidth = null
        // this.state = reason
        this.updateVidyoState()
    }
    _handleConnectFailure = (reason) => {
        this.log.info(reason, '_handleConnectFailure reason')
        // this.state = reason
        this.participantName = ''
        this.conferenceId = ''
        this.callId = ''
        this.availableBandwidth = null
        this.updateVidyoState()
    }

    addCamera = (camera) => {
        this.cameras = {
            ...this.cameras,
            [camera.id]: camera
        }
    }
    removeCamera = (camera) => {
        if ( camera?.id && this.cameras[camera.id]) {
            delete this.cameras[camera.id]
        }
    }
    addMicrophone = (mic) => {
        this.microphones = {
            ...this.microphones,
            [mic.id]: mic
        }
    }
    removeMicrophone = (mic) => {
        if ( mic?.id && this.microphones[mic.id]) {
            delete this.microphones[mic.id]
        }
    }
    addSpeaker = (speaker) => {
        this.speakers = {
            ...this.speakers,
            [speaker.id]: speaker
        }
    }
    removeSpeaker = (speaker) => {
        if ( speaker?.id && this.speakers[speaker.id]) {
            delete this.speakers[speaker.id]
        }
    }
    hideView = async (viewId) => {
        await this.vidyoConnector?.HideView({
            viewId: viewId
        });
    }

    hideLocalCameraView = () => {
        this.hideView(this.cameraViewId)
    }
    hideRemoteCameraView = () => {
        this.hideView(this.remoteCameraViewId)
    }
    selectLocalCamera = async (camera) => {
        await this.vidyoConnector?.SelectLocalCamera({
            localCamera: camera
        })
    }

    selectCameraById = async (cameraId) => {
        if (this.cameras[cameraId]) {
            await this.selectLocalCamera(this.cameras[cameraId])
        }
    }
    selectLocalMic = (mic) => {
        this.vidyoConnector?.SelectLocalMicrophone({
            localMicrophone: mic
        })
    }
    selectMicById = (micId) => {
        if (this.microphones[micId]) {
            this.selectLocalMic(this.microphones[micId])
        }
    }
    selectLocalSpeaker = (speaker) => {
        this.vidyoConnector?.SelectLocalSpeaker({
            localSpeaker: speaker
        })
    }
    selectSpeakerById = (speakerId) => {
        if (this.speakers[speakerId]) {
            this.selectLocalSpeaker(this.speakers[speakerId])
        }
    }
    selectDefaultDevice = (type) => {
        const deviceTypes = ["camera", "microphone", "speaker"]
        const deviceTypeIdAttributeNames = {
            camera: "selectedCameraId",
            microphone: "selectedMicrophoneId",
            speaker: "selectedSpeakerId"
        }
        const deviceTypeSelectors = {
            camera: this.selectLocalCamera,
            microphone: this.selectLocalMic,
            speaker: this.selectLocalSpeaker
        }
        if ( deviceTypes.includes(type) ) {
            const devices = this[type + "s"]
            let defaultDevice = null
            let selectedDevice = null
            if ( devices && isObject(devices)) {
                const keys = Object.keys(devices)
                // Find the default device
                keys.forEach(key => {
                    if ( devices[key].id === "default") {
                        defaultDevice = devices[key]
                        Logger.console("default " + type + " found", defaultDevice)
                    }
                })
                // Find the original device that corresponds to the default device
                if ( defaultDevice ) {
                    const deviceVPID = Vidyo.getDeviceVPID(defaultDevice)
                    Logger.console("default " + type + " VID:PID", deviceVPID)
                    // Find and select original device by VID:PID
                    if ( deviceVPID ) {
                        keys.forEach(key => {
                            if ( Vidyo.getDeviceVPID(devices[key]) === deviceVPID && Vidyo.getAudioDeviceType(devices[key]) === 0) {
                                selectedDevice = devices[key]
                                Logger.console("Selected " + type + " with VID:PID " + deviceVPID, selectedDevice)
                            }
                        })
                    }
                    // If device didn't have VID:PID or an original device with that VID:PID was not found, find and select the first original device
                    if ( !selectedDevice ) {
                        Logger.console(type + " with VID:PID " + deviceVPID + " was not found")
                        Logger.console("Searching for first original device")
                        keys.forEach(key => {
                            if ( !selectedDevice && Vidyo.getAudioDeviceType(devices[key]) === 0) {
                                selectedDevice = devices[key]
                                Logger.console("selected first original " + type, selectedDevice)
                            }
                        })
                    }
                    // If an original device was not found, select the first device in collection
                    if ( !selectedDevice ) {
                        selectedDevice = devices[keys[0]];
                        Logger.console("selected first " + type + " in collection", selectedDevice)
                    }
                } else {
                    // If a default device was not found, select the first device in collection
                    selectedDevice = devices[keys[0]];
                    Logger.console("selected first " + type + " in collection 2", selectedDevice)
                }
                if ( this[deviceTypeIdAttributeNames[type]] !== selectedDevice.id) {
                    deviceTypeSelectors[type](selectedDevice)
                }
            }
        }

    }
    getSelectedCamera = () => {
        if ( this.selectedCameraId && this.cameras && this.cameras[this.selectedCameraId]) {
            return this.cameras[this.selectedCameraId]
        } else {
            return null
        }
    }
    getSelectedMicrophone = () => {
        if ( this.selectedMicrophoneId && this.microphones && this.microphones[this.selectedMicrophoneId]) {
            return this.microphones[this.selectedMicrophoneId]
        } else {
            return null
        }
    }

    showSelectedLocalCamera = async () => {
        const selectedCamera = this.getSelectedCamera()
        if (selectedCamera) {
            try {
                this.vidyoConnector?.AssignViewToLocalCamera({
                    viewId: this.cameraViewId,
                    localCamera: this.getSelectedCamera(),
                    displayCropped: false,
                    allowZoom: false
                });
            } catch (e) {
                this.log?.error("Vidyo@showSelectedLocalCamera - Assign View to Local Camera failed: ", e.message, selectedCamera)
            }
            if ( this.isVideoBackgroundActive ) {
                await this.toggleVideoBackground(true)
            }
        }
    }
    startCamera = () => {
        const selectedCamera = this.getSelectedCamera()
        this.isVideoBackgroundActive = true;
        if ( selectedCamera ) {
            this.selectLocalCamera(selectedCamera)
        }
        this.vidyoConnector?.SetCameraPrivacy({privacy: false})
    }
    stopCamera = () => {
        this.vidyoConnector?.SetCameraPrivacy({privacy: true})
    }
    startMic = () => {
        this.vidyoConnector?.SetMicrophonePrivacy({privacy:false})
    }
    stopMic = () => {
        this.vidyoConnector?.SetMicrophonePrivacy({privacy:true})
    }
    startSpeaker = () => {
        this.vidyoConnector?.SetSpeakerPrivacy({privacy:false})
    }
    stopSpeaker = () => {
        this.vidyoConnector?.SetSpeakerPrivacy({privacy:true})
    }
    registerDeviceListeners = () => {
        this.registerLocalCameraEventListener()
        this.registerLocalMicrophoneEventListener()
        this.registerLocalSpeakerEventListener()
        this.registerRemoteCameraEventListener()
        this.registerRemoteMicrophoneEventListener()
        this.registerRemoteMicrophoneEnergyListener()
        // if ( process.env.REACT_APP_ENABLE_SCREEN_SHARING ) {
        //     this.registerLocalMonitorEventListener()
        //     this.registerLocalWindowShareEventListener()
        //     this.registerRemoteWindowShareEventListener()
        // }

        this.save()
    }

    registerLocalCameraEventListener = () => {
        const vidyo = this;
        vidyo.vidyoConnector?.RegisterLocalCameraEventListener({
            onAdded(localCamera) {
                vidyo.log.info(localCamera, "Local Camera Added")
                Logger.console(localCamera)
                vidyo.addCamera(localCamera)
                vidyo.save();
            },
            async onRemoved(localCamera) {
                vidyo.log.info(localCamera, "Local Camera Removed")
                vidyo.removeCamera(localCamera)
                if ( vidyo.selectedCameraId === localCamera.id ) {
                    await vidyo.hideView(vidyo.cameraViewId)
                    vidyo.vidyoConnector.SelectDefaultCamera();
                }
            },
            async onSelected(localCamera) {
                vidyo.log.info(localCamera, "Local Camera Selected" )
                if ( localCamera ) {
                    try {
                        const vidyoConstraintConfiguration = Vidyo.getVidyoConstraintConfiguration()
                        if ( vidyoConstraintConfiguration ) {
                            await localCamera?.SetMaxConstraint(vidyoConstraintConfiguration);
                        }
                        await vidyo.vidyoConnector?.AssignViewToLocalCamera({
                            viewId: vidyo.cameraViewId,
                            localCamera: localCamera,
                            displayCropped: false,
                            allowZoom: false
                        });
                        vidyo.selectedCameraId = localCamera.id
                    } catch (e) {
                        vidyo.log?.error("Vidyo@onSelected - Assign View to Local Camera failed: ", e.message)
                    }
                    if ( vidyo.isVideoBackgroundActive ) {
                        await vidyo.toggleVideoBackground(true)
                    }
                }

                vidyo.save()
            },
            async onStateUpdated(localCamera, state) {
                vidyo.log.info(JSON.stringify(localCamera) + "\n" + state + "\n", "Local Camera State Updated")
                if (Vidyo.isDeviceStateDisabled(state)) {
                    vidyo.isLocalCameraEnabled = false
                    if ( vidyo.isVideoBackgroundActive ) {
                        await vidyo.toggleVideoBackground(false)
                    }
                    if (vidyo.requestToReset) {
                        vidyo.requestToReset = false
                        vidyo.startCamera()
                    }
                } else if (Vidyo.isDeviceStateEnabled(state)) {
                    vidyo.isLocalCameraEnabled = true
                    try {
                        const onStateUpdateAssignResult = await vidyo.vidyoConnector?.AssignViewToLocalCamera({
                            viewId: vidyo.cameraViewId,
                            localCamera: localCamera,
                            displayCropped: false,
                            allowZoom: false
                        });
                        Logger.console("RegisterLocalCameraEventListener@onStateUpdated() - AssignResult\n=>", onStateUpdateAssignResult)
                    } catch (e) {
                        vidyo.log?.error("Vidyo@onStateUpdated - Assign View to Local Camera failed: ", e.message)
                    }
                    if ( vidyo.isVideoBackgroundActive ) {
                        await vidyo.toggleVideoBackground(true)
                    }
                }
                vidyo.save()
            },
        })
    }

    registerLocalMicrophoneEventListener = async () => {
        const vidyo = this;
        await vidyo.vidyoConnector?.RegisterLocalMicrophoneEventListener({
            async onAdded(localMicrophone) {
                // vidyo.log.info(localMicrophone, "Local Microphone Added")
                Logger.console("Local Microphone Added", localMicrophone)
                vidyo.addMicrophone(localMicrophone)
                vidyo.selectDefaultDevice("microphone")
                // await vidyo.vidyoConnector?.SelectDefaultMicrophone()
            },
            onRemoved(localMicrophone) {
                // vidyo.log.info(localMicrophone, "Local Microphone Removed")
                Logger.console("Local Microphone Removed", localMicrophone)
                vidyo.removeMicrophone(localMicrophone)
            },
            async onSelected(localMicrophone) {
                // vidyo.log.info(localMicrophone, "Local Microphone Selected" )
                Logger.console("Local Microphone Selected", localMicrophone)
                vidyo.selectedMicrophoneId = localMicrophone?.id
                await vidyo.vidyoConnector?.SetMicrophonePrivacy({privacy:false})
                vidyo.isLocalMicrophoneEnabled = true;
                vidyo.save()
            },
            onStateUpdated(localMicrophone, state) {
                vidyo.log.info(JSON.stringify(localMicrophone) + "\n" + state,"Local Microphone State Updated", )
                if (Vidyo.isDeviceStateDisabled(state)) {
                    vidyo.isLocalMicrophoneEnabled = false
                } else if (
                    Vidyo.isDeviceStateEnabled(state)
                ) {
                    vidyo.isLocalMicrophoneEnabled = true
                }
                vidyo.save()
            },
        })
    }
    registerLocalSpeakerEventListener = () => {
        const vidyo = this
        vidyo.vidyoConnector?.RegisterLocalSpeakerEventListener({
            onAdded(localSpeaker) {
                // vidyo.log.info(localSpeaker, "Local Speaker Added")
                Logger.console("Local Speaker Added", localSpeaker)
                vidyo.addSpeaker(localSpeaker)
                vidyo.selectDefaultDevice("speaker")
                // vidyo.selectLocalSpeaker(localSpeaker)
                // vidyo.vidyoConnector.SelectDefaultSpeaker()

            },
            onRemoved(localSpeaker) {
                // vidyo.log.info(localSpeaker, "Local Speaker Removed")
                Logger.console("Local Speaker Removed", localSpeaker)
                vidyo.removeSpeaker(localSpeaker)
            },
            async onSelected(localSpeaker) {
                // vidyo.log.info(localSpeaker, "Local Speaker selected")
                Logger.console("Local Speaker selected", localSpeaker)
                vidyo.selectedSpeakerId = localSpeaker?.id
                await vidyo.vidyoConnector?.SetSpeakerPrivacy({privacy:false})
                vidyo.isLocalSpeakerEnabled = true
                vidyo.save()
            },
            onStateUpdated(localSpeaker, state) {
                vidyo.log.info(JSON.stringify(localSpeaker) + "\n" + state, "Local Speaker State Updated: \n")
                if (Vidyo.isDeviceStateDisabled(state)) {
                    vidyo.isLocalSpeakerEnabled = false
                } else if (
                    Vidyo.isDeviceStateEnabled(state)
                ) {
                    vidyo.isLocalSpeakerEnabled = true
                }
                vidyo.save()
            },
        })
    }
    registerRemoteCameraEventListener = () => {
        const vidyo = this
        vidyo.vidyoConnector?.RegisterRemoteCameraEventListener({
            async onAdded(remoteCamera, participant) {
                vidyo.log.info(JSON.stringify(remoteCamera) + "\n" + JSON.stringify(participant), "Remote Camera Added: \n")
                vidyo.save()
                await vidyo.vidyoConnector?.AssignViewToRemoteCamera({
                    viewId: vidyo.remoteCameraViewId || process.env.REACT_APP_VIDYO_REMOTE_CAMERA_VIEW_ID,
                    remoteCamera: remoteCamera,
                    displayCropped: false,
                    allowZoom: false
                });
            },
            async onRemoved(remoteCamera, participant) {
                vidyo.log.info(JSON.stringify(remoteCamera) + "\n" + JSON.stringify(participant), "Remote Camera Removed: \n")
                await vidyo.hideView(vidyo.remoteCameraViewId || process.env.REACT_APP_VIDYO_REMOTE_CAMERA_VIEW_ID)
            },
            onStateUpdated(remoteCamera, participant, state) {
                vidyo.log.info(JSON.stringify(remoteCamera) + "\n" + JSON.stringify(participant)+ "\n" + state, "Remote Camera Updated: \n")
            },
        })
    }
    registerRemoteMicrophoneEventListener = () => {
        const vidyo = this
        vidyo.vidyoConnector?.RegisterRemoteMicrophoneEventListener({
            onAdded(remoteMicrophone, participant) {
                vidyo.log.info(JSON.stringify(remoteMicrophone) + "\n" + JSON.stringify(participant), "Remote Microphone Added: \n")
            },
            onRemoved(remoteMicrophone, participant) {
                vidyo.log.info(JSON.stringify(remoteMicrophone) + "\n" + JSON.stringify(participant), "Remote Microphone Removed: \n")
            },
            onStateUpdated(remoteMicrophone, participant) {
                vidyo.log.info(JSON.stringify(remoteMicrophone) + "\n" + JSON.stringify(participant), "Remote Microphone Updated: \n")
            },
        })
    }
    registerRemoteMicrophoneEnergyListener = () => {
        // const vidyo = this;
        this.vidyoConnector?.RegisterRemoteMicrophoneEnergyListener({
            onEnergy(remoteMicrophone, remoteParticipant, energyLevelInfo) {
                // console.log("RegisterRemoteMicrophoneEnergyListener@onEnergy - remoteMicrophone", remoteMicrophone)
                // console.log("RegisterRemoteMicrophoneEnergyListener@onEnergy - energyLevelInfo", energyLevelInfo)
                // console.log("RegisterRemoteMicrophoneEnergyListener@onEnergy - something", energyLevelInfo)
            }
        })
    }

    registerParticipantEventListener = () => {
        const vidyo = this
        vidyo.vidyoConnector?.RegisterParticipantEventListener({
            onJoined(participant) {
                vidyo.log.success(JSON.stringify(participant), "Remote Participant Joined:")
                vidyo.participant = participant
                vidyo.participantName = participant?.name
                notify(`Participant ${participant.name} joined the room`, "success", false)
                vidyo.save()
                setTimeout(() => {
                    vidyo.startRecording()
                }, 3000);
            },
            onLeft(participant) {
                vidyo.participant = null
                vidyo.participantName = ''
                vidyo.save()
                vidyo.log.error(JSON.stringify(participant), "Remote Participant Left:")
                notify(`Participant ${participant.name} left the room`, "warn", 5000)
            },
            onDynamicChanged(participants) {
                vidyo.log.info(JSON.stringify(participants), "Ordered array of participants according to rank:")
            },
            onLoudestChanged(participant, audioOnly) {
                vidyo.log.info(`${JSON.stringify(participant)}\n${audioOnly}`, "Current loudest speaker:")
                /* Current loudest speaker */
            }
        })
    }

    registerResourceManagerEventListener = () => {
        const vidyo = this
        vidyo.vidyoConnector?.RegisterResourceManagerEventListener({
            onAvailableResourcesChanged: this.onAvailableResourcesChanged,
            onMaxRemoteSourcesChanged: this.onMaxRemoteSourcesChanged}
        )
    }
    /**@param cpuEncodeObj number
     * @param cpuDecodeObj number
     * @param bandwidthSendObj number
     * @param bandwidthReceiveObj number
     * lower numbers means bandwidth degrades. 100 - you have 100% capacity to transmit the expected resolution at expected framerate.
     * 75% - goes bad. 50% - close to bad, 25% - very bad and below
     */
    onAvailableResourcesChanged = (cpuEncodeObj, cpuDecodeObj, bandwidthSendObj, bandwidthReceiveObj) => {
        // eslint-disable-next-line max-len
        // Logger.console("%conAvailableResourcesChangedCallback: %c" + 'cpuEncodeObj=' + cpuEncodeObj +
        //   '; cpuDecodeObj=' + cpuDecodeObj + '; bandwidthSendObj=' + bandwidthSendObj + '; ' +
        //   'bandwidthReceiveObj=' + bandwidthReceiveObj, 'color: red;', 'color: salmon;');
        this.sendBandwidthPercent = bandwidthSendObj;
        this.receiveBandwidthPercent = bandwidthReceiveObj;
        this.save();
    }

    onMaxRemoteSourcesChanged = (maxRemoteSourcesObj) => {

        Logger.console('%conMaxRemoteSourcesChangedCallback EXAMPLE: %c maxRemoteSourcesObj=' + maxRemoteSourcesObj, 'color: red;', 'color: salmon;');
    }

    registerMessageEventListener = () => {
        const vidyo = this;
        vidyo.vidyoConnector?.RegisterMessageEventListener({
            onChatMessageReceived(participant, chatMessage) {
                vidyo.handleMessageReceived(participant, chatMessage)
            }
        })
    }

    handleMessageReceived = (participant, chatMessage) => {
        if ( chatMessage?.body?.length && chatMessage.body.slice(0, packetIdentifier.length) === packetIdentifier) {
            const indicatorPrecision = parseInt(process.env.REACT_APP_VIDYO_CHAT_DOWNLOAD_INDICATOR_PRECISION || '10')
            const dataString = chatMessage.body.slice(packetIdentifier.length)
            const payload = JSON.parse(dataString)
            if ( payload.type === "CAP" ) {
                const photo = Vidyo.takeLocalVideoScreenshot()
                this.sendBase64Image(payload.id, photo)
            } else if (payload.type === "RST") {
                // Resetting Audio/Video Devices
                if ( payload.id === 'cam' ) {
                    this.stopCamera()
                    // this.requestToReset = true
                    this.startCamera()
                } else if (payload.id === 'mic') {
                    this.stopMic()
                    this.startMic()
                } else if (payload.id === 'spk') {
                    this.stopSpeaker()
                    this.startSpeaker()
                } else if (payload.id === 'all') {
                    this.stopCamera()
                    this.stopMic()
                    this.stopSpeaker()
                    this.startCamera()
                    this.startMic()
                    this.startSpeaker()
                }
            } else if (payload.type === "INI") {
                try {
                    if ( payload.id === 'IDFront' ) {
                        MdiClient.setIDFrontImageUri(null)
                        this.idFrontImageBuffer = Array(payload.data)
                        this.idFrontImageBufferCursor = 0
                        this.isReceivingIDFront = true
                        Vidyo.setIDFrontReceiveProgress(0)
                    } else if ( payload.id === 'IDBack' ) {
                        MdiClient.setIDBackImageUri(null)
                        this.idBackImageBuffer = Array(payload.data)
                        this.idBackImageBufferCursor = 0
                        this.isReceivingIDBack = true
                        Vidyo.setIDBackReceiveProgress(0)
                    } else if ( payload.id === 'selfie' ) {
                        MdiClient.setSelfieImageUri(null)
                        this.selfieImageBuffer = Array(payload.data)
                        this.selfieImageBufferCursor = 0
                        this.isReceivingSelfie = true
                        Vidyo.setSelfieReceiveProgress(0)
                    }
                    this.save()
                } catch (e) {
                    this._log.error(e.message, "Vidyo@handleMessageReceived -> Exception thrown while processing received INI packet")
                }
            } else if (payload.type === "DAT") {
                try {
                    if ( payload.id === 'IDFront' ) {
                        this.idFrontImageBufferCursor = payload.index
                        this.idFrontImageBuffer[this.idFrontImageBufferCursor] = payload.data
                        const progress = parseInt((this.idFrontImageBufferCursor / this.idFrontImageBuffer.length) * 100)
                        if ( parseInt(progress) % indicatorPrecision === 0 && this.idFrontReceiveProgress !== progress) {
                            this.idFrontReceiveProgress = progress
                            Vidyo.setIDFrontReceiveProgress(progress)
                        }
                    } else if ( payload.id === 'IDBack' ) {
                        this.idBackImageBufferCursor = payload.index
                        this.idBackImageBuffer[this.idBackImageBufferCursor] = payload.data
                        const progress = parseInt((this.idBackImageBufferCursor / this.idBackImageBuffer.length) * 100)
                        if ( parseInt(progress) % indicatorPrecision === 0 && this.idBackReceiveProgress !== progress) {
                            this.idBackReceiveProgress = progress
                            Vidyo.setIDBackReceiveProgress(progress)
                        }
                    } else if ( payload.id === 'selfie' ) {
                        this.selfieImageBufferCursor = payload.index
                        this.selfieImageBuffer[this.selfieImageBufferCursor] = payload.data
                        const progress = parseInt((this.selfieImageBufferCursor / this.selfieImageBuffer.length) * 100)
                        if ( parseInt(progress) % indicatorPrecision === 0 && this.selfieReceiveProgress !== progress) {
                            this.selfieReceiveProgress = progress
                            Vidyo.setSelfieReceiveProgress(progress)
                        }
                    }
                } catch (e) {
                    this._log.error(e.message, "Vidyo@handleMessageReceived -> Exception thrown while processing received DAT packet")
                }
            } else if (payload.type === 'END') {
                try {
                    if ( payload.id === 'IDFront' ) {
                        Vidyo.setIDFrontReceiveProgress(100)
                        const prefix = this.idFrontImageBuffer[0].slice(0, 10) === 'data:image' ? '' : 'data:image/jpeg;base64,'
                        MdiClient.setIDFrontImageUri({imgUri: prefix + this.idFrontImageBuffer.join(""), uuid: uuid()})
                        this.isReceivingIDFront = false
                    } else if ( payload.id === 'IDBack' ) {
                        Vidyo.setIDBackReceiveProgress(100)
                        const prefix = this.idBackImageBuffer[0].slice(0, 10) === 'data:image' ? '' : 'data:image/jpeg;base64,'
                        MdiClient.setIDBackImageUri({imgUri: prefix + this.idBackImageBuffer.join(""), uuid: uuid()})
                        this.isReceivingIDBack = false
                    } else if ( payload.id === 'selfie' ) {
                        Vidyo.setSelfieReceiveProgress(100)
                        const prefix = this.selfieImageBuffer[0].slice(0, 10) === 'data:image' ? '' : 'data:image/jpeg;base64,'
                        MdiClient.setSelfieImageUri({imgUri: prefix + this.selfieImageBuffer.join(""), uuid: uuid()})
                        this.isReceivingSelfie = false
                    }
                } catch (e) {
                    this._log.error(e.message, "Vidyo@handleMessageReceived -> Exception thrown while processing received END packet")
                }
                this.save()
            }
        } else {

            this.showMessage(chatMessage.body)
            this.log.success("Message received")
            Logger.console(chatMessage)
            this.log.success( "From participant")
            Logger.console(participant)
            const typeMessage = chatMessage.body.split('_');
            if ( typeMessage ) {
                if (typeMessage[0] === 'switch') {
                    // Why cycle camera and mic?

                    // window.CycleCamera();
                    // await window.CycleMicrophone();
                    // await window.CycleMicrophone();
                    // await window.CycleMicrophone();
                }
            } else if (typeMessage[0] === 'mobile') {
                this.showMessage(typeMessage[1]);
            } else if (typeMessage[0] === 'otp') {
                // alert('show the otp form');
            }
        }

    }

    showMessage = (message) => {
        this.log.success(message, "Showing Message")
        this.receivedMessage = message
        this.save()
    }

    sendGroupChatMessage = (message) => {
        const sendMessageResult = this.vidyoConnector.SendChatMessage({
            message: message
        })
        if ( sendMessageResult ) {
            this._log.success(sendMessageResult, "Message transmitted successfully")
        } else {
            this._log.error(sendMessageResult, "Message failed to transmit")
        }
    }

    sendPrivateChatMessage = async (message) => {
        if ( message.length > process.env.REACT_APP_VIDYO_CHAT_MAX_MESSAGELENGTH ) {
            this._log.error("Vidyo@sendPrivateChatMessage: Message exceeds maximum allowed size")
            return false
        }
        if ( !this.participant ) {
            this._log.error("Vidyo@sendPrivateChatMessage: No participant found")
            return false
        }
        if ( !message ) {
            this._log.error("Vidyo@sendPrivateChatMessage: Message cannot be empty")
            return false
        }
        try {
            return await this.vidyoConnector.SendPrivateChatMessage({
                participant: this.participant,
                message: message
            })
        } catch (e) {
            this._log.error(e.message, "Vidyo@sendPrivateChatMessage Exception")
            return false
        }
    }
    sendBase64Image = async (imgType, base64Image) => {
        const size = base64Image.length
        const chunkSize = parseInt(process.env.REACT_APP_VIDYO_CHAT_DTP_CHUNK_SIZE)
        if ( size ) {
            let chunkCount = Math.floor(size / chunkSize)
            const rem = size % chunkSize
            if ( rem > 0 ) {
                chunkCount++
            }
            this.selfieImageBuffer = []
            this.selfieImageBufferCursor = 0
            for (let i = 0; i < chunkCount; i++) {
                const index = this.selfieImageBufferCursor * chunkSize
                this.selfieImageBuffer.push(base64Image.slice(index, index + chunkSize))
                this.selfieImageBufferCursor++
            }
            const initPacket = {
                id: imgType,
                type: "INI",
                data: chunkCount,
            }
            await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(initPacket))
            for (let i = 0; i < this.selfieImageBuffer.length; i++) {
                const dtpPacket = {
                    id: imgType,
                    index: i,
                    type: "DAT",
                    data: this.selfieImageBuffer[i],
                }
                await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(dtpPacket))
            }
            const endPacket = {
                id: imgType,
                type: "END",
                index: this.selfieImageBuffer.length,
                data: size,
            }
            await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(endPacket))
        }
    }
    requestRemoteCameraCapture = async (imgType) => {
        if (['IDFront', 'IDBack', 'selfie'].includes(imgType)) {
            const packet = {
                id: imgType,
                type: "CAP",
            }
            return await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(packet))
        } else {
            Logger.error("Vidyo.js@requestRemoteCameraCapture -> Unsupported type: " + imgType)
            return false
        }

    }
    requestRemoteDevicesReset = async (device) => {
        if (['all', 'cam', 'mic', 'spk'].includes(device)) {
            const packet = {
                id: device,
                type: "RST",
            }
            return await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(packet))
        } else {
            Logger.error("Vidyo.js@requestRemoteDevicesReset -> Unsupported device type: " + device)
            return false
        }
    }
    startRecording = async () => {
        await MdiClient.startRecording()
    }
    stopRecording = () => {
        // Check if recording before attempting to stop
        this.log.success("RECORDING STOPPED")
    }
    toggleVideoBackground = async (force = null) => {
        if ( process.env.REACT_APP_VIDYO_ENABLE_VIDEO_BACKGROUND === 'true') {

            if ( ( this.isVideoBackgroundActive && force === null ) || force === false) {
                this.isVideoBackgroundActive = false;
                if ( this.isVideoInterceptorActivated ) {
                    this.log?.info("Disabling Video Background")
                    await this.vidyoConnector?.UnregisterLocalCameraStreamInterceptor()
                    this.isVideoInterceptorActivated = false;
                }
                this.save();
            } else if ( ( !this.isVideoBackgroundActive && force === null )  || force === true ) {
                this.isVideoBackgroundActive = true;
                if ( !this.isVideoInterceptorActivated ) {
                    this.log?.info("Enabling Video Background")
                    try {
                        const mediaPipePlugin = await import('./MediaPipePlugin.js');
                        const streamInterceptorSucceeded = await this.vidyoConnector?.RegisterLocalCameraStreamInterceptor(mediaPipePlugin.mediaPipeVideoBackground)
                        if ( streamInterceptorSucceeded ) {
                            this.isVideoInterceptorActivated = true;
                            this.log?.success("RegisterLocalCameraStreamInterceptor SUCCESS")
                        } else {
                            this.isVideoInterceptorActivated = false;
                            this.log?.error("RegisterLocalCameraStreamInterceptor FAILED")
                        }
                    } catch (e) {
                        Logger.console("vidyoConnector?.RegisterLocalCameraStreamInterceptor(mediaPipePlugin.mediaPipeVideoBackground) EXCEPTION:\n", e)
                        this.isVideoInterceptorActivated = false;
                    }

                }
                this.save();
            }
        }
    }
    updateVidyoState = async () => {
        try {
            if ( this.vidyoConnector ) {
                this.connectionProperties = await this.vidyoConnector.GetConnectionProperties();
                this.state = await this.vidyoConnector.GetState()
                this.log.info(this.state, "updateVidyoState ")
            } else {
                this.state = null
            }
            this.save()
        } catch (e) {
            this._log.warning("Exception thrown while updating Vidyo state")
        }
    }

    getStats = async () => {
        if ( this.vidyoConnector ) {
            let conferenceId = ''
            let callId = ''
            let sendBandwidth = 0
            let receiveBandwidth = 0
            try {
                const analyticsJson = await this.vidyoConnector.GetStatsJson()
                const analytics = JSON.parse(analyticsJson)
                // Logger.console("GetStatsJson()", analytics)
                // Logger.console(analytics)
                try {
                    conferenceId =  analytics.userStats[0].roomStats[0].conferenceId;
                    callId =  analytics.userStats[0].roomStats[0].callId;
                    sendBandwidth =  (analytics.userStats[0].roomStats[0].sendBitRateTotal / 1024).toFixed(3);
                    receiveBandwidth =  (analytics.userStats[0].roomStats[0].receiveBitRateTotal / 1024).toFixed(3);

                } catch {
                }
            } catch (e) {
                this.log.error(e, "Vidyo.getStats() Exception")
            } finally {
                let shouldSave = false
                if ( this.conferenceId !== conferenceId ) {
                    this.conferenceId = conferenceId
                    shouldSave = true
                }
                if ( this.callId !== callId ) {
                    this.callId = callId
                    shouldSave = true
                }
                if ( this.sendBandwidth !== sendBandwidth ) {
                    this.sendBandwidth = sendBandwidth
                    shouldSave = true
                }
                if ( this.receiveBandwidth !== receiveBandwidth ) {
                    this.receiveBandwidth = receiveBandwidth
                    shouldSave = true
                }
                if ( shouldSave ) {
                    this.save()
                }
            }
        }
    }

    save = () => {
        this.saveChanges(reduxType);
    };

    isConnected = async () => {
        if ( this.state ) {
            await this.updateVidyoState()
            return this.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected
        } else {
            return false
        }

    }

    static getAudioDeviceName = (device) => {
        if ( device ) {
            const name = device.name
            if ( name && name.length ) {
                let hasDeviceId =  name.slice(-1) === ')' && name.slice(-11, -10) === '('
                if ( name.slice(0,10) === 'Default - ') {
                    return hasDeviceId ? name.slice(10, -12) : name.slice(10)
                } else if ( name.slice(0,13) === 'Προεπιλογή - ') {
                    return hasDeviceId ? name.slice(13, -12) : name.slice(13)
                }
                else if ( name.slice(0,17) === 'Communications - ') {
                    return hasDeviceId ? name.slice(17, -12) : name.slice(17)
                }
                else if ( name.slice(0,15) === 'Επικοινωνίες - ') {
                    return hasDeviceId ? name.slice(15, -12) : name.slice(15)
                } else {
                    return hasDeviceId ? name.slice(0, -12) : name
                }
            }
        }
        return 'Undefined'
    }
     static getAudioDeviceType = (device) => {
         if ( device ) {
             const name = device.name
             if ( name && name.length ) {
                 if ( name.slice(0,10) === 'Default - ' ||  name.slice(0,13) === 'Προεπιλογή - ') {
                     return 1;
                 }
                 else if ( name.slice(0,17) === 'Communications - ' || name.slice(0,15) === 'Επικοινωνίες - ') {
                     return 2;
                 }
                 else {
                     return 0
                 }
             }
         }
         return null
     }
      static getDeviceVPID = (device) => {
          if ( device?.name?.length) {
              if ( device.name.slice(-1) === ')' && device.name.slice(-11, -10) === '(' ) {
                  return device.name.slice(-10, -1)
              }
          }
          return null
      }
    static getDeviceVID = (device) => {
        if ( device?.name?.length) {
            if ( device.name.slice(-1) === ')' && device.name.slice(-11, -10) === '(' ) {
                return device.name.slice(-10, -6)
            }
        }
        return null
    }
    static getDevicePID = (device) => {
        if ( device?.name?.length) {
            if ( device.name.slice(-1) === ')' && device.name.slice(-11, -10) === '(' ) {
                return device.name.slice(-5, -1)
            }
        }
        return null
    }

    static isOriginalAudioDevice = (device) => {
        return device?.id !== 'default' && device?.id !== 'communications'
    }

    static getVidyoConstraintConfiguration = () => {
        const {
            REACT_APP_ENABLE_VIDYO_CONSTRAINT,
            REACT_APP_VIDYO_CONSTRAINT_WIDTH,
            REACT_APP_VIDYO_CONSTRAINT_HEIGHT,
            REACT_APP_VIDYO_CONSTRAINT_FRAME_INTERVAL
        } = process.env;
        Logger.console("vidyo constraint configuration", REACT_APP_ENABLE_VIDYO_CONSTRAINT + ": " + typeof REACT_APP_ENABLE_VIDYO_CONSTRAINT,
            REACT_APP_VIDYO_CONSTRAINT_WIDTH + ": " + typeof REACT_APP_VIDYO_CONSTRAINT_WIDTH,
            REACT_APP_VIDYO_CONSTRAINT_HEIGHT + ": " + typeof REACT_APP_VIDYO_CONSTRAINT_HEIGHT,
            REACT_APP_VIDYO_CONSTRAINT_FRAME_INTERVAL + ": " + typeof REACT_APP_VIDYO_CONSTRAINT_FRAME_INTERVAL)
        if ( REACT_APP_ENABLE_VIDYO_CONSTRAINT === 'true' ) {
            const reactAppVidyoConstraintWidth = parseInt(REACT_APP_VIDYO_CONSTRAINT_WIDTH)
            const reactAppVidyoConstraintHeight = parseInt(REACT_APP_VIDYO_CONSTRAINT_HEIGHT)
            const reactAppVidyoConstraintFrameInterval = parseInt(REACT_APP_VIDYO_CONSTRAINT_FRAME_INTERVAL)
            if ( String(reactAppVidyoConstraintWidth) === REACT_APP_VIDYO_CONSTRAINT_WIDTH &&
                String(reactAppVidyoConstraintHeight) === REACT_APP_VIDYO_CONSTRAINT_HEIGHT &&
                String(reactAppVidyoConstraintFrameInterval) === REACT_APP_VIDYO_CONSTRAINT_FRAME_INTERVAL &&
                reactAppVidyoConstraintWidth >= 320 &&
                reactAppVidyoConstraintHeight >= 180 &&
                reactAppVidyoConstraintFrameInterval >= 5 ) {
                return {
                    width: reactAppVidyoConstraintWidth,
                    height: reactAppVidyoConstraintHeight,
                    frameInterval: reactAppVidyoConstraintFrameInterval
                }
            }
        }
        return null;
    }

    static formatDeviceName = (deviceName) => {
        const parts = deviceName?.split("(") || ['N/A()']
        if ( parts.length > 1 && parts[parts.length - 1].indexOf(":") > -1) {
            parts.pop()
        }
        return parts.join("(")
    }

    static isDeviceStateDisabled = (state) => {
        return state === vidyoDeviceState.VIDYO_DEVICESTATE_Stopped ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Removed ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_InUse ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Error ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Suspended ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Paused
    }
    static isDeviceStateEnabled = (state) => {
        return state === vidyoDeviceState.VIDYO_DEVICESTATE_Started ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Unsuspended ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Available ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Resumed
    }

    static takeRemoteVideoScreenshot = () => {
        const remoteVideoElement = document.querySelector(`#${process.env.REACT_APP_VIDYO_REMOTE_CAMERA_VIEW_ID} video`)
        if ( remoteVideoElement ) {
            const canvas = document.createElement('canvas');
            canvas.width = remoteVideoElement.videoWidth
            canvas.height = remoteVideoElement.videoHeight
            const context = canvas.getContext('2d');
            context.drawImage(remoteVideoElement, 0, 0, canvas.width, canvas.height);
            const dataURI = canvas.toDataURL('image/jpeg');
            Logger.console(dataURI)
            return dataURI
        }
    }
    static takeLocalVideoScreenshot = () => {
        const localVideoElement = document.querySelector(`#${process.env.REACT_APP_VIDYO_LOCAL_CAMERA_VIEW_ID} video`)
        if ( localVideoElement ) {
            const canvas = document.createElement('canvas');
            canvas.width = localVideoElement.videoWidth
            canvas.height = localVideoElement.videoHeight
            const context = canvas.getContext('2d');
            context.drawImage(localVideoElement, 0, 0, canvas.width, canvas.height);
            const dataURI = canvas.toDataURL('image/jpeg');
            // Logger.console(dataURI)
            return dataURI
        }
    }
    /** SELECTORS **/
    static getVidyo = createSelector(vidyo, vidyoState => {
        return vidyoState ? vidyoState : null;
    });
    static getState = createSelector(vidyo, vidyoState => {
        return vidyoState?.state ? vidyoState.state : null;
    });
    static getVidyoIsConnected = createSelector(vidyo, vidyoState => {
        return vidyoState?.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected
    });
    static showSettingsModal = createSelector(vidyo, vidyoState => {
        return !!vidyoState?.showSettingsModal;
    });
    static showOtpModal = createSelector(vidyo, vidyoState => {
        return !!vidyoState?.showOtpModal;
    });
    static showAgentCameraModal = createSelector(vidyo, vidyoState => {
        return !!vidyoState?.showAgentCameraModal;
    });
    static idFrontReceiveProgress = createSelector(vidyo, vidyoState => {
        return vidyoState?.idFrontReceiveProgress || 0;
    });
    static idBackReceiveProgress = createSelector(vidyo, vidyoState => {
        return vidyoState?.idBackReceiveProgress || 0;
    });
    static selfieReceiveProgress = createSelector(vidyo, vidyoState => {
        return vidyoState?.selfieReceiveProgress || 0;
    });
    /** ACTIONS **/
    static toggleSettingsModal = (show) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(reduxType.TOGGLE_SHOW_SETTINGS, show)
        );
    };
    static toggleOtpModal = (show) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(reduxType.TOGGLE_SHOW_OTP_MODAL, show)
        );
    };
    static toggleAgentCameraModal = (show) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(reduxType.TOGGLE_SHOW_AGENT_CAMERA_MODAL, show)
        );
    };
    static setSelfieReceiveProgress = (val) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(reduxType.SET_SELFIE_RECEIVE_PROGRESS, val)
        );
    };
    static setIDFrontReceiveProgress = (val) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(reduxType.SET_IDFRONT_RECEIVE_PROGRESS, val)
        );
    };
    static setIDBackReceiveProgress = (val) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(reduxType.SET_IDBACK_RECEIVE_PROGRESS, val)
        );
    };
}

export default Vidyo;
