import {createReducer} from '../../../Utils';
import * as type from './types';

const initialState = null;

export default createReducer(initialState, {
  [type.SAVE](state, action) {
    return {
      showSettingsModal: !!(state?.showSettingsModal),
      showOtpModal: !!(state?.showOtpModal),
      showAgentCameraModal: !!(state?.showAgentCameraModal),
      ...action.payload,
    };
  },
  [type.TOGGLE_SHOW_SETTINGS](state, action) {
    return {
      ...state,
      showSettingsModal: typeof action.payload === 'boolean' ? action.payload : !state.showSettingsModal
    };
  },
  [type.TOGGLE_SHOW_OTP_MODAL](state, action) {
    return {
      ...state,
      showOtpModal: typeof action.payload === 'boolean' ? action.payload : !state.showOtpModal
    };
  },
  [type.TOGGLE_SHOW_AGENT_CAMERA_MODAL](state, action) {
    return {
      ...state,
      showAgentCameraModal: typeof action.payload === 'boolean' ? action.payload : !state.showAgentCameraModal
    };
  },
  [type.SET_IDFRONT_RECEIVE_PROGRESS](state, action) {
    return {
      ...state,
      idFrontReceiveProgress: action.payload || 0
    };
  },
  [type.SET_IDBACK_RECEIVE_PROGRESS](state, action) {
    return {
      ...state,
      idBackReceiveProgress: action.payload || 0
    };
  },
  [type.SET_SELFIE_RECEIVE_PROGRESS](state, action) {
    return {
      ...state,
      selfieReceiveProgress: action.payload || 0
    };
  },
  // All other action types result in state being returned
});
