import {FormControl, InputLabel} from "@mui/material";
import {css} from "aphrodite";
import styles from "./styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

const DocumentIDSelect = (props) => {
  const {documentIDOptions, selectedDocumentID, setSelectedDocumentID} = props
  return (
    <FormControl className={css(styles.selectContainer)} style={{minWidth: 200, maxWidth: 400}}>
      <InputLabel id={'documentIDLabel'}>Κατηγορία Εγγράφου</InputLabel>
      <Select name="documentID" id={"documentID"}  onChange={setSelectedDocumentID}
              value={selectedDocumentID || ''}
              className={css(styles.countrySelect)} labelId={'documentIDLabel'} label={'Document ID'}
      >
        {documentIDOptions?.map(option => (
          <MenuItem  value={option.value} key={option.value}>
            {option.label}
          </MenuItem >
        ))}
      </Select>
    </FormControl>
  )
}
export default DocumentIDSelect
