import {FormControl, InputLabel} from "@mui/material";
import {css} from "aphrodite";
import styles from "./styles";
import Select from "@mui/material/Select";
import isoCountriesOptions from "../../../../Models/MdiClient/ISO_Countries.json";
import MenuItem from "@mui/material/MenuItem";
import React, {useEffect} from "react";

const CountrySelect = (props) => {
  const {selectedCountryCode, setSelectedCountryCode, selectedDocumentID} = props
  useEffect(() => {
    if ( selectedDocumentID === 1 || selectedDocumentID === 2 ) {
      setSelectedCountryCode({target: {value: "GR"}})
    }
  }, [selectedDocumentID])
  return (
    <FormControl className={css(styles.selectContainer)} style={{minWidth: 200, maxWidth: 400}}>
      <InputLabel id={'countryLabel'}>Χώρα έκδοσης</InputLabel>
      <Select name="country" id={"country"}  onChange={setSelectedCountryCode}
              value={selectedCountryCode || ''}
              className={css(styles.countrySelect)} labelId={'countryLabel'} label={'Issuing Country'}
      >
        {
          isoCountriesOptions?.map(country => {
              return (
                  <MenuItem  value={country.Alpha2Code} key={country.Id}>
                      {country.Alpha3Code + ' - ' + country.Name}
                  </MenuItem >
              )
            }
          )
        }
      </Select>
    </FormControl>
  )
}
export default CountrySelect
