import { createType } from '../../../Utils';

const actionName = 'VIDYO';

export const SAVE = createType.customAction(actionName, 'SAVE');
export const TOGGLE_SHOW_SETTINGS = createType.customAction(actionName, 'TOGGLE_SHOW_SETTINGS');
export const TOGGLE_SHOW_OTP_MODAL = createType.customAction(actionName, 'TOGGLE_SHOW_OTP_MODAL');
export const TOGGLE_SHOW_AGENT_CAMERA_MODAL = createType.customAction(actionName, 'TOGGLE_SHOW_AGENT_CAMERA_MODAL');
export const SET_IDFRONT_RECEIVE_PROGRESS = createType.customAction(actionName, 'SET_IDFRONT_RECEIVE_PROGRESS');
export const SET_IDBACK_RECEIVE_PROGRESS = createType.customAction(actionName, 'SET_IDBACK_RECEIVE_PROGRESS');
export const SET_SELFIE_RECEIVE_PROGRESS = createType.customAction(actionName, 'SET_SELFIE_RECEIVE_PROGRESS');
