import {toast} from "react-toastify";

const notify = (message, type = '', autoClose = false) => {
  const types = ['success', 'error', 'warn', 'info', 'dark', '']
  if ( !types.includes(type) ) {
    type = ''
  }
  toast[type](message, {
    position: 'bottom-left',
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export default notify
