import Cookies from 'js-cookie';

const tokenHasExpired = () => {
  // return authorization header with jwt token

  const tokens = Cookies.get('tokens');
  if (!tokens) {
    return false;
  }
  const lastTimestamp = new Date(JSON.parse(tokens).expiresOn).getTime();
  const currentTimestamp = new Date().getTime();
  return currentTimestamp > lastTimestamp;

};

export default tokenHasExpired;
