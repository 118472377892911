import {StyleSheet} from 'aphrodite';
import theme from '../../../common/theme/mellon';

export default StyleSheet.create({
    navbar: {
        height: 100,
        width: "100%",
        // maxWidth: 1300,
        fontSize: 24,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px 10px 20px',
        // borderStyle: 'solid',
        // borderWidth: 1,
        // borderColor: 'red'
    },
    navbarButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    container: {
        zIndex: 11,
        display: "flex",
        width: "100%",
        justifyContent: "center",
        backgroundColor: theme.colors.white,
        boxShadow: "rgb(0 0 0 / 23%) 1px 0px 12px 0px",
        position: "relative",
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
    },
    navbarColumnContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        marginRight: 15,
    },
    navbarColumnsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    navbarRowContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // flexGrow: 1,
    },
    navbarTitle: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 'bold'
    },
    navbarButton: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 35,
        height: 50,
        width: 50,
        padding: 10,
        marginRight: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    navbarButtonSmall: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 35,
        height: 40,
        width: 40,
        padding: 7,
        marginBottom: 2,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    inputButtonComboContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: 10,
    },
    navbarPhoneInput: {
        width: 120,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 2,
        paddingTop: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        textAlign: 'center',
        borderRadius: 4
    },
    navbarButtonSuccess: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'lightgreen',
        borderRadius: 35,
        height: 50,
        width: 50,
        padding: 10,
        marginRight: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    navbarSquareButton: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'transparent',
        // borderRadius: 35,
        height: 50,
        width: 50,
        padding: 10,
        marginRight: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    navbarSquareButtonSuccess: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'lightgreen',
        // borderRadius: 35,
        height: 50,
        width: 50,
        padding: 10,
        marginRight: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    navbarIcon: {
        height: '100%',
        width: '100%',
    },
    // bandwidthContainer: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'flex-start',
    //     justifyContent: 'center',
    //     paddingLeft: 20,
    // },
    // bandwidth: {
    //     display: 'flex',
    //     justifyContent: 'flex-start',
    //     alignItems: 'center',
    //     fontSize: 20,
    //     fontWeight: 'bold',
    //     whiteSpace: 'nowrap',
    // }
});
//
