import {StyleSheet} from 'aphrodite';
// import theme from '../../../common/theme/mellon';

export default StyleSheet.create({
    scenarioHeader: {
        backgroundColor: 'lime',
        padding: 5,
        fontWeight: 'bold',
    },
    phaseTitle: {
        fontWeight: 'bold',
        fontSize: 18,
        paddingBottom: 5,
        paddingTop: 5,
        marginTop: 20,
        marginBottom: 10,
    },
    paragraph: {
        lineHeight: 1.8,
        fontFamily: "Calibri",
        marginBottom: 10,
    },
    tab: {
        display: 'inline',
        marginLeft: 40,
    },
    highlightYellow: {
        background: 'yellow'
    },
});
