import './App.css';
import theme from "./View/common/theme/mellon"
import {Router, createHistory} from "@reach/router";
import {Default} from "./View/pages/Default";
import {Dashboard} from "./View/pages/Dashboard";
import { StylesProvider} from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const App = () => {
    const history = createHistory(window);

    // useWorker()

    return (
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Router id={"router"} history={history} style={{
            height: "calc(100%)",
            display: "flex",
            justifyContent: "center"
          }}>
            <Dashboard
                exact path="/"
                state={{
                  name: 'Dashboard',
                }}
            />
            <Default default/>
          </Router>
        </ThemeProvider>
      </StylesProvider>
    );
}

export default App;
