import React from 'react';
import { css } from 'aphrodite';
import styles from './styles';
import {useSelector} from "react-redux";
import {MdiClient, Vidyo} from "../../../../Models";
import screenshotIcon from "../../../common/icons/screenshot.png";
import screenshotBackIcon from "../../../common/icons/screenshot_back.png";
import selfieIcon from "../../../common/icons/selfie.png";
import faceMatchIcon from "../../../common/icons/facematch.png";
import formIcon from "../../../common/icons/contact-form.png";
import settingsIcon from "../../../common/icons/setting.png";
import otpIcon from "../../../common/icons/otp.png";
import {Tooltip} from "@mui/material";
import {DocumentIDSelect} from "../../../common/components/DocumentIDSelect";
import {CountrySelect} from "../../../common/components/CountrySelect";
import {documentIDOptions} from '../../../../Models/MdiClient/options';
import {OffIcon} from '../../../common/components/Icons';

const NavBar = (props) => {
    const {closeWorkflow, vidyoRef} = props
    const vidyo = useSelector(Vidyo.getVidyo);
    const vidyoIsConnected = useSelector(Vidyo.getVidyoIsConnected);
    const mdiClient = useSelector(MdiClient.getMdiClient);
    const mdiClientIsLoading = useSelector(MdiClient.isLoading);
    const captureIDFront = () => {
        if ( process.env.REACT_APP_USE_NEW_REMOTE_CAMERA_CAPTURE === 'true' ) {
            vidyoRef?.requestRemoteCameraCapture('IDFront')
        } else {
            mdiClient?.captureFrontIDScreenshot();
        }
    }
    const captureIDBack = () => {
        if ( process.env.REACT_APP_USE_NEW_REMOTE_CAMERA_CAPTURE === 'true' ) {
            vidyoRef?.requestRemoteCameraCapture('IDBack')
        } else {
            mdiClient?.captureBackIDScreenshot();
        }
    }
    const captureSelfie = () => {
        if ( process.env.REACT_APP_USE_NEW_REMOTE_CAMERA_CAPTURE === 'true' ) {
            vidyoRef?.requestRemoteCameraCapture('selfie')
        } else {
            mdiClient?.captureSelfieScreenshot();
        }
    }
  return (
      <div className={css(styles.container)}>
          <div className={css(styles.navbar)}>
              {/*<Logo />*/}
              <div className={css(styles.navbarButtonsContainer)}>
                  {vidyo?.connected && vidyo?.participantName &&
                      <div className={css(styles.navbarRowContainer)}>
                          <DocumentIDSelect documentIDOptions={mdiClient?.documentIDOptions} selectedDocumentID={mdiClient?.selectedDocumentID} setSelectedDocumentID={mdiClient?.setSelectedDocumentID}/>
                          <CountrySelect selectedCountryCode={mdiClient?.selectedCountryCode} setSelectedCountryCode={mdiClient?.setSelectedCountryCode} selectedDocumentID={mdiClient?.selectedDocumentID}/>
                          {mdiClient?.selectedCountryCode && mdiClient?.getSelectedDocumentProperty() && !mdiClientIsLoading &&
                              <Tooltip title={"Εμπρός Όψη"}>
                                  <button className={mdiClient?.IDFront.imageUri ? css(styles.navbarButtonSuccess): css(styles.navbarButton)} onClick={captureIDFront}>
                                      <img src={screenshotIcon} alt="screenshotIcon"
                                           className={css(styles.navbarIcon)}/>
                                  </button>
                              </Tooltip>}
                          {mdiClient?.selectedCountryCode && mdiClient?.getSelectedDocumentProperty() && mdiClient?.getSelectedDocumentProperty()?.twoSide && !mdiClientIsLoading &&
                              <Tooltip title={"Πίσω Όψη"}>
                                  <button className={mdiClient?.IDBack.imageUri ? css(styles.navbarButtonSuccess): css(styles.navbarButton)}
                                          onClick={captureIDBack}>
                                      <img src={screenshotBackIcon} alt="screenshotBackIcon"
                                           className={css(styles.navbarIcon)}/>
                                  </button>
                              </Tooltip>
                          }

                          {mdiClient?.selectedCountryCode && mdiClient?.getSelectedDocumentProperty() && !mdiClientIsLoading &&
                              <Tooltip title={'Πρόσωπο'}>
                                  <button className={mdiClient?.selfie.imageUri ? css(styles.navbarButtonSuccess): css(styles.navbarButton)}
                                          onClick={captureSelfie}>
                                      <img src={selfieIcon} alt="selfieIcon"
                                           className={css(styles.navbarIcon)}/>
                                  </button>
                              </Tooltip>
                          }
                      </div>
                  }
                  {mdiClient?.getSelectedDocumentProperty() && vidyo?.connected &&
                      <Tooltip title={"Αποστολή OTP"}>
                          <button onClick={Vidyo.toggleOtpModal} className={css(styles.navbarSquareButton)}>
                              <img src={otpIcon} alt="otp"
                                   className={css(styles.navbarIcon)}/>
                          </button>
                      </Tooltip>
                  }
                  <div className={css(styles.navbarRowContainer)}>
                      {mdiClient?.canDoFacematch && !mdiClientIsLoading &&
                          <Tooltip title={'Βιομετρικός Έλεγχος'}>
                              <button className={mdiClient?.thalesFaceResult ? css(styles.navbarSquareButtonSuccess): css(styles.navbarSquareButton) }
                                      onClick={mdiClient?.doFaceMatching}>
                                  <img src={faceMatchIcon} alt="faceMatchIcon"
                                       className={css(styles.navbarIcon)}/>
                              </button>
                          </Tooltip>
                      }
                  </div>
                  <div className={css(styles.navbarRowContainer)}>
                      {mdiClient?.canDoFacematch && !mdiClientIsLoading &&
                          <Tooltip title={'Εμφάνιση Αποτελεσμάτων'}>
                              <button className={css(styles.navbarSquareButton) }
                                      onClick={()=> mdiClient?.setShowSubmitModal()}>
                                  <img src={formIcon} alt="formIcon"
                                       className={css(styles.navbarIcon)}/>
                              </button>
                          </Tooltip>
                      }
                  </div>
                  <div className={css(styles.navbarRowContainer)}>
                      {mdiClient?.workflowId && !vidyoIsConnected &&
                          <Tooltip title={'Close Workflow'}>
                              <button onClick={closeWorkflow} className={css(styles.navbarButton)}>
                                  <OffIcon/>
                              </button>
                          </Tooltip>
                      }
                  </div>
              </div>
              <div className={css(styles.navbarRowContainer)}>
                  <Tooltip title={'Ρυθμίσεις'}>
                      <button className={css(styles.navbarButton)}
                              onClick={Vidyo.toggleSettingsModal}>
                          <img src={settingsIcon} alt="settings"
                               className={css(styles.navbarIcon)}/>
                      </button>
                  </Tooltip>
              </div>
          </div>
      </div>
  );
};

const memoizedNavBar = React.memo(NavBar);
export { memoizedNavBar as NavBar };
