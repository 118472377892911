import React from 'react';
import {css, StyleSheet} from "aphrodite";
import Modal from '@mui/material/Modal';

// import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import theme from "../theme/mellon";

const styles = StyleSheet.create({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: "100%",
    },
    title: {
        fontSize: 30,
        fontWeight: "bold",
        paddingBottom: 10,
        color: theme.palette.primary.main
    },
    modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderBottom: "1px solid",
        borderColor: theme.palette.primary.main
    },
    modalCloseButton: {
        fontSize: 40,
        cursor: "pointer",
        color: theme.palette.primary.main,
        ':hover': {
            color: theme.palette.primary.dark,
        }
    },
    paper: {
        // width: "90%",
        maxHeight: "90%",
        maxWidth: 1000,
        minWidth: 800,
        backgroundColor: "white",
        borderRadius: '4px',
        boxShadow: theme.shadows[4],
        padding: theme.spacing(3, 5, 3),
        overflow: "auto"
    },
    test: {
        backgroundColor: "red"
    }
});

const CustomModal = props => {
    const {title, children, open, handleClose, width} = props;

    return (
        <Modal
            className={css(styles.modal)}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            // onClose={handleClose}
            closeAfterTransition

            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
        >
            <Fade in={open}>
                <div className={css(styles.paper)} style={{width: width || "90%"}}>
                    <div className={css(styles.modalHeader)}>
                        <div id="transition-modal-title" className={css(styles.title)}>{title}</div>
                        <div className={css(styles.modalCloseButton)} onClick={handleClose}>
                            <HighlightOffIcon className={css(styles.modalCloseButton)}/>
                        </div>
                    </div>
                    {children}
                </div>
            </Fade>
        </Modal>
    );
}

export default CustomModal;
