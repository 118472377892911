import {makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'green !important',
  },
  selected: {},
}));
const MenuItemSuccess = (props) => {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <MenuItem
      classes={{ root: classes.root, selected: classes.selected }}
      {...rest}
    >
      {children}
    </MenuItem>
  )
}

export default MenuItemSuccess
