import {css} from "aphrodite";
import styles from "./styles";

const scenario2 = <div>
    <div className={css(styles.scenarioHeader)}>Σενάριο 2</div>
    <div className={css(styles.phaseTitle)}>1η φάση Αρχική (ΣΥΝΑΙΝΕΣΗ/ΕΝΗΜΕΡΩΣΗ)</div>
    <div className={css(styles.paragraph)}>
        <div className={css(styles.tab)}></div>
        Καλημέρα/Καλησπέρα/Χαίρεται, <b>Σας <span>καλοσωρίζουμε</span></b> στην υπηρεσία εξ
        αποστάσεως ηλεκτρονικής ταυτοποίησης του Οργανισμού.<span
    > </span>Για να <b>προχωρήσουμε
        στην διαδικασία ταυτοποίησης</b> σας θα σας περιγράψουμε ορισμένες ενέργειες
        τις οποίες χρειάζεται να πραγματοποιήσετε. <b>Επίσης
        θα σας κάνουμε κάποιες ερωτήσεις</b> οι οποίες αποτελούν μέρος της
        διαδικασίας.<span></span><b><u>Συμφωνείτε στη καταγραφή και αποθήκευση της διαδικασίας της εξ
        αποστάσεως ταυτοποίησης και την λήψη <span>στιγμιοτύπων</span> του
        προσώπου σας, του εγγράφου ταυτοποίησης σας και ενδεχομένως άλλων απαραίτητων
        πληροφοριών, την επεξεργασία και τήρηση όλων των δεδομένων και των αρχείων που
        απαιτούνται</u></b>; Παύση για <span>αναναμονή</span>
        απάντησης:<span></span><b><i><u>η διαδικασία θα προχωρήσει
        μόνο αν ο <span>ταυτοποιούμενος</span> δώσει καταφατική απάντηση
        στην ερώτηση. </u></i></b>Θα θέλατε να ξεκινήσουμε τη διαδικασία;
    </div>

    <div className={css(styles.highlightYellow)}>
        ( <b>Ενδεικτικές ενέργειες</b> σε περίπτωση που χρειαζόμαστε περισσότερα στοιχεία για την ποιότητα
        του ήχου και της εικόνας: Παρακαλώ απομακρυνθείτε/πλησιάστε στο μικρόφωνο και πείτε μου το
        ονοματεπώνυμό σας / Παρακαλώ απομακρυνθείτε λίγο από την κάμερα/ πλησιάστε λίγο την κάμερα προς το μέρος σας.)
    </div>

    <div className={css(styles.phaseTitle)}>2η φάση Liveness Detection</div>
    <p><b><span>Σταθείτε</span></b><span> παρακαλώ απέναντι από την κάμερα σας ευθεία. <b
    >Ελάτε λίγο πιο κοντά στην κάμερα</b>/ <b>απομακρυνθείτε ελαφρώς από την κάμερα.</b>
    Χρειάζεται εντός της οθόνης να φαίνεται τόσο <b>το πρόσωπο σας όσο και το πάνω μέρος του σώματος σας</b>. Παραμείνετε
    σε αυτή τη θέση <b>και χαμογελάστε</b>. Τώρα παρακαλώ <b>κλείστε και ανοίξτε τα μάτια</b> σας. Ευχαριστώ.</span></p>

    <div className={css(styles.phaseTitle)}>3η Φάση Επίδειξης Εγγράφου Ταυτοποίησης – Οδηγίες και έλεγχοι</div>
    <p><span>Παρακαλώ <b>επιδείξτε </b>το έγγραφο ταυτοποίησης (<b>ταυτότητα, διαβατήριο</b>, Άδεια παραμονής, ταυτότητα ομογενούς,
        αστυνομική ταυτότητα) και <b>πείτε μου τον αριθμό του</b>.
        Παρακαλώ <b>πείτε μου την ημερομηνία και τον τόπογέννησής σας</b>.
        Παρακαλώ έχετε <b>διαθέσιμο το έγγραφο ταυτοποίησης σας</b> ώστε να σας καθοδηγήσω στην φωτογράφηση και των
        δύο πλευρών/όψεων του/ των σελίδων που θα σας υποδείξω.
    </span></p>

    <div className={css(styles.phaseTitle)}>4η Φάση Φωτογράφησης Εγγράφου Ταυτοποίησης</div>
    <p>
        Παρακαλώ <b>τοποθετήστε σε σταθερή επιφάνεια το έγγραφο ταυτοποίηση σας</b> με την
    μπροστινή όψη/ στην σελίδα με την φωτογραφία ώστε να γίνει η φωτογράφηση.<br/> Ευχαριστώ.
    <br/>
        <b>Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων:</b>
        <br/>
        <b>Βήμα 1:</b> Γυρίστε στην πίσω κάμερα του κινητού σας (χρησιμοποιώντας το μπλε κουμπί) για να πάρω φωτογραφία το έγγραφό σας.
        <br/>
        <b>Βήμα 2:</b> Γυρίστε το κινητό και την ταυτότητα/διαβατήριο σε οριζόντια θέση με τρόπο ώστε η υπογραφή να βρίσκεται αριστερά για να πάρω φωτογραφία την εμπρός όψη.
        <br/>
        <b>Βήμα 3:</b> Γυρίστε το κινητό και την ταυτότητα σε κάθετη θέση για να πάρω φωτογραφία την πίσω όψη.
        <br/>
        <b>Βήμα 4:</b> Γυρίστε ξανά στην εμπρός κάμερα για να πάρω φωτογραφία το πρόσωπό σας.
        <br/>
        <b>*Βήμα 2 και 3:</b> Θα πρέπει η φωτογραφία του εγγράφου να λαμβάνει όλη την κάμερα του κινητού
    </p>

    <div className={css(styles.phaseTitle)}>5η Φάση Φωτογράφησης Προσώπου</div>
    <p><b><span>Θα προχωρήσουμε
         στην φωτογραφία του προσώπου σας. Παρακαλώ όπως κοιτάξετε ευθεία στην κάμερα
    του κινητού σας και παραμείνετε ακίνητος</span></b><span
    >. Σας ευχαριστώ. Θα ήθελα <b><u>τώρα να περάσετε τα δάχτυλά σας μπροστά από το πρόσωπο σας φέρνοντας
    το χέρι σας δεξιά –αριστερά δύο φορές</u></b>. Ευχαριστώ. Παρακαλώ περιμένετε
    να ολοκληρωθεί η διαδικασία ελέγχου. Ευχαριστώ.</span></p>

    <div className={css(styles.phaseTitle)}>6η Φάση Ελέγχων Αυθεντικότητας Εγγράφου Ταυτοποίησης</div>

    <p><b><span>Κρατήστε το έγγραφο ταυτοποίησής σας από την<span
    ></span>πλευρά/ ανοιγμένο στην σελίδα που φέρει την
    φωτογραφία σας δίπλα στο πρόσωπο σας και κοιτάξτε την κάμερα</span></b><span
    >. Παρακαλώ <b>Λυγίστε </b>τώρα το έγγραφο ταυτοποίησής σας. Παρακαλώ <b
    >περάστε τα<span>
    </span>δάχτυλά σας μπροστά από το έγγραφο</b> ταυτοποίησής σας <b
    >φέρνοντας το χέρι σας δεξιά – αριστερά δύο
    φορές</b>.<span></span>Παρακαλώ <b>πείτε μου τον αριθμό του εγγράφου</b> ταυτοποίησής σας. Εφόσον
    πρόκειται για έγγραφο ταυτοποίησης δύο όψεων: <b>Γυρίστε στο πίσω μέρος του</b> εγγράφου ταυτοποίησής σας και <b
        >πλησιάστε το στην κάμερα</b>. Παρακαλώ <b
        >πείτε μου την ηλικία σας</b>. Μπορείτε να αφήσετε
    το έγγραφο ταυτοποίησής σας.</span></p>
    <div className={css(styles.phaseTitle)}> 7η Φάση Ελέγχων Νηφαλιότητας και Μη Απειλής ή Εξαναγκασμού</div>
    <p><b><span>Χωρίς
    να κοιτάξετε το έγγραφο ταυτοποίησής σας, παρακαλώ πείτε μου τον αριθμό του.</span></b></p>
    <div className={css(styles.phaseTitle)}> 8η Φάση Αποστολής OTP</div>

    <p><span className={css(styles.highlightYellow)}>Θα ήθελα σε αυτό
    το σημείο να μας αναφέρετε τον αριθμό
    του κινητού σας τηλεφώνου.</span><span><b>Συμφωνείτε;</b> Αναμονή για καταφατική
    απάντηση- Πείτε μου τον αριθμό σας παρακαλώ. Θα σας σταλεί ένας<span
        > </span>κωδικός μοναδικός/ <span>one</span>
    <span>time</span> <span>password</span>/ OTP.
        Θα πρέπει να καταχωρήσετε τον αριθμό αυτό πατώντας το μπλε κουμπί στο κάτω μέρος δεξιά της οθόνης του κινητού προκειμένου να κάνετε την εισαγωγή του.Ευχαριστώ.
    </span></p>

    <div className={css(styles.phaseTitle)}> 9η Φάση Ολοκλήρωσης Διαδικασίας Ταυτοποίησης
    </div>
    <p><b><span>Υπάρχει
    κάτι που θα θέλατε να ρωτήσετε σχετικά με την διαδικασία ταυτοποίησης σας</span></b><span
    >; (<i>Αναμένουμε
    αρνητική απάντηση σε περίπτωση όμως που υπάρξει η οποιαδήποτε ερώτηση θα πρέπει
    να πούμε ότι για αυτήν την πληροφορία θα πρέπει να απευθυνθείτε στον Οργανισμό)</i>.
    <u>Σας ευχαριστούμε για τον χρόνο σας και θα θέλαμε να σας ενημερώσουμε ότι
    έχουμε ολοκληρώσει τη διαδικασία</u></span></p>
    <p><u><span>εξ αποστάσεως ηλεκτρονικής ταυτοποίησης. Για την εξέλιξη της αίτησης σας θα
    ενημερωθείτε σύντομα από τον Οργανισμό<b>.</b></span></u></p>
</div>


export default scenario2
