import {FormControl, InputLabel} from "@mui/material";
import Select from "@mui/material/Select";
import {css} from "aphrodite";
import styles from "./styles";
import MenuItem from "@mui/material/MenuItem";
import {Vidyo} from "../../../../Models";
import React from "react";

const CameraSelect = (props) => {
  const { cameras , selectedCameraId, selectCameraById, disabled} = props
  if ( cameras ) {
    return (
      <FormControl>
        <InputLabel id={'cameraIdLabel'}>Camera</InputLabel>
        <Select disabled={disabled} name="cameraId" id={"cameraId"} labelId={'cameraIdLabel'}
                value={selectedCameraId || ''} onChange={selectCameraById}
                className={css(styles.deviceSelect)} label={'Camera'}>
          {Object.keys(cameras)?.map(cameraId =>
            <MenuItem  value={cameraId} key={cameraId}>
              {Vidyo.formatDeviceName(cameras[cameraId]?.name)}
            </MenuItem >
          )}
        </Select>
      </FormControl>
    )
  }
  return null;
}

export default CameraSelect
