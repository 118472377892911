import {Request} from "../../Utils";
import Logger from "../../Utils/Logger";
import uuid from "react-uuid";

const getToken = async () => {
    try {
        const mdiHost = process.env.REACT_APP_MDI_HOST
        const clientId = process.env.REACT_APP_MDI_CLIENT_ID
        const clientSecret = process.env.REACT_APP_MDI_CLIENT_SECRET
        const params = {
            body: {
                clientId,
                clientSecret
            }
        }
        const result = await Request.get(`${mdiHost}/api/auth`, params)
        // Logger.log(result, "getToken result")
        return result?.token
    } catch (e) {
        Logger.console("getToken Exception: ", e)
        return null
    }
}

const getOcrData = async (workflowId, token) => {
    try {
        const mdiHost = process.env.REACT_APP_MDI_HOST

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Accept: 'application/json, text/javascript, */*; q=0.01',
            },
            body: {
                workflowId,
            }
        }
        const result = await Request.get(`${mdiHost}/api/Document/OcrData`, params)
        Logger.log(result, "getOcrData result")
        return result
    } catch (e) {
        Logger.console("getOcrData Exception: ", e)
        return null
    }
}
const uploadDocument = async (payload, token) => {
    try {
        const mdiHost = process.env.REACT_APP_MDI_HOST

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Accept: 'application/json, text/javascript, */*; q=0.01',
            },
            body: payload
        }
        const result = await Request.post(`${mdiHost}/api/documentStorage`, params)
        Logger.log(result, "MdiClient Services@uploadDocument result")
        return result
    } catch (e) {
        Logger.console("MdiClient Services@uploadDocument Exception: ", e)
        return null
    }
}
const getFacematchResult = async (payload, token) => {
    try {
        const mdiHost = process.env.REACT_APP_MDI_HOST

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Accept: 'application/json, text/javascript, */*; q=0.01',
            },
            body: payload
        }
        const result = await Request.post(`${mdiHost}/api/id/verify`, params)
        Logger.log(result, "MdiClient Services@getFacematchScore result")
        return result
    } catch (e) {
        Logger.console("MdiClient Services@getFacematchScore Exception: ", e)
        return null
    }
}

const sendSMS = async (phoneNumber, subject, message) => {
    try {
        const smsHost = process.env.REACT_APP_MSTAT_HOST
        const params = {
            body: {
                id: uuid(),
                auth_key: process.env.REACT_APP_MSTAT_AUTH_KEY,
                validity: 60,
                from: 'Mellon',
                text: message,
                to: phoneNumber,
                subject: subject,
            }
        }
        Logger.console('sendSMS params: ', params)
        const result = await Request.get(`${smsHost}/http/sms.php`, params)
        Logger.console('sendSMS result: ', result)
        return result
    } catch (e) {
        Logger.console("MdiClient Services@sendSMS() Exception", e)
        return null
    }
}
const requestOtp = async (phoneNumber, workflowId, token) => {
    try {
        const mdvHost = process.env.REACT_APP_MDI_HOST
        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: {
                PhoneNumber: phoneNumber,
                WorkFlowGuid: workflowId
            }
        }
        Logger.console('requestOtp params: ', params)
        const response = await Request.post(mdvHost + '/api/Otp/RequestOtp', params)
        return response
    } catch (e) {
        return {
            result: "Exception",
            message: e.message
        }
    }
}

const sendDocumentsToAgent = async (documents, ocrData, workflowId, useUnikenOcr, token) => {
    try {
        const mdiHost = process.env.REACT_APP_MDI_HOST
        const payload = {
            providerName: 'MDV',
            workFlowId: workflowId,
            data: {
                documentType: 'JPEG',
                listOfDocumentIds: documents,
                ocrData: ocrData,
            },
        };
        if ( useUnikenOcr ) {
            payload.ocrTemplateId = 1;
            payload.OcrProviderName = "Uniken";
        }
        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Accept: 'application/json, text/javascript, */*; q=0.01',
            },
            body: payload
        }
        const result = await Request.post(`${mdiHost}/api/ocr`, params)
        Logger.console("Send Documents to Agent result: ", result)
        return result
    } catch (e) {
        Logger.console("MdiClient Services@sendDocumentsToAgent() Exception", e)
        return null
    }
}

const sendExtraData = async (workflowId, livenessPassed, stepsCompleted, reason, token) => {
    try {
        const mdiHost = process.env.REACT_APP_MDI_HOST
        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Accept: 'application/json, text/javascript, */*; q=0.01',
            },
            body: [
                {
                    path: 'WorkFlowData',
                    op: 'replace',
                    value: {
                        Liveness_Passed: livenessPassed,
                        Steps_Completed: stepsCompleted,
                        Reason_For_Rejection: reason,
                    }
                }
            ]
        }

        const result = await Request.patch(`${mdiHost}/api/workflow/${workflowId}`, params)
        Logger.console('Send Extra Data result: ', result)
        return result;

    } catch (e) {
        Logger.console("MdiClient Services@sendExtraData() Exception", e)
        return null
    }
}

const startRecording = async (workflowId, token) => {
    try {
        if ( process.env.REACT_APP_ENABLE_VIDEO_RECORDING === 'true' ) {
            const mdiHost = process.env.REACT_APP_MDI_HOST
            const params = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                    Accept: 'application/json, text/javascript, */*; q=0.01',
                },
                body: {}
            }
            const result = await Request.post(`${mdiHost}/api/video/startrecording?workflowId=${workflowId}`, params)
            Logger.console('Start Recording result: ', result)
            return result
        }
        return null
    } catch (e) {
        Logger.error("MdiClient Services@startRecording() Exception", e)
        return null
    }
}

const closeWorkflow = async (workflowId, token) => {
    try {
        const mdiHost = process.env.REACT_APP_MDI_HOST
        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Accept: 'application/json, text/javascript, */*; q=0.01',
            },
            body: {
                providerName: 'Mellon',
                workFlowId: workflowId
            }
        }
        const result = await Request.post(`${mdiHost}/api/workflow/process`, params)
        Logger.console('Close Workflow result: ', result)
        return result
    } catch (e) {
        Logger.error("MdiClient Services@closeWorkflow() Exception", e)
        return null
    }
}
const getWorkflow = async (workflowId, token) => {
    try {
        const mdiHost = process.env.REACT_APP_MDI_HOST
        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Accept: 'application/json, text/javascript, */*; q=0.01',
            },
            body: {
                kycActions: true,
            }
        }
        const result = await Request.get(`${mdiHost}/api/workflow/${workflowId}`, params)
        Logger.console('Get Workflow result: ', result)
        return result
    } catch (e) {
        Logger.error("MdiClient Services@getWorkflow() Exception", e)
        return null
    }
}
export const MdiService = {
    getToken,
    getOcrData,
    sendSMS,
    requestOtp,
    uploadDocument,
    getFacematchResult,
    sendDocumentsToAgent,
    sendExtraData,
    closeWorkflow,
    startRecording,
    getWorkflow,
}
