import {createReducer, isJSON} from '../../../Utils';
import * as type from './types';

const initialState = {
  isLoading: false,
  data: null,
};

export default createReducer(initialState, {
  [type.REQUEST + type.GET_TOKEN](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.GET_TOKEN](state, action) {
    return {
      isLoading: false,
      data: {
        ...state.data,
        token: action?.response
      }
    };
  },

  [type.FAILURE + type.GET_TOKEN](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  },
  [type.REQUEST + type.GET_OCR_DATA](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.GET_OCR_DATA](state, action) {
    let mobile = ''
    if (action?.response) {
      if ( Array.isArray(action?.response) ) {

        action?.response.forEach(element => {
          if (element.Key === 'Mobile') {
            mobile = element.Value;
          }
        })
        if (mobile) {
          mobile = mobile.replace(/-/g, '');
        }
      }
    }

    return {
      isLoading: false,
      data: {
        ...state.data,
        ocrData: action?.response,
        ocrDataMobile: mobile
      }
    };
  },

  [type.FAILURE + type.GET_OCR_DATA](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  },

  [type.REQUEST + type.GET_WORKFLOW](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.GET_WORKFLOW](state, action) {
    let mobile = ''
    if (action?.response?.workFlowDescription && isJSON(action.response.workFlowDescription)) {
      const workflow = JSON.parse(action.response.workFlowDescription);
      if (workflow.userPhoneNumber) {
        mobile = workflow.userPhoneNumber.replace(/-/g, '');
      }
    }

    return {
      isLoading: false,
      data: {
        ...state.data,
        workFlowData: action?.response, //ocrData and ocrDataMobile
        workFlowDataMobile: mobile
      }
    };
  },

  [type.FAILURE + type.GET_WORKFLOW](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  },

  [type.REQUEST + type.GET_FACEMATCH_RESULT](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.GET_FACEMATCH_RESULT](state, action) {
        return {
      isLoading: false,
      data: {
        ...state.data,
        facematch: action?.response
      }
    };
  },

  [type.FAILURE + type.GET_FACEMATCH_RESULT](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  },

  [type.REQUEST + type.UPLOAD_DOCUMENT](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.UPLOAD_DOCUMENT](state, action) {
    let uploads = {
      IDFront: {},
      IDBack: {},
      selfie: {},
    }
    if (state.data?.uploads ) {
      uploads = {
        ...uploads,
        ...state.data?.uploads
      }
    }
    return {
      isLoading: false,
      data: {
        ...state.data,
        uploads: {
          ...uploads,
          [action.payload?.documentType]: {
            ...uploads[action.payload?.documentType],
            uploaded: true,
            response: action.payload?.result
          }
        }
      }
    };
  },

  [type.FAILURE + type.UPLOAD_DOCUMENT](state, action) {
    let uploads = {
      IDFront: {},
      IDBack: {},
      selfie: {},
    }
    if (state.data?.uploads ) {
      uploads = {
        ...uploads,
        ...state.data?.uploads
      }
    }
    return {
      ...state,
      isLoading: false,
      data: {
        ...state.data,
        uploads: {
          ...uploads,
          [action.payload?.documentType]: {
            ...uploads[action.payload?.documentType],
            uploaded: false,
            response: action.payload?.result
          }
        }
      },
      error: action.error,
    };
  },

  [type.REQUEST + type.SEND_DOCUMENTS_TO_AGENT](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.SEND_DOCUMENTS_TO_AGENT](state, action) {
    return {
      isLoading: false,
      data: {
        ...state.data,
        sendDocumentsToAgentResult: action?.response
      }
    };
  },

  [type.FAILURE + type.SEND_DOCUMENTS_TO_AGENT](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  },

  [type.REQUEST + type.SEND_EXTRA_DATA](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.SEND_EXTRA_DATA](state, action) {
    return {
      isLoading: false,
      data: {
        ...state.data,
        sendExtraDataResult: action?.response
      }
    };
  },

  [type.FAILURE + type.SEND_EXTRA_DATA](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  },

  [type.REQUEST + type.CLOSE_WORKFLOW](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.CLOSE_WORKFLOW](state, action) {
    return {
      isLoading: false,
      data: {
        ...state.data,
        closeWorkflowResult: action?.response
      }
    };
  },

  [type.FAILURE + type.CLOSE_WORKFLOW](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  },

  [type.REQUEST + type.START_RECORDING](state, action) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [type.SUCCESS + type.START_RECORDING](state, action) {
    return {
      isLoading: false,
      data: {
        ...state.data,
        startRecordingResult: action?.response
      }
    };
  },

  [type.FAILURE + type.START_RECORDING](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
      startRecordingResult: null
    };
  },

  [type.SAVE](state, action) {
    return {
      ...state,
      data: {
        ...action.payload,
      }
    };
  },
  [type.SET_IDFRONT_IMAGE_URI](state, action) {
    const {imgUri, uuid} = action.payload
    return {
      ...state,
      data: {
        ...state.data,
        IDFront: {
          ...state.data.IDFront,
          uuid: uuid || null,
          imageUri: imgUri || null
        },
      }
    };
  },
  [type.SET_IDBACK_IMAGE_URI](state, action) {
    const {imgUri, uuid} = action.payload
    return {
      ...state,
      data: {
        ...state.data,
        IDBack: {
          ...state.data.IDBack,
          uuid: uuid || null,
          imageUri: imgUri || null,
        },
      }
    };
  },
  [type.SET_SELFIE_IMAGE_URI](state, action) {
    const {imgUri, uuid} = action.payload
    return {
      ...state,
      data: {
        ...state.data,
        selfie: {
          ...state.data.selfie,
          uuid: uuid || null,
          imageUri: imgUri || null
        },
      }
    };
  },

  // All other action types result in state being returned
});
