import { createType } from '../../../Utils';

const actionName = 'MDI_CLIENT';

export const REQUEST = createType.request(`${actionName}`);
export const SUCCESS = createType.success(`${actionName}`);
export const FAILURE = createType.failure(`${actionName}`);
export const GET_TOKEN = createType.subtype( 'GET_TOKEN');
export const GET_OCR_DATA = createType.subtype( 'GET_OCR_DATA');
export const GET_WORKFLOW = createType.subtype( 'GET_WORKFLOW');
export const GET_FACEMATCH_RESULT = createType.subtype( 'GET_FACEMATCH_RESULT');
export const UPLOAD_DOCUMENT = createType.subtype( 'UPLOAD_DOCUMENT');
export const SEND_DOCUMENTS_TO_AGENT = createType.subtype( 'SEND_DOCUMENTS_TO_AGENT');
export const SEND_EXTRA_DATA = createType.subtype( 'SEND_EXTRA_DATA');
export const CLOSE_WORKFLOW = createType.subtype( 'CLOSE_WORKFLOW');
export const START_RECORDING = createType.subtype( 'START_RECORDING');
export const SAVE = createType.customAction(actionName, 'SAVE');
export const SET_IDFRONT_IMAGE_URI = createType.customAction(actionName, 'SET_IDFRONT_IMAGE_URI');
export const SET_IDBACK_IMAGE_URI = createType.customAction(actionName, 'SET_IDBACK_IMAGE_URI');
export const SET_SELFIE_IMAGE_URI = createType.customAction(actionName, 'SET_SELFIE_IMAGE_URI');
