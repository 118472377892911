import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';

const Default = () => {

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.title)}>
        404. PAGE NOT FOUND
      </div>
      <div className={css(styles.button)} onClick={() => window.history.back()}>
        {'Go Back'}
      </div>
    </div>
  );
};

const memoizedDefault = React.memo(Default);
export { memoizedDefault as Default };
