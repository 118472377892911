import {dispatchHelper, isObject} from '../Utils';
import {store} from '../State/store';

import {Logger} from '../Utils';

class Model {
    _fillable = [];
    _guarded = [];
    _hidden = [];
    _casts = {};
    _overrides = {};
    _log = new Logger();
    constructor(data, params = null) {
        if (isObject(params)) {
            Object.keys(params).forEach(key => {
                this[key] = params[key];
            });
        }
        if (isObject(data)) {
            Object.keys(data).forEach(key => {
                const index = key.charAt(0) === '_' ? key : '_' + key;
                const keyActual = key.charAt(0) === '_' ? key.slice(1) : key;
                if (typeof data[key] !== 'function') {
                    this[index] = data[key];
                    const writeable =
                        (!Array.isArray(this._fillable) ||
                            this._fillable.length === 0 ||
                            this._fillable.includes(key)) &&
                        (!Array.isArray(this._guarded) || !this._guarded.includes(key));
                    Object.defineProperty(this, keyActual, {
                        get: this._overrides?.[keyActual]?.get
                            ? this._overrides[keyActual].get
                            : () => {
                                return this._casts?.[keyActual]
                                    ? this._casts[keyActual](this[index])
                                    : this[index];
                            },
                        set: this._overrides?.[keyActual]?.set
                            ? this._overrides[keyActual].set
                            : newValue => {
                                if (writeable) {
                                    this[index] = newValue;
                                }
                            },
                        // writable: writeable,
                        configurable: writeable,
                    });
                } else {
                    if (this._fillable.includes(key) ) {
                        this[key] = data[key];
                    } else {
                        this[index] = data[key];
                    }

                }
            });
        } else {
            throw new Error('Invalid initialization data. Must be an object');
        }
        // Check that all fillable fields are defined
        if (Array.isArray(this._fillable)) {
            this._fillable.forEach(key => {
                const index = key.charAt(0) === '_' ? key : '_' + key;
                const keyActual = key.charAt(0) === '_' ? key.slice(1) : key;
                if (this[index] === undefined && typeof data[key] !== 'function') {
                    this[index] = null;
                    const writeable = true;
                    Object.defineProperty(this, keyActual, {
                        get: this._overrides?.[keyActual]?.get
                            ? this._overrides[keyActual].get
                            : () => {
                                return this._casts?.[keyActual]
                                    ? this._casts[keyActual](this[index])
                                    : this[index];
                            },
                        set: this._overrides?.[keyActual]?.set
                            ? this._overrides[keyActual].set
                            : newValue => {
                                if (writeable) {
                                    this[index] = newValue;
                                }
                            },
                        // writable: writeable,
                        configurable: writeable,
                    });
                } else {
                    if ( typeof data[key] !== 'function' ) {
                        this[index] = data[key];
                    }
                }
            });
        } else {
            throw new Error('Invalid initialization data. Must be an object');
        }
    }

    get values() {
        const result = {};
        Object.keys(this).forEach(key => {
            // && Object.prototype.hasOwnProperty.call(this, key)
            if (typeof this[key] !== 'function') {
                const excluded = [
                    '_fillable',
                    '_guarded',
                    '_hidden',
                    '_casts',
                    '_overrides',
                    '_log',
                ];
                if (!this._hidden.includes(key) && !excluded.includes(key)) {
                    const magicKey = key.slice(1);
                    result[magicKey] = this[magicKey];
                }
            }
        });
        return result;
    }

    getAsDate(fieldName) {
        try {
            return new Date(this['_' + fieldName]);
        } catch (e) {
            this._log.error(e);
        }
    }

    clone() {
        const data = {};
        const excluded = [
            '_fillable',
            '_guarded',
            '_hidden',
            '_casts',
            '_overrides',
            '_log',
        ];
        for (const key in this) {
            if (!excluded.includes(key)) {
                data[key] = this[key];
            }
        }
        return new this.constructor(data, {
            _fillable: this._fillable,
            _guarded: this._guarded,
            _hidden: this._hidden,
            _casts: this._casts,
            _overrides: this._overrides,
        });
    }

    update(data) {
        if (isObject(data)) {
            Object.keys(data).forEach(key => {
                const index = key.charAt(0) === '_' ? key : '_' + key;
                const keyActual = key.charAt(0) === '_' ? key.slice(1) : key;
                if (typeof data[key] !== 'function') {
                    this[index] = data[key];
                    Object.defineProperty(this, keyActual, {
                        get: this._overrides?.[keyActual]?.get
                            ? this._overrides[keyActual].get
                            : () => {
                                return this._casts?.[keyActual]
                                    ? this._casts[keyActual](this[index])
                                    : this[index];
                            },
                        set: this._overrides?.[keyActual]?.set
                            ? this._overrides[keyActual].set
                            : newValue => {
                                if (
                                    (!Array.isArray(this._fillable) ||
                                        this._fillable.length === 0 ||
                                        this._fillable.includes(key)) &&
                                    (!Array.isArray(this._guarded) ||
                                        !this._guarded.includes(key))
                                ) {
                                    this[index] = newValue;
                                }
                            },
                    });
                } else {
                    this[index] = data[key];
                }
            });
        } else {
            throw new Error('Invalid update data. Must be an object');
        }
    }

    saveChanges = type => {
        if (type && type.SAVE) {
            const obj = this.values;
            if (isObject(obj)) {
                return Model.dispatch(
                    dispatchHelper.dispatchCustomAction(type.SAVE, obj),
                );
            }
            return Model.dispatch(
                dispatchHelper.dispatchCustomAction(type.SAVE, null),
            );
        }
        throw new Error(
            'Invalid save parameters. Must be an object and have a type.SAVE set',
        );
    };

    static dispatch = actionResponse => {
        try {
            return store.dispatch(actionResponse);
        } catch (e) {
            Logger.log(e);
            return null;
        }
    };
    static dispatchAsync = async actionResponse => {
        try {
            return await store.dispatch(actionResponse);
        } catch (e) {
            Logger.log(e);
            return null;
        }
    };

    /**
     * Returns the given `obj` without the `property`.
     *
     * @param {Object} obj
     * @param {String} property
     *
     * @returns {Object}
     */
    static objWithoutProperty = (obj, property) => {
        const { [property]: unused, ...rest } = obj
        return rest
    }
}

export default Model;
