import {css} from "aphrodite";
import styles from "./styles";

const scenario4 = <div>
    <div className={css(styles.scenarioHeader)}>Σενάριο 4</div>
    <div className={css(styles.phaseTitle)}>1η φάση Αρχική (ΣΥΝΑΙΝΕΣΗ/ΕΝΗΜΕΡΩΣΗ)</div>
    <div className={css(styles.paragraph)}>
        Καλημέρα/Καλησπέρα/Χαίρεται, Σας <b>καλοσωρίζουμε</b> στην υπηρεσία εξ αποστάσεως ηλεκτρονικής ταυτοποίησης του
        Οργανισμού. Για <b>να προχωρήσουμε</b> στην διαδικασία ταυτοποίησης σας θα <b>σας περιγράψουμε</b> ορισμένες
        ενέργειες τις
        οποίες χρειάζεται να πραγματοποιήσετε. Επίσης θα <b>σας κάνουμε κάποιες ερωτήσεις</b> οι οποίες αποτελούν μέρος
        της
        διαδικασίας. <b>Συμφωνείτε στη καταγραφή και αποθήκευση της διαδικασίας της εξ αποστάσεως ταυτοποίησης και την
        λήψη
        στιγμιοτύπων του προσώπου σας, του εγγράφου ταυτοποίησης σας και ενδεχομένως άλλων απαραίτητων πληροφοριών, την
        επεξεργασία και τήρηση όλων των δεδομένων και των αρχείων που απαιτούνται;</b> (Παύση για αναμονή απάντησης: η
        <b> διαδικασία θα προχωρήσει μόνο αν ο ταυτοποιούμενος δώσει καταφατική απάντηση στην ερώτηση.</b>) Θα θέλατε να
        ξεκινήσουμε τη διαδικασία;<br/>
        <span className={css(styles.highlightYellow)}>(<b>Ενδεικτικές ενέργειες σε περίπτωση που χρειαζόμαστε περισσότερα στοιχεία για την ποιότητα του ήχου και της
            εικόνας:</b> Παρακαλώ απομακρυνθείτε/πλησιάστε στο μικρόφωνο και πείτε μου το ονοματεπώνυμό σας / Παρακαλώ
            απομακρυνθείτε λίγο από την κάμερα/ πλησιάστε λίγο την κάμερα προς το μέρος σας.)</span>
    </div>
    <div className={css(styles.phaseTitle)}>2η Φάση Επίδειξης Εγγράφου Ταυτοποίησης – Οδηγίες και έλεγχοι</div>
    <div className={css(styles.paragraph)}>
        Παρακαλώ επιδείξτε το έγγραφο ταυτοποίησης (<b>ταυτότητα, διαβατήριο,</b> Άδεια παραμονής, ταυτότητα ομογενούς,
        αστυνομική ταυτότητα) <b>και πείτε μου τον αριθμό του.</b> Παρακαλώ πείτε μου <b>την ημερομηνία και τον τόπο
        γέννησής σας.</b>
        Παρακαλώ <b>έχετε διαθέσιμο το έγγραφο</b> ταυτοποίησης σας ώστε να<b> σας καθοδηγήσω στην φωτογράφηση και των
        δύο πλευρών</b>/όψεων του/ των σελίδων που θα σας υποδείξω.
        (Tον ενημερώνουμε ότι παίρνουμε τον έλεγχο της πίσω κάμερας για να γίνει η φωτογράφιση).
    </div>
    <div className={css(styles.phaseTitle)}>3η Φάση Φωτογράφησης Εγγράφου Ταυτοποίησης</div>
    <div className={css(styles.paragraph)}>
        {/*<b><u>ΥΠΟΛΟΓΙΣΤΗΣ</u></b> Παρακαλώ <b>τοποθετήστε μπροστά στην κάμερα του υπολογιστή το έγγραφο</b> ταυτοποίηση*/}
        {/*σας με την μπροστινή*/}
        {/*όψη/ <b>στην σελίδα με την φωτογραφία και κρατήστε σταθερά χωρίς τα χέρια σας να καλύπτουν κάποιο σημείο ώστε να*/}
        {/*γίνει η φωτογράφηση.</b> Ευχαριστώ. Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων:<b> Γυρίστε</b> το*/}
        {/*έγγραφο*/}
        {/*ταυτοποίησης στη πίσω όψη σε οριζόντια θέση με τρόπο ώστε η υπογραφή να βρίσκεται αριστερά και κρατήστε σταθερά*/}
        {/*<b>χωρίς τα χέρια σας να καλύπτουν</b> κάποιο σημείο ώστε να γίνει η φωτογράφηση. Ευχαριστώ.*/}

        {/*<b><u>ΚΙΝΗΤΟ ΤΗΛΕΦΩΝΟ</u></b> */}
        Παρακαλώ <b>τοποθετήστε σε σταθερή επιφάνεια το έγγραφο ταυτοποίηση σας</b> με την
        μπροστινή όψη/ στην σελίδα με την φωτογραφία ώστε να γίνει η φωτογράφηση. Ευχαριστώ.
        <br/>
        <b>Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων:</b>
        {/*<b> Γυρίστε το</b> έγγραφο ταυτοποίησης στη πίσω όψη σε οριζόντια θέση με τρόπο ώστε η υπογραφή να*/}
        {/*βρίσκεται αριστερά για να γίνει η φωτογράφιση*/}
        <br/>
        <b>Βήμα 1:</b> Γυρίστε στην πίσω κάμερα του κινητού σας (χρησιμοποιώντας το μπλε κουμπί) για να πάρω φωτογραφία το έγγραφό σας.
        <br/>
        <b>Βήμα 2:</b> Γυρίστε το κινητό και την ταυτότητα/διαβατήριο σε οριζόντια θέση με τρόπο ώστε η υπογραφή να βρίσκεται αριστερά για να πάρω φωτογραφία την εμπρός όψη.
        <br/>
        <b>Βήμα 3:</b> Γυρίστε το κινητό και την ταυτότητα σε κάθετη θέση για να πάρω φωτογραφία την πίσω όψη.
        <br/>
        <b>Βήμα 4:</b> Γυρίστε ξανά στην εμπρός κάμερα για να πάρω φωτογραφία το πρόσωπό σας.
        <br/>
        <b>*Βήμα 2 και 3:</b> Θα πρέπει η φωτογραφία του εγγράφου να λαμβάνει όλη την κάμερα του κινητού
    </div>
    <div className={css(styles.phaseTitle)}> 4η Φάση Φωτογράφησης Προσώπου</div>

    <div className={css(styles.paragraph)}>
        Θα <b>προχωρήσουμε στην φωτογραφία</b> του προσώπου σας. Παρακαλώ όπως<b>κοιτάξετε ευθεία στην κάμερα του
        κινητού σας και παραμείνετε ακίνητος.</b> Σας ευχαριστώ. <b>Θα ήθελα τώρα να περάσετε τα δάχτυλά σας
        μπροστά από
        το πρόσωπο σας φέρνοντας το χέρι σας δεξιά –αριστερά δύο φορές.</b> Ευχαριστώ. Παρακαλώ περιμένετε να
        ολοκληρωθεί η
        διαδικασία ελέγχου. Ευχαριστώ.
    </div>
    <div className={css(styles.phaseTitle)}> 5η Φάση Ελέγχων Αυθεντικότητας Εγγράφου Ταυτοποίησης</div>

    <div className={css(styles.paragraph)}>
        <b>Κρατήστε το έγγραφο ταυτοποίησής σας από την πλευρά/ ανοιγμένο στην σελίδα που φέρει την φωτογραφία σας δίπλα
            στο πρόσωπο σας και <u>κοιτάξτε</u> την κάμερα.</b> Παρακαλώ <b><u>Λυγίστε</u></b> τώρα το έγγραφο
        ταυτοποίησής σας.
        Παρακαλώ <b><u>περάστε τα
        δάχτυλά σας </u></b>μπροστά από το έγγραφο ταυτοποίησής σας <b><u>φέρνοντας το χέρι σας δεξιά – αριστερά δύο
        φορές.</u></b> Παρακαλώ
        <b><u>πείτε μου τον αριθμό του εγγράφου ταυτοποίησής σας.</u></b> Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο
        όψεων: <b>Γυρίστε
        στο πίσω μέρος</b> του εγγράφου ταυτοποίησής σας και <b>πλησιάστε το</b> στην
        κάμερα. Παρακαλώ <b>πείτε μου την ηλικία σας.</b> Μπορείτε να αφήσετε το έγγραφο ταυτοποίησής σας.
    </div>
    <div className={css(styles.phaseTitle)}> 6η φάση Liveness Detection</div>

    <b> Σταθείτε</b> παρακαλώ απέναντι από την κάμερα σας <b>ευθεία. Ελάτε</b> λίγο πιο κοντά στην
    κάμερα/ <b>απομακρυνθείτε</b> ελαφρώς από
    την κάμερα. Χρειάζεται εντός της οθόνης <b>να φαίνεται</b> τόσο το πρόσωπο σας όσο και το πάνω μέρος του σώματος
    σας.
    <b>Παραμείνετε</b> σε αυτή τη θέση <b>και χαμογελάστε.</b> Τώρα παρακαλώ <b>κλείστε και ανοίξτε τα μάτια
    σας.</b> Ευχαριστώ.
    <div className={css(styles.phaseTitle)}>7η Φάση Ελέγχων Νηφαλιότητας και Μη Απειλής ή Εξαναγκασμού</div>
    <div className={css(styles.paragraph)}>
        <b> Χωρίς να κοιτάξετε το έγγραφο ταυτοποίησής σας, παρακαλώ πείτε μου τον πείτε μου τον αριθμό του.</b>
    </div>
    <div className={css(styles.phaseTitle)}> 8η Φάση Αποστολής OTP</div>

    <div className={css(styles.paragraph)}>
        <span className={css(styles.highlightYellow)}>
            Θα ήθελα σε αυτό το σημείο<b> να μας αναφέρετε τον αριθμό του κινητού</b> σας τηλεφώνου.<b> Συμφωνείτε;</b>
        </span>
        (Αναμονή για καταφατική απάντηση-) Πείτε μου τον αριθμό σας παρακαλώ. Θα σας σταλεί ένας κωδικός μοναδικός/ one time
        password/ OTP.
        Θα πρέπει να καταχωρήσετε τον αριθμό αυτό πατώντας το μπλε κουμπί στο κάτω μέρος δεξιά της οθόνης του κινητού
        προκειμένου να κάνετε την εισαγωγή του.
        Ευχαριστώ.
    </div>
    <div className={css(styles.phaseTitle)}> 9η Φάση Ολοκλήρωσης Διαδικασίας Ταυτοποίησης</div>

    <div className={css(styles.paragraph)}>
        <b> Υπάρχει κάτι που θα θέλατε να ρωτήσετε</b> σχετικά με την διαδικασία ταυτοποίησης σας; (Αναμένουμε αρνητική
        απάντηση σε περίπτωση όμως που υπάρξει η οποιαδήποτε ερώτηση θα πρέπει να πούμε ότι για αυτήν την πληροφορία θα πρέπει να
        απευθυνθείτε στον Οργανισμό).
        <b>Σας ευχαριστούμε για τον χρόνο σας</b> και θα θέλαμε να σας ενημερώσουμε ότι έχουμε
        ολοκληρώσει τη διαδικασία εξ αποστάσεως ηλεκτρονικής ταυτοποίησης.<b> Για την εξέλιξη της αίτησης σας θα
        ενημερωθείτε σύντομα από τον Οργανισμό</b>

    </div>

</div>


export default scenario4
