import {StyleSheet} from 'aphrodite';

export default StyleSheet.create({
  selectContainer: {
    minWidth: 200,
    maxWidth: 400,
  },
  countrySelect: {
    marginRight: 10,
    minWidth: 200,
    maxWidth: 400,
  },
});
