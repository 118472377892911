import {StyleSheet} from 'aphrodite';

export default StyleSheet.create({
  screenshotToolbarButtonIcon: {
    border: '1px solid rgb(162, 162, 162)',
    cursor: 'pointer',
    background: 'white',
    borderRadius: 35,
    height: 25,
    width: 25,
    padding: 5,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  sidebarToolbarButtonIcon: {
    border: '1px solid rgb(162, 162, 162)',
    cursor: 'pointer',
    background: 'white',
    borderRadius: 5,
    height: 25,
    width: 25,
    padding: 5,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  screenshotToolbarButtonIconDanger: {
    border: '1px solid rgb(162, 162, 162)',
    cursor: 'pointer',
    background: 'red',
    borderRadius: 35,
    height: 25,
    width: 25,
    padding: 5,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  blink: {
    animationName: {
      '0%': {
        opacity: 1
      },
      '49.75%': {
        opacity: 1
      },
      '50.25%': {
        opacity: 0
      },
      '85.5%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    animationDuration: '1.7s',
    animationIterationCount: 'infinite'
  },
  footerToolbarButtonIcon: {
    width: '100%',
    height: '100%',
  },
  recordIcon: {
    width: 50,
    height: 50,
    marginRight: 10
  },
});
