import {css} from "aphrodite";
import styles from "./styles";

const scenario3 = <div>
    <div className={css(styles.scenarioHeader)}>Σενάριο 3</div>
    <div className={css(styles.phaseTitle)}>1η φάση Αρχική (ΣΥΝΑΙΝΕΣΗ/ΕΝΗΜΕΡΩΣΗ)</div>
    <div className={css(styles.paragraph)}>
        Καλημέρα/Καλησπέρα/Χαίρεται, καλώς ήρθατε στην υπηρεσία εξ αποστάσεως ηλεκτρονικής ταυτοποίησης του Οργανισμού.
        <b>Συναινείτε</b> στη καταγραφή και αποθήκευση της διαδικασίας της εξ αποστάσεως ταυτοποίησης, τη λήψη
        στιγμιοτύπων του
        προσώπου σας και του εγγράφου ταυτοποίησης σας και ενδεχομένως άλλων απαραίτητων πληροφοριών, την επεξεργασία
        και τήρηση όλων των δεδομένων και των αρχείων που απαιτούνται ; (Παύση για αναμονή απάντησης: η διαδικασία <b>θα
        προχωρήσει μόνο</b> αν ο <b>ταυτοποιούμενος δώσει καταφατική απάντηση στην ερώτηση.</b> )
        Για να προχωρήσουμε στην ταυτοποίηση σας θα ακολουθήσουν κάποιες ενέργειες και θα σας καθοδηγήσω να τις
        ολοκληρώσουμε. Επίσης θα σας θέσω κάποιες ερωτήσεις κατά την διάρκεια της διαδικασίας. Μπορούμε να ξεκινήσουμε;
        (<b>Ενδεικτικές ενέργειες σε περίπτωση που χρειαζόμαστε περισσότερα στοιχεία για την ποιότητα του ήχου και της
        εικόνας:</b> Παρακαλώ απομακρυνθείτε/πλησιάστε στο μικρόφωνο και πείτε μου το ονοματεπώνυμό σας / Παρακαλώ
        απομακρυνθείτε λίγο από την κάμερα/ πλησιάστε λίγο την κάμερα προς το μέρος σας.)
    </div>

    <div className={css(styles.phaseTitle)}>2η Φάση Επίδειξης Εγγράφου Ταυτοποίησης – Οδηγίες και έλεγχοι</div>
    <div className={css(styles.paragraph)}>

        Παρακαλώ <b>επιδείξτε</b> το έγγραφο ταυτοποίησης (<b>ταυτότητα, διαβατήριο</b>, Άδεια παραμονής, ταυτότητα
        ομογενούς, αστυνομική
        ταυτότητα) <b>και πείτε μου τον αριθμό του</b>. Παρακαλώ <b>πείτε μου την ημερομηνία και τον τόπο γέννησής
        σας</b>.
        Παρακαλώ έχετε
        διαθέσιμο το έγγραφο ταυτοποίησης σας ώστε να σας καθοδηγήσω στην φωτογράφηση και των δύο πλευρών/όψεων του/ των
        σελίδων που θα σας υποδείξω.
        (Tον ενημερώνουμε ότι παίρνουμε τον έλεγχο της πίσω κάμερας για να γίνει η φωτογράφιση).
    </div>
    <div className={css(styles.phaseTitle)}> 3η Φάση Φωτογράφησης Εγγράφου Ταυτοποίησης</div>
    <div className={css(styles.paragraph)}>
        Παρακαλώ <b>τοποθετήστε σε σταθερή επιφάνεια το έγγραφο</b> ταυτοποίηση σας με την μπροστινή όψη/ στην
        σελίδα με την φωτογραφία ώστε να γίνει η φωτογράφηση. Ευχαριστώ.
        <br/>
        <b>Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων:</b>
        <br/>
        <b>Βήμα 1:</b> Γυρίστε στην πίσω κάμερα του κινητού σας (χρησιμοποιώντας το μπλε κουμπί) για να πάρω φωτογραφία το έγγραφό σας.
        <br/>
        <b>Βήμα 2:</b> Γυρίστε το κινητό και την ταυτότητα/διαβατήριο σε οριζόντια θέση με τρόπο ώστε η υπογραφή να βρίσκεται αριστερά για να πάρω φωτογραφία την εμπρός όψη.
        <br/>
        <b>Βήμα 3:</b> Γυρίστε το κινητό και την ταυτότητα σε κάθετη θέση για να πάρω φωτογραφία την πίσω όψη.
        <br/>
        <b>Βήμα 4:</b> Γυρίστε ξανά στην εμπρός κάμερα για να πάρω φωτογραφία το πρόσωπό σας.
        <br/>
        <b>*Βήμα 2 και 3:</b> Θα πρέπει η φωτογραφία του εγγράφου να λαμβάνει όλη την κάμερα του κινητού
    </div>
    <div className={css(styles.phaseTitle)}> 4η Φάση Φωτογράφησης Προσώπου
    </div>
    <div className={css(styles.paragraph)}>
        Θα προχωρήσουμε <b>στην φωτογραφία του προσώπου σας.</b> Παρακαλώ όπως <b>κοιτάξετε ευθεία</b> στην κάμερα του
        κινητού σας και <b>παραμείνετε ακίνητος</b>. Σας ευχαριστώ. Θα ήθελα <b>τώρα να περάσετε το χέρι σας μπροστά
        από το πρόσωπο σας</b>. Ευχαριστώ. Παρακαλώ περιμένετε να ολοκληρωθεί η διαδικασία ελέγχου. Ευχαριστώ.

        <div className={css(styles.phaseTitle)}> 5η Φάση Ελέγχων Αυθεντικότητας Εγγράφου Ταυτοποίησης</div>
        <b> Κρατήστε το</b> έγγραφο ταυτοποίησής σας από την πλευρά/ ανοιγμένο στην σελίδα που<b> φέρει την φωτογραφία
        σας δίπλα στο
        πρόσωπο σας και κοιτάξτε την κάμερα.</b> Παρακαλώ <b>Λυγίστε</b> τώρα το έγγραφο ταυτοποίησής σας.

        Παρακαλώ <b>περάστε το δάχτυλο ή το χέρι σας</b> μπροστά από το έγγραφο ταυτοποίησής σας. Παρακαλώ <b>πείτε μου
        τον αριθμό του
        εγγράφου ταυτοποίησής σας</b>. Εφόσον πρόκειται για έγγραφο ταυτοποίησης δύο όψεων: <b>Γυρίστε στο πίσω μέρος
        του εγγράφου
        ταυτοποίησής σας και πλησιάστε το στην κάμερα. Παρακαλώ πείτε μου την ηλικία σας</b>. Μπορείτε να αφήσετε το
        έγγραφο
        ταυτοποίησής σας.
    </div>
    <div className={css(styles.phaseTitle)}> 6η φάση Liveness Detection
    </div>
    <div className={css(styles.paragraph)}>


        Παρακαλώ <b>παραμείνετε σε ευθεία θέση</b> απέναντι στην κάμερα του κινητού και<b> σε απόσταση που θα είναι
        εμφανές το πρόσωπο
        σας και το πάνω μέρος του σώματος σας.</b> Ελάτε λίγο πιο κοντά στην κάμερα/ απομακρυνθείτε ελαφρώς από την
        κάμερα.
        Ευχαριστώ. <b>Παρακαλώ ανοιγοκλείστε τα μάτια σας.</b> Ευχαριστώ. Παρακαλώ <b>χαμογελάστε.</b> Ευχαριστώ
    </div>
    <div className={css(styles.phaseTitle)}> 7η Φάση Ελέγχων Νηφαλιότητας και Μη Απειλής ή Εξαναγκασμού
    </div>
    <div className={css(styles.paragraph)}>

        <b>Χωρίς να κοιτάξετε το έγγραφο ταυτοποίησής σας, παρακαλώ πείτε μου τον τόπο γέννησής σας.</b>
    </div>
    <div className={css(styles.phaseTitle)}> 8η Φάση Αποστολής OTP</div>
    <div className={css(styles.paragraph)}>
        <span className={css(styles.highlightYellow)}>Παρακαλώ πείτε μου τον αριθμό του κινητού σας τηλεφώνου </span>
        για να σας αποσταλεί ένας μοναδικός κωδικός/ one time password/ OTP.
        Θα πρέπει να καταχωρήσετε τον αριθμό αυτό πατώντας το μπλε κουμπί στο κάτω μέρος δεξιά της οθόνης του κινητού
        προκειμένου να κάνετε την εισαγωγή του. Ευχαριστώ.
    </div>
    <div className={css(styles.phaseTitle)}> 9η Φάση Ολοκλήρωσης Διαδικασίας Ταυτοποίησης</div>
    <div className={css(styles.paragraph)}>

        Πριν ολοκληρώσουμε την διαδικασία ταυτοποίησης <b>θα ήθελα να σας υποβάλλω μία ερώτηση. Βρήκατε σύνθετη την
        διαδικασία
        ταυτοποίησης;</b> (Αναμονή για την απάντηση του χρήστη. Σημειώνεται εδώ ότι η ερώτηση γίνεται χωρίς να
        ενδιαφέρει ή
        να
        καταγράφεται κάπου η απάντηση που θα δοθεί. ) <b>Ολοκληρώθηκε</b> η διαδικασία της εξ αποστάσεως ηλεκτρονικής
        ταυτοποίησης.
        Ευχαριστούμε για τον χρόνο σας. <b>Για την εξέλιξη της αίτησης σας θα ενημερωθείτε σύντομα από τον
        Οργανισμό.</b>
    </div>
</div>


export default scenario3
