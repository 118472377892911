import {BYTE_REASONS, DOCUMENT_TYPES} from './MdiEnumerations';

const documentIDOptions = [
    { value: 1, label: 'Διαβατήρια'/** EX 'Ελληνικές Ταυτότητες Παλαιού τύπου' **/},
    { value: 2, label: 'Άδεια Παραμονής' /** EX 'Ελληνικές Ταυτότητες Νέου τύπου' **/ },
    { value: 3, label: 'Gov Declaration' /** EX 'Διαβατήρια που έχουν εκδοθεί από χώρα της ΕΕ ( και Ηνωμένου Βασιλείου… )' **/ }
];


const rejectionReasons = [
    {
        mellonId: 9,
        byteId: BYTE_REASONS.QUALITY,
        value: ' Η ταυτοποίηση δεν είναι δυνατή λόγω ελλιπούς φωτισμού, κακής ποιότητας εικόνας ή / και ήχου, διακοπών στη μετάδοση δεδομένων ή διακοπών στη ροή της διαδικασίας.',
        label: ' Η ταυτοποίηση δεν είναι δυνατή λόγω ελλιπούς φωτισμού, κακής ποιότητας εικόνας ή / και ήχου, διακοπών στη μετάδοση δεδομένων ή διακοπών στη ροή της διαδικασίας.',
        willBanClient: false,
    },
    {
        mellonId: 10,
        byteId: BYTE_REASONS.NOT_SUITABLE_DOCUMENT,
        value: 'Tο έγγραφο ταυτοποίησης δεν είναι κατάλληλο (εκτός λίστας εγγράφων)',
        label: 'Tο έγγραφο ταυτοποίησης δεν είναι κατάλληλο (εκτός λίστας εγγράφων)',
        willBanClient: false,
    },
    {
        mellonId: 15,
        byteId: BYTE_REASONS.DOUBT_VALIDITY,
        value: 'Εγείρονται αμφιβολίες ως προς την εγκυρότητα και την αξιοπιστία του εγγράφου ταυτοποίησης.',
        label: 'Εγείρονται αμφιβολίες ως προς την εγκυρότητα και την αξιοπιστία του εγγράφου ταυτοποίησης.',
        willBanClient: true,
    },
    {
        mellonId: 11,
        byteId: BYTE_REASONS.DOUBT_OTHER,
        value: 'Yπάρχουν αμφιβολίες σχετικά με άλλα στοιχεία που εξετάζονται κατά τη διαδικασία.',
        label: 'Yπάρχουν αμφιβολίες σχετικά με άλλα στοιχεία που εξετάζονται κατά τη διαδικασία.',
        willBanClient: true,
    },
    {
        mellonId: 12,
        byteId: BYTE_REASONS.THIRD_PERSON,
        value: 'Δεν είναι εφικτή η επικοινωνία με το φυσικό πρόσωπο για λόγους εκτός αυτών που αναφέρονται στο σημείο α ανωτέρω ή εμφανίζεται ακούσια ή εκούσια τρίτο πρόσωπο εκτός του ταυτοποιούμενου, κατά την διαδικασία.',
        label: 'Δεν είναι εφικτή η επικοινωνία με το φυσικό πρόσωπο για λόγους εκτός αυτών που αναφέρονται στο σημείο α ανωτέρω ή εμφανίζεται ακούσια ή εκούσια τρίτο πρόσωπο εκτός του ταυτοποιούμενου, κατά την διαδικασία.',
        willBanClient: false,
    },
    {
        mellonId: 13,
        byteId: BYTE_REASONS.UNDER_COMPULSION,
        value: 'Υπάρχουν ενδείξεις ότι ο ταυτοποιούμενος τελεί υπό εξαναγκασμό, ψυχική ή διανοητική διαταραχή ή την επήρεια ουσιών.',
        label: 'Υπάρχουν ενδείξεις ότι ο ταυτοποιούμενος τελεί υπό εξαναγκασμό, ψυχική ή διανοητική διαταραχή ή την επήρεια ουσιών.',
        willBanClient: false,
    },
];

const documentProperties = [
    { // Passport
        providerName: process.env.REACT_APP_MDI_DOCUMENT_STORAGE_PROVIDER_NAME,
        ocrProviderName: undefined,
        documentTypeId: DOCUMENT_TYPES.JPEG,
        storageTypeId: 1,
        frontSideTemplateId: 1,
        backSideTemplateId: undefined,
        twoSide: false,
        greekId: false,
    },
    { // Resident Permit
        providerName: process.env.REACT_APP_MDI_DOCUMENT_STORAGE_PROVIDER_NAME,
        ocrProviderName: process.env.REACT_APP_MDI_DOCUMENT_STORAGE_OCR_PROVIDER_NAME,
        documentTypeId: DOCUMENT_TYPES.JPEG,
        storageTypeId: 1,
        frontSideTemplateId: 2,
        backSideTemplateId: undefined,
        twoSide: false,
        greekId: false,
    },
    { // Gov Declaration
        providerName: process.env.REACT_APP_MDI_DOCUMENT_STORAGE_PROVIDER_NAME,
        ocrProviderName: process.env.REACT_APP_MDI_DOCUMENT_STORAGE_OCR_PROVIDER_NAME,
        documentTypeId: DOCUMENT_TYPES.JPEG,
        storageTypeId: 1,
        frontSideTemplateId: 3,
        backSideTemplateId: undefined,
        twoSide: false,
        greekId: false,
    }
];
const selfieStorageDocumentProperties = {
    providerName: process.env.REACT_APP_MDI_DOCUMENT_STORAGE_PROVIDER_NAME,
    ocrProviderName: undefined,
    documentTypeId: DOCUMENT_TYPES.JPEG,
    storageTypeId: 1,
    templateId: 4
}
export {documentProperties}
export {rejectionReasons}
export {documentIDOptions}
export {selfieStorageDocumentProperties}
