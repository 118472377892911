import {StyleSheet} from 'aphrodite';
import theme from '../../common/theme/mellon';

export default StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
        width: '100%',
        height: '100%',
        // maxWidth: 1300,
        // backgroundColor: theme.palette.background.paper,
        overflowY: "auto"
    },
    mainContainer: {
        display: 'flex',
        height: '100%',
        // flexDirection: 'column',
        flexGrow: 1,
        // height: 'calc(100% - 90px)',
    },
    videoSection: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        paddingRight: 0,
        flexGrow: 1,
    },
    sidebarButton: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left: -24,
        backgroundColor: theme.palette.primary.main,
        height: 24,
        width: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        borderBottomLeftRadius: 5,
    },
    htmlViewer: {
        position: 'relative',
        backgroundColor: 'white',
        padding: 10,
        // height: '100%',
        overflowY: 'auto',
        flexGrow: 1,
    },
    htmlViewerCollapsed: {
        // position: 'relative',
        backgroundColor: 'white',
        padding: 0,
        // height: '100%',
        overflowY: 'hidden',
        flexGrow: 1,
    },
    expandHtmlViewerButton: {
        // position: 'absolute',
        // top: 0,
        // left: 0,
        width: 20,
        height: 30,
        paddingBottom: 10,
        cursor: 'pointer',
    },
    imageViewer: {
        backgroundColor: 'lightgray',
        padding: 0,
        // height: '100%',
        overflowY: 'auto',
        flexGrow: 1,
        maxHeight: '50%',
        flex: '1 0 auto'
    },
    imageViewerCollapsed: {
        backgroundColor: 'lightgray',
        padding: 0,
        // height: '100%',
        overflowY: 'hidden',
        flexGrow: 1,
    },
    imageViewerExpanded: {
        maxHeight: '90%'
    },
    sidebarExpanded: {
        overflowY: 'auto',
        position: 'relative',
        paddingTop: 0,
        paddingRight: 0,
        width: 500,
        backgroundColor: 'lightgray',
        borderBottom: '1px solid',
        borderColor: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    sidebarCollapsed: {
        overflowY: 'auto',
        position: 'relative',
        paddingTop: 0,
        paddingRight: 0,
        width: 0,
        backgroundColor: 'lightgray',
        borderBottom: '1px solid',
        borderColor: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column'
    },
    footerContainer: {
        height: 90,
        borderTop: '1px solid',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 10,
        paddingLeft: 10,
        display: 'flex',
        justifyContent: 'space-between'
    },
    videoModalContent: {
        backgroundColor: 'gray'
    },
    title: {
        fontSize: 26,
        fontWeight: "bold",
        color: theme.palette.primary.main,
        borderBottom: "1px solid",

    },
    renderer: {
        flexGrow: 1,
        width: '100%',
        height: 'auto',
    },
    smallNestedRenderer: {
        flexGrow: 1,
        width: '100%',
        height: 'auto',
    },
    floatingRendererContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'gray',
        height: 480,
        width: 720,
        border: '1px solid',
        borderColor: theme.palette.primary.main
    },
    nestedRendererContainer: {
        position: 'absolute',
        bottom: 30,
        right: 0,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'gray',
        height: 120,
        width: 180,
        border: '1px solid',
        borderColor: '#777777',
        zIndex: 1,
    },
    rendererContainer: {
        position: 'absolute',
        bottom: 30,
        right: 0,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'gray',
        height: 120,
        width: 180,
        border: '1px solid',
        borderColor: '#777777',
        zIndex: 1,
    },
    remoteRendererContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'gray',
        height: 'calc(100% - 100px)',
        width: 'auto',
        border: '1px solid',
        borderColor: theme.palette.primary.main
    },
    remoteRenderer: {
        flexGrow: 1,
        width: '100%',
        height: 'auto',
    },
    rendererFooter: {
        backgroundColor: 'lightgray',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rendererFooterLeft: {
        fontWeight: 'bold',
        backgroundColor: 'lightgray',
        color: theme.palette.primary.main,
        paddingLeft: 10,
        paddingRight: 10,
        height: 30,
        display: 'flex',
        alignItems: 'center',
    },
    rendererFooterRight: {
        fontWeight: 'bold',
        backgroundColor: 'lightgray',
        color: theme.palette.primary.main,
        paddingLeft: 10,
        paddingRight: 10,
        height: 30,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    footerToolbar: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: 'transparent',
        // position: 'absolute',
        // bottom: 5,
        color: theme.palette.primary.main,
        // left: 0,
        // paddingLeft: 10,
    },
    footerToolbarButton: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 35,
        height: 70,
        width: 70,
        padding: 10,
        marginRight: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    footerToolbarButtonSmall: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 35,
        height: 40,
        width: 40,
        padding: 7,
        marginBottom: 2,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    footerToolbarButtonEnabled: {
        backgroundColor: theme.palette.primary.main,
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        borderRadius: 35,
        height: 70,
        width: 70,
        padding: 10,
        marginRight: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    footerPhoneInput: {
        width: 120,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 2,
        paddingTop: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        textAlign: 'center',
        borderRadius: 4
    },
    otpPhoneInput: {
        width: 200,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 5,
        paddingTop: 5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        textAlign: 'center',
        borderRadius: 4,
        fontSize: 22,
    },
    footerToolbarButtonIcon: {
        width: '100%',
        height: '100%',
    },
    recordIcon: {
        width: 50,
        height: 50,
        marginRight: 10
    },
    breathe: {
        animationName: {
            '0%': {
                animationTimingFunction: 'cubic-bezier(0.9647,0.2413,-0.0705,0.7911)',
                transform: 'scale(0)'
            },
            '51%': {
                animationTimingFunction: 'cubic-bezier(0.9226,0.2631,-0.0308,0.7628)',
                transform: 'scale(1.02994)'
            },
            '100%': {
                transform: 'scale(0)'
            }
        },
        animationDuration: '1s',
        animationIterationCount: 'infinite'
    },
    fade: {
        animationName: {
            '0%': {
                animationTimingFunction: 'cubic-bezier(0.4184,0.2657,0.7226,0.5908)',
                opacity: 1
            },
            '100%': {
                opacity: 0
            }
        },
        animationDuration: '1s',
        animationIterationCount: 'infinite'
    },
    blink: {
        animationName: {
            '0%': {
                opacity: 1
            },
            '49.75%': {
                opacity: 1
            },
            '50.25%': {
                opacity: 0
            },
            '85.5%': {
                opacity: 0
            },
            '100%': {
                opacity: 1
            }
        },
        animationDuration: '1.7s',
        animationIterationCount: 'infinite'
    },
    footerInfo: {
        position: 'absolute',
        bottom: 5,
        color: theme.palette.primary.main,
        right: 0,
        paddingRight: 10,
    },
    screenshot: {
        height: '100%',
        width: '100%',
    },
    screenshotContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        marginBottom: 10,
    },
    screenshotToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 5,
        paddingTop: 5,
        paddingRight: 5,
        backgroundColor: 'white',
        borderRadius: 5,
    },
    screenshotTitle: {
        fontSize: 18,
        color: theme.palette.primary.main,
    },
    screenshotToolbarButtonsContainer: {
        display: 'flex'
    },
    screenshotButton: {
        height: '100%',
        width: 'auto',
        background: 'transparent',
        cursor: 'pointer',
        border: 'none'
    },
    screenshotToolbarButtonIcon: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'white',
        borderRadius: 35,
        height: 25,
        width: 25,
        padding: 5,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    screenshotToolbarButtonIconDanger: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'red',
        borderRadius: 35,
        height: 25,
        width: 25,
        padding: 5,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    inputButtonComboContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: 10,
    },
    loaderIcon: {
        width: '100%',
        height: '100%',
    },
    loader: {
        width: 100,
        height: 100,
        padding: 10,
        position: 'absolute',
        top: 'calc(50% - 100px)',
        left: '50%',
        zIndex: 100,
    },
    submitResultsForm: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    columnsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: 10
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        // marginRight: 15,
    },
    row: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: 1,
    },
    columnItemTitle: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 'bold'
    },

    hr: {
        color: theme.palette.primary.main,
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    modalFooterRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    selectOption: {
        color: 'blue'
    },
    selectOptionDanger: {
        color: 'red'
    },
    bandwidthStatsContainer: {
        color: 'white',
        paddingLeft: 5,
        paddingRight: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bandwidthStatsContainerSmall: {
        color: 'white',
        paddingLeft: 5,
        paddingRight: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 10,
    },
    confirmDialogContent: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    confirmDialogText: {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 22,
        fontWeight: 'bold',
    },
    confirmDialogButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    settingsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    settingRow: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    rendererButtonsContainer: {
        position: 'absolute',
        left: 'calc(50% - 95px)',
        bottom: 30,
        height: 70,
        width: 190,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
    },
    rendererButton: {
        // border: '1px solid rgb(162, 162, 162)',
        border: 'none',
        // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        boxShadow: 'none',
        opacity: 0.5,
        ':hover': {
            opacity: 1,
            // border: '1px solid rgb(162, 162, 162)',
            // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            // background: 'white',
            transition: 'all .2s'
        },
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 35,
        // height: 70,
        width: 70,
        padding: 10,
        // marginRight: 10,
    },
    nestedRendererButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        border: 'none',
        boxShadow: 'none',
        opacity: 0.5,
        ':hover': {
            opacity: 1,
            transition: 'all .1s'
        },
        cursor: 'pointer',
        background: 'white',
        // borderRadius: 35,
        // height: 70,
        width: 20,
        height: 20,
        padding: 1,
        // marginRight: 10,
        zIndex: 1,
    },
    nestedRendererButtonIcon: {
        width: '100%',
        height: '100%',
    },
    rendererButtonSmall: {
        height: 30,
        width: 30,
        padding: 5
    },
    modalContentCentered: {
        paddingTop: 10,
        paddingBottom: 10,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bandwidthContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        // paddingLeft: 20,
    },
    bandwidth: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    }
});
