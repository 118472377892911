import {FormControl, InputLabel} from "@mui/material";
import {css} from "aphrodite";
import styles from "./styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {MenuItemDanger} from "../MenuItemDanger";
import React from "react";
import {MenuItemSuccess} from "../MenuItemSucces";

const RejectReasonSelect = (props) => {
  const {rejectionReasons, rejectReason, setRejectReason} = props
  return (
    <FormControl className={css(styles.selectContainer)} variant={'outlined'}>
      <InputLabel id={'rejectReasonLabel'} shrink={true}>{'Αποτέλεσμα Ταυτοποίησης'}</InputLabel>
      <Select name="rejectReason" id={"rejectReason"}  onChange={setRejectReason}
              value={rejectReason ? rejectReason : ''} displayEmpty={true}
              className={css(styles.countrySelect)}  label={'Reject Reason'} labelId={'rejectReasonLabel'}
      >
        <MenuItemSuccess value={""} key={0}>
          Passed
        </MenuItemSuccess>
        {rejectionReasons.map(option => {
          if ( option.willBanClient ) {
            return (
              <MenuItemDanger value={option.mellonId} key={option.value}>
                {option.label}
              </MenuItemDanger>
            )
          }
          return (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
export default RejectReasonSelect
