import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {css} from 'aphrodite';
import {useLocation} from '@reach/router';
import styles from './styles';
import {Vidyo} from "../../../Models";
import {FloatingModal, Zoom, Modal} from '../../common/components'
import Logger from "../../../Utils/Logger";
/** DO NOT REMOVE THE LINE BELOW **/
// eslint-disable-next-line no-unused-vars
import {VidyoClientLib} from "../../../Utils/Vidyo/js/VidyoClient/22.5.1.0001/VidyoClient(22.5.1.0001).min"
/** DO NOT REMOVE THE LINE ABOVE **/
import '../../../Utils/Vidyo/css/VidyoClient.css'
import cameraOn from '../../common/icons/cameraOn.png'
import cameraOff from '../../common/icons/cameraOff.png'
import callStart from '../../common/icons/callStart.png'
import callEnd from '../../common/icons/callEnd.png'
import micOn from '../../common/icons/microphoneOn.png'
import micOff from '../../common/icons/microphoneOff.png'
import speakerOn from '../../common/icons/speakerOn.png'
import speakerOff from '../../common/icons/speakerOff.png'
import expandIcon from '../../common/icons/expand-arrows-flipped.png'
import resetIcon from '../../common/icons/reset.png'
import backgroundIcon from '../../common/icons/background_icon.png'
import backgroundIconActive from '../../common/icons/background_icon_active.png'
import {vidyoConnectorState} from "../../../Models/Vidyo/VidyoEnumerations";
import { Tooltip} from "@mui/material";
import queryString from 'query-string';
import {MdiClient} from "../../../Models";
// import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import scenario1 from "./scenarios/scenario1";
import scenario2 from "./scenarios/scenario2";
import scenario3 from "./scenarios/scenario3";
import scenario4 from "./scenarios/scenario4";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import loadingIcon from "../../common/icons/loading-animation.gif";
import Button from "@mui/material/Button";
import {MicrophoneSelect} from "../../common/components/MicrophoneSelect";
import {CameraSelect} from "../../common/components/CameraSelect";
import {SpeakerSelect} from "../../common/components/SpeakerSelect";
import {RejectReasonSelect} from "../../common/components/RejectReasonSelect";
import {CloseIcon, ZoomIcon, RecordIcon} from "../../common/components/Icons";
import {notify} from "../../../Utils";
import {CollapseIcon, ExpandIcon} from '../../common/components/Icons/Icons';
import {NavBar} from '../../layout/components';
import {CircularProgressWithLabel} from "../../common/components/CircularProgressWithLabel";

const Dashboard = ({ history }) => {

    // const [t] = useTranslation(['dashboard']);
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(true)
    const [showModalContent, setShowModalContent] = useState(true)
    const [sidebarExpanded, setSidebarExpanded] = useState(true)
    const [imageViewerExpanded, setImageViewerExpanded] = useState(true)
    const [showSelfieZoom, setShowSelfieZoom] = useState(false)
    const [showIdFrontZoom, setShowIdFrontZoom] = useState(false)
    const [showIdBackZoom, setShowIdBackZoom] = useState(false)
    const [workflowId, setWorkflowId] = useState(null)
    // const [scenario, setScenario] = useState(null)
    const [hasVidyoState, setHasVidyoState] = useState(false)

    const vidyoRef = useRef(null)
    const vidyo = useSelector(Vidyo.getVidyo);
    const mdiClient = useSelector(MdiClient.getMdiClient);
    const mdiClientIsLoading = useSelector(MdiClient.isLoading);
    const showSettingsModal = useSelector(Vidyo.showSettingsModal);
    const showOtpModal = useSelector(Vidyo.showOtpModal);
    const showAgentCameraModal = useSelector(Vidyo.showAgentCameraModal);
    const idFrontReceiveProgress = useSelector(Vidyo.idFrontReceiveProgress);
    const idBackReceiveProgress = useSelector(Vidyo.idBackReceiveProgress);
    const selfieReceiveProgress = useSelector(Vidyo.selfieReceiveProgress);

    useEffect(() => {
        let params = queryString.parse(location.search);
        Logger.console("P A R A M S : ", params)
        setWorkflowId(params.workflowId)
        vidyoRef.current = new Vidyo({
            host: process.env.REACT_APP_VIDYO_HOST,
            roomKey: params.sessionId /*|| 'yjmGi8MXVx'*/, // TODO: remove fallback room
            displayName: 'Agent A', // TODO: Get Agent Name from somewhere
            cameraViewId: process.env.REACT_APP_VIDYO_LOCAL_CAMERA_VIEW_ID,
            remoteCameraViewId: process.env.REACT_APP_VIDYO_REMOTE_CAMERA_VIEW_ID,
        })
        if (params.workflowId ) {
            MdiClient.getToken()
        } else {
            notify("Workflow ID not provided", "error")
        }


        // setScenario(getRandomScenario())

    },[])
    useEffect(() => {
        if ( mdiClient?.token && workflowId && !mdiClient?.workFlowData ) {
            MdiClient.getWorkFlow(workflowId)
        }
    }, [mdiClient?.token])
    useEffect(() => {
        // redirectToMaster()
    }, [mdiClient?.closeWorkflowResult])

    useEffect(() => {
        if ( mdiClient && workflowId && !mdiClient.workflowId ) {
            mdiClient.setWorkflowId(workflowId)
        }
    }, [mdiClient, workflowId])

    useEffect(() => {
        if ( mdiClient?.facematch ) {
            Logger.console(mdiClient?.facematch)
        }
    }, [mdiClient?.facematch])

    useEffect(() => {
        if ( vidyo ) {
            if ( vidyo.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Idle || vidyo.state === null ) {
                vidyoRef.current?.initializeVidyoConnector()
            }
            if ( vidyo.state && !hasVidyoState ) {
                setHasVidyoState(true)
            }
        } else {
            if ( hasVidyoState ) {
                setHasVidyoState(false)
            }
        }

    },[vidyo?.state])

    let interval = useRef(null);

    useEffect(() => {
        if ( vidyo?.roomName) {
            vidyoRef.current?.getStats();
            if (interval.current) {
                clearInterval(interval.current);
            }
            interval.current = setInterval(vidyoRef.current?.getStats, 1000);
            return () => {
                clearInterval(interval.current);
            };
        } else {
            clearInterval(interval.current);
        }
    }, [vidyo?.roomName]);
    useEffect(() => {
        refreshLocalCameraView()
    }, [showAgentCameraModal]);

    const refreshLocalCameraView = () => {
        stopCamera()
        startCamera()
    }
    const handlePhoneChange = (event) => {
        if ( event?.target?.value ) {
            mdiClient?.setOcrDataMobile(event.target.value)
        }
    }

    // const handleCloseModal = () => {
    //     setIsModalOpen(false)
    //     // hideCameraView()
    //     // vidyoRef.current?.stopCamera()
    //     stopCamera()
    //     vidyoRef.current?.stopMic()
    // }
    //
    // const handleToggleShowModalContent = () => {
    //     if ( showModalContent ) {
    //         vidyoRef.current?.hideLocalCameraView()
    //         setShowModalContent(false)
    //     } else {
    //         setShowModalContent(true)
    //         vidyoRef.current?.showSelectedLocalCamera()
    //     }
    // }

    const stopCamera = () => {
        vidyoRef.current?.stopCamera()
        vidyoRef.current?.hideLocalCameraView()
    }
    const startCamera = () => {
        vidyoRef.current?.startCamera()
    }

    const toggleCamera = () => {
        if (vidyo?.isLocalCameraEnabled) {
            Logger.log('stopping camera')
            stopCamera()
        } else {
            Logger.log('starting camera')
            if ( !isModalOpen ) {
                setIsModalOpen(true)
            }
            if ( !showModalContent ) {
                setShowModalContent(true)
            }
            startCamera()
        }
    }
    const toggleMicrophone = () => {
        if ( vidyo?.isLocalMicrophoneEnabled ) {
            vidyoRef.current?.stopMic()
        } else {
            vidyoRef.current?.startMic()
        }
    }
    const toggleSpeaker = () => {
        if ( vidyo?.isLocalSpeakerEnabled ) {
            vidyoRef.current?.stopSpeaker()
        } else {
            vidyoRef.current?.startSpeaker()
        }
    }
    const resetRemoteDevices = () => {
        vidyoRef.current?.requestRemoteDevicesReset('cam')
    }
    const selectCameraById = (event) => {
        vidyoRef.current?.selectCameraById(event.target.value)
    }
    const selectMicrophoneById = (event) => {
        vidyoRef.current?.selectMicById(event.target.value)
    }
    const selectSpeakerById = (event) => {
        vidyoRef.current?.selectSpeakerById(event.target.value)
    }

    const toggleJoinLeaveConference = async () => {
        if ( vidyo?.roomKey ) {
            if ( await vidyoRef.current?.isConnected() ) {
                vidyoRef.current?.disconnect()
            } else {
                await vidyoRef.current?.connectToConference()
            }
        } else {
            notify("Room Key not provided", "error")
        }
    }
    const closeWorkflow = async () => {
        if ( await vidyoRef.current.isConnected()) {
            notify("Please disconnect from conference room first", "warn");
        } else {
            const result = await MdiClient.closeWorkflow()
            Logger.console("Close Workflow Result: ", result);
            if (result) {
                notify("Workflow Closed Successfully", "success")
            } else {
                notify("There was an error closing workflow", "error")
            }
        }
    }


    const sendOtp = async () => {
        if ( mdiClient?.ocrDataMobile ) {
            // mdiClient.generateOtp()
            // mdiClient?.sendOtpSms()
            await mdiClient?.requestOtpSms()
            vidyoRef.current?.sendGroupChatMessage(`otp_${mdiClient.OTP}`)
            Vidyo.toggleOtpModal()
        }
    }

    // const getRandomScenario = () => {
    //     const index = Math.floor(Math.random() * 4)
    //     const scenarios = [scenario1, scenario2, scenario3, scenario4]
    //     if ( scenarios[index] ) {
    //         return scenarios[index]
    //     }
    //     return null
    // }

    const toggleSidebar = () => {
        // setSidebarExpanded(!sidebarExpanded)
        setImageViewerExpanded(!imageViewerExpanded)
    }

    const redirectToMaster = async () => {
        if ( mdiClient?.isWorkflowClosed ) {
            mdiClient?.setShowConfirmDialog(true)
        }
    }

    return (
        <div className={css(styles.container)}>
            <div id={"main"} className={css(styles.mainContainer)}>
                <Zoom isOpen={showSelfieZoom} parentId={'main'} title={'Selfie'}
                      handleClose={() => setShowSelfieZoom(false)} src={mdiClient?.selfie.imageUri}/>
                <Zoom isOpen={showIdFrontZoom} parentId={'main'} title={'ID Front'}
                      handleClose={() => setShowIdFrontZoom(false)} src={mdiClient?.IDFront.imageUri}/>
                <Zoom isOpen={showIdBackZoom} parentId={'main'} title={'ID Front'}
                      handleClose={() => setShowIdBackZoom(false)} src={mdiClient?.IDBack.imageUri}/>
                {/*REMOTE PARTICIPANT VIDEO*/}
                <div className={css(styles.videoSection)}>
                    <NavBar closeWorkflow={closeWorkflow} vidyoRef={vidyoRef.current}/>
                    {/*AGENT CAMERA MODAL*/}
                    <FloatingModal
                        isOpen={showAgentCameraModal} handleClose={Vidyo.toggleAgentCameraModal} parentId={"router"}
                        title={vidyo?.selectedCameraId ? Vidyo.formatDeviceName(vidyoRef.current?.cameras[vidyo?.selectedCameraId]?.name)  : 'No Camera Selected'}
                        contentClassName={css(styles.videoModalContent)} extraButtonTitle={"Virtual Background"}
                        extraButtonIcon={vidyo?.isVideoBackgroundActive ? backgroundIconActive : backgroundIcon}
                        extraButtonAction={ process.env.REACT_APP_VIDYO_ENABLE_VIDEO_BACKGROUND === 'true' ? () => vidyoRef?.current?.toggleVideoBackground() : null}
                    >
                        <div className={css(styles.floatingRendererContainer)}>
                            {/*AGENT VIDEO RENDERER*/}
                            <div id={process.env.REACT_APP_VIDYO_LOCAL_CAMERA_VIEW_ID} className={css(styles.renderer)}></div>
                            {vidyo?.connected &&
                              <div className={css(styles.bandwidthStatsContainer)}>
                                  <div>Send Bandwidth: {vidyo?.sendBandwidthPercent}%</div>
                                  <div>Receive Bandwidth: {vidyo?.receiveBandwidthPercent}%</div>
                              </div>
                            }
                        </div>
                    </FloatingModal>
                    {/*RESULTS FORM SUBMIT MODAL*/}
                    <Modal open={!!mdiClient?.showSubmitModal} title={'Αποτελέσματα Βιομετρικού Ελέγχου'} handleClose={()=> mdiClient?.setShowSubmitModal(false)}>
                        <div className={css(styles.submitResultsForm)}>
                            <div className={css(styles.columnsContainer)}>
                                <div className={css(styles.column)}>
                                    {mdiClient?.thalesFaceResult &&
                                      <>
                                          {/*<div className={css(styles.columnItemTitle)}>*/}
                                          {/*    Facematch result: {mdiClient?.thalesFaceResult?.result}*/}
                                          {/*</div>*/}
                                          <div className={css(styles.columnItemTitle)}>
                                              Facematch score: {!isNaN(mdiClient?.thalesFaceResult?.score) ? Math.round(parseFloat(mdiClient?.thalesFaceResult?.score)) : mdiClient?.thalesFaceResult?.score}%
                                          </div>
                                      </>
                                    }
                                    {mdiClient?.thalesDocumentResult &&
                                      <>
                                          <div className={css(styles.columnItemTitle)}>
                                              Επικύρωση Εγγράφου: {parseInt(mdiClient?.thalesDocumentResult?.result || 1) === 0 ? "ΝΑΙ" : "ΟΧΙ"}
                                              {' '}({mdiClient?.thalesDocumentResult?.description})
                                          </div>
                                      </>
                                    }
                                </div>
                            </div>
                            <div className={css(styles.columnsContainer)}>
                                <div className={css(styles.column)}>
                                    <div className={css(styles.columnItemTitle)}>
                                        Liveness Passed: <input type="checkbox" checked={mdiClient?.livenessPassed} onClick={mdiClient?.toggleLivenessPassed} readOnly value={"something"}/>
                                    </div>
                                    <div className={css(styles.columnItemTitle)}>
                                        Steps Completed: <input type="checkbox" checked={mdiClient?.stepsCompleted} onClick={mdiClient?.toggleStepsCompleted} readOnly value={"something"}/>
                                    </div>
                                </div>
                                <div className={css(styles.column)}>
                                    <div className={css(styles.row)}>
                                        <RejectReasonSelect rejectionReasons={mdiClient?.rejectionReasons} rejectReason={mdiClient?.rejectReason} setRejectReason={mdiClient?.setRejectReason}/>
                                    </div>
                                </div>
                            </div>
                            <hr className={css(styles.hr)}/>
                            <div className={css(styles.modalFooterRow)}>
                                <Button onClick={mdiClient?.submitResults} variant={'outlined'}>Υποβολή</Button>
                            </div>
                        </div>
                    </Modal>
                     {/* Redirect to Master Confirm Dialog */}
                    <Modal open={!!(mdiClient?.showConfirmDialog)} title={'Μετάβαση σε Master'}
                           handleClose={()=> mdiClient?.setShowConfirmDialog(false)}>
                        <div className={css(styles.confirmDialogContent)}>
                            <div className={css(styles.confirmDialogText)}>
                                Θέλετε να μεταβείτε απευθείας στη Master?
                            </div>
                        </div>
                        <div className={css(styles.confirmDialogButtonContainer)}>
                            <Button onClick={()=> mdiClient?.setShowConfirmDialog(false)} variant={'outlined'}>ΑΚΥΡΩΣΗ</Button>
                            <Button onClick={mdiClient?.redirectToMaster} variant={'contained'}>ΜΕΤΑΒΑΣΗ</Button>
                        </div>
                    </Modal>
                    {/* Send OTP Dialog */}
                    <Modal open={!!(showOtpModal)} title={'Αποστολή OTP'} width={500}
                           handleClose={Vidyo.toggleOtpModal}>
                        <div className={css(styles.modalContentCentered)}>
                            <div>Αποστολή OTP στον αριθμό:</div>
                            <input className={css(styles.otpPhoneInput)} type="text"
                                   value={mdiClient?.ocrDataMobile || ''} onChange={handlePhoneChange}/>
                        </div>
                        <div className={css(styles.confirmDialogButtonContainer)}>
                            <Button onClick={Vidyo.toggleOtpModal} variant={'outlined'}>ΑΚΥΡΩΣΗ</Button>
                            <Button onClick={sendOtp} variant={'contained'}>Αποστολή</Button>
                        </div>
                    </Modal>
                    {/* Video/Audio Settings Modal */}
                    <Modal open={showSettingsModal} title={'Video/Audio Settings'}
                           handleClose={Vidyo.toggleSettingsModal}>
                        <div className={css(styles.footerToolbar)}>
                            {hasVidyoState &&
                                <div className={css(styles.settingsContainer)}>
                                    <div className={css(styles.settingRow)}>
                                        <CameraSelect cameras={vidyoRef.current?.cameras}
                                                      selectedCameraId={vidyoRef.current?.selectedCameraId}
                                                      selectCameraById={selectCameraById} disabled={!showModalContent}/>
                                        <button onClick={toggleCamera}
                                                className={css(vidyo?.isLocalCameraEnabled ? styles.footerToolbarButtonEnabled : styles.footerToolbarButton)}>
                                            <img src={vidyo?.isLocalCameraEnabled ? cameraOn : cameraOff}
                                                 alt="cameraIcon"
                                                 className={css(styles.footerToolbarButtonIcon)}/>
                                        </button>
                                        {mdiClient?.isRecording && <Tooltip title={'Recording'}>
                                            <div>
                                                <RecordIcon/>
                                            </div>
                                        </Tooltip>}
                                    </div>
                                    <div className={css(styles.settingRow)}>
                                        <MicrophoneSelect microphones={vidyoRef.current?.microphones}
                                                          selectedMicrophoneId={vidyoRef.current?.selectedMicrophoneId}
                                                          selectMicrophoneById={selectMicrophoneById}/>
                                        <button onClick={toggleMicrophone}
                                                className={css(vidyo?.isLocalMicrophoneEnabled ? styles.footerToolbarButtonEnabled : styles.footerToolbarButton)}>
                                            <img src={vidyo?.isLocalMicrophoneEnabled ? micOn : micOff} alt="micIcon"
                                                 className={css(styles.footerToolbarButtonIcon)}/>
                                        </button>
                                    </div>
                                    <div className={css(styles.settingRow)}>
                                        <SpeakerSelect speakers={vidyoRef.current?.speakers}
                                                       selectedSpeakerId={vidyoRef.current?.selectedSpeakerId}
                                                       selectSpeakerById={selectSpeakerById}/>
                                        <button onClick={toggleSpeaker}
                                                className={css(vidyo?.isLocalSpeakerEnabled ? styles.footerToolbarButtonEnabled : styles.footerToolbarButton)}>
                                            <img src={vidyo?.isLocalSpeakerEnabled ? speakerOn : speakerOff}
                                                 alt="speakerIcon" className={css(styles.footerToolbarButtonIcon)}/>
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={css(styles.bandwidthContainer)}>
                            {!!vidyo?.sendBandwidth &&
                                <Tooltip title={'Transmit Bandwidth'}>
                                    <div
                                        className={css(styles.bandwidth)}>Transmit: {vidyo.sendBandwidth > 1024 ? (vidyo.sendBandwidth / 1024).toFixed(3) : vidyo.sendBandwidth} {vidyo.sendBandwidth > 1024 ? 'Mbps' : 'Kbps'}</div>
                                </Tooltip>
                            }
                            {!!vidyo?.receiveBandwidth &&
                                <Tooltip title={'Receive Bandwidth'}>
                                    <div
                                        className={css(styles.bandwidth)}>Receive: {vidyo.receiveBandwidth > 1024 ? (vidyo.receiveBandwidth / 1024).toFixed(3) : vidyo.receiveBandwidth} {vidyo.receiveBandwidth > 1024 ? 'Mbps' : 'Kbps'}</div>
                                </Tooltip>
                            }
                        </div>
                    </Modal>
                    <div className={css(styles.remoteRendererContainer)}>
                        {/*REMOTE PARTICIPANT VIDEO RENDERER*/}
                        <div id={process.env.REACT_APP_VIDYO_REMOTE_CAMERA_VIEW_ID}
                             className={css(styles.remoteRenderer)}>

                        </div>
                        {!showAgentCameraModal &&
                            <div className={css(styles.nestedRendererContainer)}>
                                {/*AGENT VIDEO RENDERER*/}
                                <div id={process.env.REACT_APP_VIDYO_LOCAL_CAMERA_VIEW_ID}
                                     className={css(styles.smallNestedRenderer)}>
                                </div>
                                <button onClick={Vidyo.toggleAgentCameraModal}
                                        className={css(styles.nestedRendererButton)}>
                                    <img
                                        src={expandIcon}
                                        alt="expand"
                                        className={css(styles.nestedRendererButtonIcon)}/>
                                </button>
                                {vidyo?.connected &&
                                    <div className={css(styles.bandwidthStatsContainerSmall)}>
                                        <div>TX: {vidyo?.sendBandwidthPercent}%</div>
                                        <div>RX: {vidyo?.receiveBandwidthPercent}%</div>
                                    </div>
                                }
                            </div>
                        }
                        <div className={css(styles.rendererButtonsContainer)}>
                            <button onClick={toggleMicrophone}
                                    className={css(styles.rendererButton, styles.rendererButtonSmall)}>
                                <img src={vidyo?.isLocalMicrophoneEnabled ? micOn : micOff} alt="micIcon"
                                     className={css(styles.footerToolbarButtonIcon)}/>
                            </button>
                            <button onClick={toggleSpeaker}
                                    className={css(styles.rendererButton, styles.rendererButtonSmall)}>
                                <img src={vidyo?.isLocalSpeakerEnabled ? speakerOn : speakerOff}
                                     alt="speakerIcon" className={css(styles.footerToolbarButtonIcon)}/>
                            </button>
                            <button onClick={toggleJoinLeaveConference} className={css(styles.rendererButton)}>
                                <img
                                    src={vidyo?.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected ? callEnd : callStart}
                                    alt="callIcon"
                                    className={css(styles.footerToolbarButtonIcon)}/>
                            </button>
                            <button onClick={toggleCamera}
                                    className={css(styles.rendererButton, styles.rendererButtonSmall)}>
                                <img src={vidyo?.isLocalCameraEnabled ? cameraOn : cameraOff} alt="cameraIcon"
                                     className={css(styles.footerToolbarButtonIcon)}/>
                            </button>
                            {!!vidyo?.participant && (
                                <button onClick={resetRemoteDevices}
                                        className={css(styles.rendererButton, styles.rendererButtonSmall)}>
                                    <img src={resetIcon} alt="resetIcon"
                                         className={css(styles.footerToolbarButtonIcon)}/>
                                </button>
                            )}
                        </div>
                        <div className={css(styles.rendererFooter)}>
                            <div className={css(styles.rendererFooterLeft)}>
                                {!!vidyo?.participantName && vidyo?.participantName + ' | '}
                                {!!vidyo?.receivedMessage && ' -> Says: ' + vidyo?.receivedMessage + ' | '}
                                {'Status: ' + vidyo?.stateDescription} {vidyo?.roomName && 'Room: ' + vidyo?.roomName}
                                {/*OCR Data Mobile: {mdiClient?.ocrDataMobile}*/}
                            </div>
                            <div className={css(styles.rendererFooterRight)}>
                                v{process.env.REACT_APP_VERSION}
                            </div>

                        </div>
                    </div>
                </div>
                {/*SIDEBAR*/}
                <div className={css(sidebarExpanded ? styles.sidebarExpanded : styles.sidebarCollapsed)}>
                    <div
                        className={css(sidebarExpanded ? styles.imageViewer : styles.imageViewerCollapsed, imageViewerExpanded && styles.imageViewerExpanded)}>
                        {mdiClient?.IDFront.imageUri &&
                            <div className={css(styles.screenshotContainer)}>
                                <div className={css(styles.screenshotToolbar)}>
                                    <div className={css(styles.screenshotTitle)}>Εμπρός Όψη</div>
                                    <div className={css(styles.screenshotToolbarButtonsContainer)}>
                                        <button className={css(styles.screenshotButton)}
                                                onClick={() => setShowIdFrontZoom(true)}>
                                            <ZoomIcon/>
                                        </button>
                                        {/*<button className={css(styles.screenshotButton)}*/}
                                        {/*        onClick={mdiClient?.cropIDFront}>*/}
                                        {/*    <CropIcon/>*/}
                                        {/*</button>*/}
                                        <button className={css(styles.screenshotButton)}
                                                onClick={mdiClient?.removeIdFrontImage}>
                                            <CloseIcon/>
                                        </button>
                                    </div>
                                </div>
                                {/*<ReactCrop crop={mdiClient.IDFront.crop} onChange={mdiClient.setIDFrontCrop}*/}
                                {/*           ruleOfThirds>*/}
                                <img id={mdiClient.IDFront.uuid} className={css(styles.screenshot)}
                                         src={mdiClient.IDFront.imageUri} alt="front"/>
                                {/*</ReactCrop>*/}
                            </div>
                        }
                        {!mdiClient?.IDFront.imageUri && vidyo?.isReceivingIDFront &&
                            <div className={css(styles.screenshotContainer)}>
                                <div className={css(styles.screenshotToolbar)}>
                                    <div className={css(styles.screenshotTitle)}>Εμπρός Όψη</div>
                                    <div className={css(styles.screenshotToolbarButtonsContainer)}>
                                    </div>
                                </div>
                                <CircularProgressWithLabel value={idFrontReceiveProgress} />
                            </div>
                        }
                        {mdiClient?.IDBack.imageUri &&
                            <div className={css(styles.screenshotContainer)}>
                                <div className={css(styles.screenshotToolbar)}>
                                    <div className={css(styles.screenshotTitle)}>Πίσω Όψη</div>
                                    <div className={css(styles.screenshotToolbarButtonsContainer)}>
                                        <button className={css(styles.screenshotButton)}
                                                onClick={() => setShowIdBackZoom(true)}>
                                            <ZoomIcon/>
                                        </button>
                                        {/*<button className={css(styles.screenshotButton)}*/}
                                        {/*        onClick={mdiClient?.cropIDBack}>*/}
                                        {/*    <CropIcon/>*/}
                                        {/*</button>*/}
                                        <button className={css(styles.screenshotButton)}
                                                onClick={mdiClient?.removeIdBackImage}>
                                            <CloseIcon/>
                                        </button>
                                    </div>
                                </div>
                                {/*<ReactCrop crop={mdiClient.IDBack.crop} onChange={mdiClient.setIDBackCrop} ruleOfThirds>*/}
                                <img id={mdiClient.IDBack.uuid} className={css(styles.screenshot)} src={mdiClient.IDBack.imageUri} alt="back"/>
                                {/*</ReactCrop>*/}
                            </div>
                        }
                        {!mdiClient?.IDBack.imageUri && vidyo?.isReceivingIDBack &&
                            <div className={css(styles.screenshotContainer)}>
                                <div className={css(styles.screenshotToolbar)}>
                                    <div className={css(styles.screenshotTitle)}>Πίσω Όψη</div>
                                    <div className={css(styles.screenshotToolbarButtonsContainer)}>
                                    </div>
                                </div>
                                <CircularProgressWithLabel value={idBackReceiveProgress} />
                            </div>
                        }
                        {mdiClient?.selfie.imageUri &&
                            <div className={css(styles.screenshotContainer)}>
                                <div className={css(styles.screenshotToolbar)}>
                                    <div className={css(styles.screenshotTitle)}>Πρόσωπο</div>
                                    <div className={css(styles.screenshotToolbarButtonsContainer)}>
                                        <button className={css(styles.screenshotButton)} onClick={() => setShowSelfieZoom(true)}>
                                            <ZoomIcon/>
                                        </button>
                                        {/*<button className={css(styles.screenshotButton)} onClick={mdiClient?.cropSelfie}>*/}
                                        {/*    <CropIcon/>*/}
                                        {/*</button>*/}
                                        <button className={css(styles.screenshotButton)} onClick={mdiClient?.removeSelfieImage}>
                                            <CloseIcon/>
                                        </button>
                                    </div>
                                </div>
                                {/*<ReactCrop crop={mdiClient.selfie.crop} onChange={mdiClient.setSelfieCrop} ruleOfThirds>*/}
                                    <img id={mdiClient.selfie.uuid} className={css(styles.screenshot)} src={mdiClient.selfie.imageUri} alt="selfie"/>
                                {/*</ReactCrop>*/}
                            </div>
                        }
                        {!mdiClient?.selfie.imageUri && vidyo?.isReceivingSelfie &&
                            <div className={css(styles.screenshotContainer)}>
                                <div className={css(styles.screenshotToolbar)}>
                                    <div className={css(styles.screenshotTitle)}>Πρόσωπο</div>
                                    <div className={css(styles.screenshotToolbarButtonsContainer)}>

                                    </div>
                                </div>
                                <CircularProgressWithLabel value={selfieReceiveProgress} />
                            </div>
                        }
                    </div>
                    <div className={css(sidebarExpanded ? styles.htmlViewer : styles.htmlViewerCollapsed)}>
                        {(mdiClient?.IDFront.imageUri || mdiClient?.IDBack.imageUri || mdiClient?.selfie.imageUri) &&
                            <div className={css(styles.expandHtmlViewerButton)} onClick={toggleSidebar}>
                                {imageViewerExpanded ? <CollapseIcon/> : <ExpandIcon/>}
                            </div>
                        }

                        {scenario1}
                    </div>
                </div>
                {mdiClient?.canDoFacematch && mdiClientIsLoading &&
                    <div className={css(styles.loader)}>
                        <img src={loadingIcon} alt="loading"
                             className={css(styles.loaderIcon)}/>
                    </div>}
            </div>
            <ToastContainer/>
        </div>
    );
};

const memoizedDashboard = React.memo(Dashboard);
export {memoizedDashboard as Dashboard};
