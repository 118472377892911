const BYTE_REASONS = {
    QUALITY: 'QUALITY',
    NOT_SUITABLE_DOCUMENT: 'NOT_SUITABLE_DOCUMENT',
    DOUBT_VALIDITY: 'DOUBT_VALIDITY',
    DOUBT_OTHER: 'DOUBT_OTHER',
    THIRD_PERSON: 'THIRD_PERSON',
    UNDER_COMPULSION: 'UNDER_COMPULSION',
    APPLICATION_INCORRECT: 'APPLICATION_INCORRECT',
    DECLARATION_INCORRECT: 'DECLARATION_INCORRECT'
}
const DOCUMENT_PROCESSING_RESULT = [
    'SUCCESS',
    'DOCUMENT_EXPIRED',
    'DATA_INTEGRITY_ISSUE',
    'SECURITY_ISSUE'
]
const DOCUMENT_TYPES = {
    JPG: 0,
    JPEG: 1,
    TIFF: 2,
    PNG: 3,
    PEG: 4,
    PDF: 5
}
export {BYTE_REASONS}
export {DOCUMENT_PROCESSING_RESULT}
export {DOCUMENT_TYPES}
