import {combineReducers} from 'redux';

import vidyo from './vidyo';
import mdiClient from './mdiClient';

const rootReducer = combineReducers({
    vidyo,
    mdiClient
});

export {rootReducer};
