import Model from "../Model";
import {dispatchHelper, isObject, Logger, notify} from "../../Utils";
import * as type from "../../State/modules/mdiClient/types";
import {createSelector} from "reselect";
import {store} from "../../State/store";
import {MdiService} from "./services";
import {Vidyo} from "../index";
import {documentIDOptions, documentProperties, rejectionReasons, selfieStorageDocumentProperties} from './options';
import uuid from 'react-uuid';
import {toast} from "react-toastify";
import Request from '../../Utils/Request'
import {DOCUMENT_PROCESSING_RESULT} from './MdiEnumerations';
const mdiClient = () => store.getState().mdiClient;

class MdiClient extends Model {

    constructor(data) {
        /** Configure your model here **/
        super(data, { // params object and any properties inside are optional. Include only what you need or nothing at all
            // Only fields in _fillable will be writeable. Empty or absent _fillables  will not evaluate at all
            _fillable: [
                'token', 'workFlowData', 'workFlowDataDataMobile', 'selectedCountryCode', 'selectedDocumentID', 'OTP', 'documentType', 'documentSize',
                'documentIDOptions', 'rejectionReasons', 'documentProperties', 'IDFront', 'IDBack', 'selfie',
                'canDoFacematch', 'facematch', 'uploads', 'workflowId', 'idvOcrData', 'thalesProcessResult', 'thalesFaceResult', 'thalesDocumentResult', 'isLoading',
                'stepsCompleted', 'livenessPassed', 'rejectReason', 'sendDocumentsToAgentResult', 'startRecordingResult', 'showSubmitModal', 'showConfirmDialog',
                'documentsSentToAgent', 'isExtraDataSent', 'isWorkflowClosed', 'isRecording', 'closeWorkflowResult',
            ],
            // Fields in _guarded will not be writeable
            _guarded: [],
            _log: new Logger({printTimestamp: false}),
            // Fields in _hidden will not be included in the "values" (values returns an object with this instance's data fields) result
            _hidden: ['createdAt', 'updatedAt'],
            // Fields in _casts will be type cast before returned. e.g. in the example below id property will be returned as string regardless
            _casts: {
                id: String,
                isPaid: Number,
                paidAt: Boolean
            },
            // Specify custom getters and setters for fields. If a field is not in _overrides, the default getter and setter will be used.
            _overrides : {
                createdAt: {
                    get: () => {
                        return this["_createdAt"].toLocaleString() + " CUSTOM GETTER SUFFIX";
                    },
                    set: (newValue) => {
                        this["_createdAt"] = new Date(newValue)
                    }
                },
                documentIDOptions: {
                    get: () => documentIDOptions
                },
                rejectionReasons: {
                    get: () =>  rejectionReasons
                },
                documentProperties: {
                    get: () => documentProperties
                },
                documentType: {
                    get: () => {
                        if (this.selectedDocumentID && this.documentProperties[this.selectedDocumentID - 1]) {
                            if (this.documentProperties[this.selectedDocumentID - 1].greekId) {
                                return 'ID'
                            }
                            return 'Passport'
                        }
                        return null
                    }
                },
                documentSize: {
                    get: () => {
                        if (this.selectedDocumentID && this.documentProperties[this.selectedDocumentID - 1]) {
                            if (this.documentProperties[this.selectedDocumentID - 1].greekId) {
                                return 'CUSTOM'
                            }
                            return 'TD3'
                        }
                        return null
                    }
                },
                canDoFacematch: {
                    get: () => {
                        if ( this.getSelectedDocumentProperty() && this.selectedCountryCode ) {
                            if ( this.documentProperties[this.selectedDocumentID - 1]?.twoSide ) {
                                if ( this.IDFront.uuid && this.IDFront.imageUri &&
                                    this.IDBack.uuid && this.IDBack.imageUri &&
                                    this.selfie.uuid && this.selfie.imageUri
                                ) {
                                    return true
                                }
                            } else {
                                if ( this.IDFront.uuid && this.IDFront.imageUri &&
                                    this.selfie.uuid && this.selfie.imageUri
                                ) {
                                    return true
                                }
                            }
                        }
                        return false
                    }
                },
                idvOcrData: {
                    get: () => {
                        if ( this.facematch && this.selectedCountryCode ) {
                            const fm = this.facematch
                            if ( isObject(fm.data?.processResult?.documentResult?.ocrTextFields) ) {
                                return {
                                    ...fm.data.processResult.documentResult.ocrTextFields,
                                    IssueAuthority: this.selectedCountryCode
                                }
                            }
                        }
                        return null
                    }
                },
                thalesProcessResult: {
                    get: () => {
                        if ( this.facematch ) {
                            return {
                                id: this.facematch.data?.id,
                                name: this.facematch.data?.name,
                                code: this.facematch.data?.processResult?.code,
                            }
                        }
                        return null
                    }
                },
                thalesFaceResult: {
                    get: () => {
                        if ( this.facematch ) {
                            return {
                                result: this.facematch.data?.processResult?.faceResult?.result || 'Failed',
                                score: this.facematch.data?.processResult?.faceResult?.score || 'Failed',
                            }
                        }
                        return null
                    }
                },
                thalesDocumentResult: {
                    get: () => {
                        if ( this.facematch ) {
                            return {
                                result: this.facematch.data?.processResult?.documentResult?.result,
                                description: ['0', '1', '2', '3'].includes(this.facematch.data?.processResult?.documentResult?.result) ?  DOCUMENT_PROCESSING_RESULT[parseInt(this.facematch.data?.processResult?.documentResult?.result)] : 'Failed',
                            }
                        }
                        return null
                    }
                },
                documentsSentToAgent: {
                    get: () => {
                        return !!this.sendDocumentsToAgentResult
                    }
                },
                isExtraDataSent: {
                    get: () => {
                        return !!this.sendExtraDataResult
                    }
                },
                isWorkflowClosed: {
                    get: () => {
                        return !!this.closeWorkflowResult
                    }
                },

                isRecording: {
                    get: () => {
                        return !!this.startRecordingResult
                    }
                },
                canSubmitResults: {
                    get: () => {
                        return this.documentsSentToAgent && this.thalesDocumentResult && this.thalesFaceResult && this.thalesProcessResult
                    }
                }

            }
        });

        if (!this.IDFront) {
            this.IDFront = {
                uuid: null,
                imageUri: null,
                crop: null
            }
        }
        if ( !this.IDBack ) {
            this.IDBack = {
                uuid: null,
                imageUri: null,
                crop: null
            }
        }
        if ( !this.selfie) {
            this.selfie = {
                uuid: null,
                imageUri: null
            }
        }
        this.stepsCompleted = !!this.stepsCompleted
        this.livenessPassed = !!this.livenessPassed
        if ( this.showConfirmDialog !== false && this.showConfirmDialog !== true ) {
            this.showConfirmDialog = false
        }
    }
    get log() {
        return this._log
    }

    setOcrDataMobile = (number) => {
        this.ocrDataMobile = number;
        this.save();
    }

    setSelectedCountryCode = (event) => {
        Logger.console("selectedCountryCode", event?.target?.value)
        if ( event?.target?.value && this.selectedCountryCode !== event.target.value ) {
            this.selectedCountryCode = event.target.value
            this.resetScreenshots()
        }
    }

    setSelectedDocumentID = (event) => {
        try {
            const value = parseInt(event?.target?.value)
            Logger.console("selectedDocumentID", value)
            if ( this.selectedDocumentID !== value ) {
                this.selectedDocumentID = value
                this.resetScreenshots()
            }
        } catch (e) {
            this.log.error(e, "setSelectedDocumentID Exception")
        }
    }
    setRejectReason = (event) => {
        try {
            const value = event?.target?.value
            Logger.console("rejectReason", value)
            if ( this.rejectReason !== value ) {
                this.rejectReason = value
                this.save()
            }
        } catch (e) {
            this.log.error(e, "setRejectReason Exception")
        }
    }

    toggleStepsCompleted = () => {
        this.stepsCompleted = !this.stepsCompleted
        this.save()
    }
    toggleLivenessPassed = () => {
        this.livenessPassed = !this.livenessPassed
        this.save()
        // MdiClient.sendExtraData()
    }

    submitResults = async () => {
        const result = await MdiClient.
        sendExtraData()
        if ( result ) {
            this.notify("Results submitted successfully", "success", 5000)
        } else {
            this.notify("There was an error submitting results", "error", 5000)
        }
    }

    resetScreenshots = () => {
        this.IDFront.uuid = null
        this.IDFront.imageUri = null
        this.IDFront.crop = null
        this.IDBack.uuid = null
        this.IDBack.imageUri = null
        this.IDBack.crop = null
        this.selfie.uuid = null
        this.selfie.imageUri = null
        this.selfie.crop = null
        this.save()
    }
    captureFrontIDScreenshot = () => this.captureRemoteVideoScreenshot('IDFront')
    captureBackIDScreenshot = () => this.captureRemoteVideoScreenshot('IDBack')
    captureSelfieScreenshot = () => this.captureRemoteVideoScreenshot('selfie')
    captureRemoteVideoScreenshot = (type) => {
        if ( (type === 'IDFront' || type === 'IDBack' || type === 'selfie') ) {
            if ( type === 'IDFront' || type === 'IDBack' ) {
                if ( !this.selectedDocumentID ) {
                    this.notify('Please select Document ID', 'warn', 4000)
                    return
                } else if ( !this.selectedCountryCode ) {
                    this.notify('Please select Issuing Country', 'warn', 4000)
                    return
                }
            }
            try {
                this[type].imageUri = Vidyo.takeRemoteVideoScreenshot()
                this[type].uuid = uuid()
                this[type].crop = null
            } catch (e) {
                this[type].imageUri = null
                this[type].uuid = null
                this[type].crop = null
            } finally {
                this.save()
            }
        }
    }

    setIDFrontCrop = (crop, percentCrop) => {
        Logger.console("setIDFrontCrop", crop, percentCrop)
        this.IDFront.crop = crop
        this.save()
    }
    setIDBackCrop = (crop, percentCrop) => {
        this.IDBack.crop = crop
        this.save()
    }
    setSelfieCrop = (crop, percentCrop) => {
        this.selfie.crop = crop
        this.save()
    }

    cropIDFront = () => {
        this.cropScreenshot('IDFront')
    }
    cropIDBack = () => this.cropScreenshot('IDBack')
    cropSelfie = () => this.cropScreenshot('selfie')

    cropScreenshot = (type) => {
        if ( type === 'IDFront' || type === 'IDBack' || type === 'selfie') {
            if ( this[type].uuid && this[type].crop?.width && this[type].crop?.height) {
                const image = document.getElementById(this[type].uuid)
                if ( image ) {
                    const newImage = new Image();
                    newImage.onload = () => {
                        const canvas = document.createElement('canvas');
                        const scaleX = image.naturalWidth / image.width;
                        const scaleY = image.naturalHeight / image.height;
                        canvas.width = this[type].crop.width * scaleX;
                        canvas.height = this[type].crop.height * scaleY;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(
                            newImage,
                            this[type].crop.x * scaleX,
                            this[type].crop.y * scaleY,
                            this[type].crop.width * scaleX,
                            this[type].crop.height * scaleY,
                            0,
                            0,
                            this[type].crop.width * scaleX,
                            this[type].crop.height * scaleY
                        );
                        const base64Image = canvas.toDataURL('image/jpeg');
                        Logger.console(" CROPPED base64", base64Image)
                        this[type].imageUri = base64Image
                        this[type].crop = null
                        this.save()
                    }
                    newImage.src = this[type].imageUri
                }
            }
        }
    }
    doFaceMatching = async () => {
        if ( this.selectedDocumentID && this.selectedDocumentID > 0 &&
            this.documentProperties[this.selectedDocumentID - 1] ) {
            const documentTypesToUpload = this.getRequiredDocumentTypesForFaceMatching()
            const documentsValidation = this.validatedRequiredDocumentTypesForFaceMatching()
            if ( documentTypesToUpload.length > 0 && documentsValidation ) {

                try {
                    if ( this.tokenHasExpired() ) {
                        const newToken = await MdiClient.getToken()
                        if ( !newToken ) {
                            this.notify("Facematch: Failed to refresh token", 'error')
                            return
                        }
                    }
                    const uploadResult = await this._uploadAllDocuments()
                    Logger.console("UPLOAD ALL DOCUMENTS RESULT", uploadResult)
                    let documentsUploaded = 0
                    documentTypesToUpload.forEach( dt => {
                        if ( uploadResult[dt] ) {
                            documentsUploaded++;
                        }
                    })
                    if ( documentsUploaded === documentTypesToUpload.length ) {
                        this.log.request('starting facematching request')
                        const payload = {
                            providerName: 'Uniken',
                            workFlowId: this.workflowId,
                            RequestData: {
                                CaptureMethod: 'Scanner',
                                DocumentType: this.documentType,
                                DocumentSize: this.documentSize,
                                DocumentFormat: 'JPEG',
                                DocFrontPageStorageId: this.IDFront.uuid || '',
                                DocBackPageStorageId: this.IDBack.uuid || '',
                                FaceImageStorageId: this.selfie.uuid || '',
                            }
                        }
                        const faceMatchResult = await MdiClient.getFacematchResult(payload)
                        this.log.warning('FACEMATCH RESULT')
                        Logger.console("facematch result | ", faceMatchResult)
                        this.notify(
                          <div>
                              Facematch finished
                          </div>, 'success', true
                        )
                        // TODO: Possibly remove the sendDocumentsToAgent() step
                        const sendDocumentsToAgentResult = await MdiClient.sendDocumentsToAgent()
                        this.log.warning('SEND DOCUMENTS TO AGENT RESULT')
                        Logger.console("send documents to agent result | ", sendDocumentsToAgentResult)
                        this.notify(
                          <div>
                              Documents sent to agent
                          </div>, 'success', true
                        )
                    } else {
                        this.log.error(`'FaceMatching Error: Some documents failed to upload. Successful uploads: ${documentsUploaded}/${documentTypesToUpload.length}`)
                        this.notify(
                            <div>
                                FaceMatching Error: Some documents failed to upload
                                <br/>
                                Uploaded: {documentsUploaded}/{documentTypesToUpload.length}
                            </div>, 'error', true)
                    }

                } catch (e) {
                    this.log.error(e.message, "MdiClient@doFaceMatching Exception")
                    this.notify(
                        <div>
                            FaceMatching Error: Something went wrong
                            <br/>
                            Exception: {e.message}
                        </div>, 'error', true)
                }
            } else {
                Logger.console("documentTypesToUpload", documentTypesToUpload)
                Logger.console("documentsValidation", documentsValidation)
                this.notify(
                    <div>
                        FaceMatching Error:
                        <br/>
                        {documentTypesToUpload.length === 0 ? 'No documents specified in template' : 'Documents are not valid'}
                    </div>, 'error', true)
            }
        } else {
            this.notify('Please select Document ID', 'warn', 4000)
        }
    }

    getRequiredDocumentTypesForFaceMatching = () => {
        const result = []
        if ( this.selectedDocumentID > 0 && this.documentProperties[this.selectedDocumentID - 1] ) {
            result.push('IDFront')
            result.push( 'selfie')
            if ( this.documentProperties[this.selectedDocumentID - 1].twoSide ) {
                result.push('IDBack')
            }
        }
        return result
    }
    validatedRequiredDocumentTypesForFaceMatching = () => {
        const documentTypesToUpload = this.getRequiredDocumentTypesForFaceMatching()
        let result = documentTypesToUpload.length > 0
        documentTypesToUpload.forEach(documentType => {
            if ( !this[documentType].uuid && !this[documentType].imageUri ) {
                result = false
            }
        })
        return result
    }
    removeIdFrontImage = () => {
        this.IDFront.imageUri = null
        this.IDFront.uuid = null
        this.IDFront.crop = null
        this.save()
    }
    removeIdBackImage = () => {
        this.IDBack.imageUri = null
        this.IDBack.uuid = null
        this.IDBack.crop = null
        this.save()
    }
    removeSelfieImage = () => {
        this.selfie.imageUri = null
        this.selfie.uuid = null
        this.selfie.crop = null
        this.save()
    }

    /**
     * Sends the already generated this.OTP using m-stat SMS service (locally implemented.
     * @returns {Promise<void>}
     */
    sendOtpSms = async () => {
        const mobile = this.ocrDataMobile?.replace(/-/g, '');
        if ( mobile && mobile.length >= 10 ) {
            if ( this.OTP ) {
                const subject = 'Mellon KYC'
                const message = `Your otp is: ${this.OTP}`
                const result = await MdiService.sendSMS(mobile, subject, message)
                Logger.console("MdiClient@sendOtpSms result", result)
                if ( result ) {
                    this.notify(<div>OTP: {this.OTP}<br/>Phone: {mobile}</div>, 'dark')
                } else {
                    this.notify(<div>SMS failed for Phone Number: {mobile}</div>, 'error')
                }
            } else {
                this.notify(<div>OTP has not been generated: {this.OTP}</div>, 'error')
            }
        } else {
            this.notify(<div>Invalid Phone Number: {mobile}</div>, 'error')
        }
    }

    /**
     * Requests an OTP from the OTP Service. The OTP Service generates the OTP and sends it with SMS/Viber
     * to the provided phone number before returning it in the response.
     * @returns {Promise<void>}
     */
    requestOtpSms = async () => {
        this.OTP = ''
        const mobile = this.ocrDataMobile?.replace(/-/g, '');
        const workflowId = this.workflowId
        if ( mobile && mobile.length >= 10 ) {
            if ( this.tokenHasExpired() ) {
                const newToken = await MdiClient.getToken()
                if ( !newToken ) {
                    this.notify("MdiClient@requestOtpSms: Failed to refresh token", 'error')
                    return
                }
            }
            const state = store.getState();
            const mdiClient = state.mdiClient
            const response = await MdiService.requestOtp(mobile, workflowId, mdiClient?.data?.token)
            Logger.console("MdiClient@requestOtp result", response)
            if ( response?.result === "OK" ) {
                this.OTP = response.message
                this.notify(<div>OTP: {response.message}<br/>Phone: {mobile}</div>, 'dark')
            } else {
                this.notify(<div>SMS failed for Phone Number: {mobile}<br/>{response?.message || ''}</div>, 'error')
            }
        } else {
            this.notify(<div>Invalid Phone Number: {mobile}</div>, 'error')
        }
    }
    /**
     * Generates a random 6-digit number as a string and saves it in this.OTP before returning it.
     * @returns {string}
     */
    generateOtp = () => {
        const digits = '0123456789';
        let otp = ''
        for (let i = 0; i < 6; i++) {
            otp += digits[Math.floor(Math.random() * 10)];
        }
        this.OTP = otp;
        this.save()
        return otp
    }

    notify = (message, type = '', autoClose = false) => {
        const types = ['success', 'error', 'warn', 'info', 'dark', '']
        if ( !types.includes(type) ) {
            type = ''
        }
        toast[type](message, {
            position: 'bottom-left',
            autoClose: autoClose,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    tokenHasExpired = () => {
        if ( this.token ) {
            const decodedToken = Request.decodeJwt(this.token)
            if ( decodedToken && decodedToken.exp) {
                const now = new Date()
                if ( decodedToken.exp >= now.valueOf() / 1000 ) {
                    return true
                }
            }
        }
        return false
    }

    _uploadIDFrontDocument = async () => {
        return await this._uploadDocument('IDFront')
    }
    _uploadIDBackDocument = async () => {
        return await this._uploadDocument('IDBack')
    }
    _uploadSelfieDocument = async () => {
        return await this._uploadDocument('selfie')
    }

    getSelectedDocumentProperty = () => {
        if ( this.documentProperties && this.selectedDocumentID && this.selectedDocumentID > 0 && this.documentProperties[this.selectedDocumentID - 1] ) {
            return this.documentProperties[this.selectedDocumentID - 1]
        }
        return null
    }
    // TODO: link to view documents at http://mdihub.westeurope.cloudapp.azure.com/DocumentView?documentid={workflowId}
    // TODO: Check and validate facematch and sendDocumentsToAgent results. Show corresponding notifications
    _uploadAllDocuments = async () => {
        const selectedDocumentProperty = this.getSelectedDocumentProperty()
          const result = {};
        if ( selectedDocumentProperty ) {
            const uploadFrontResult = await this._uploadIDFrontDocument()
            const uploadSelfieResult = await this._uploadSelfieDocument()

            result.IDFront = uploadFrontResult
            result.selfie = uploadSelfieResult

            if ( selectedDocumentProperty.twoSide ) {
                result.IDBack = await this._uploadIDBackDocument()
            }
        }
        this.log.mag(result, "_uploadAllDocuments result")
        return result
    }
    _uploadDocument = async (type) => {
        if ( ['IDFront', 'IDBack', 'selfie'].includes(type) && this[type].uuid && this[type].imageUri ) {
            try {
                const documentProperties = this.documentProperties[this.selectedDocumentID - 1]

                if ( documentProperties ) {
                    const templateId = this.getTemplateId(type)
                    const documentTypeId = this.getDocumentTypeId(type);
                    const storageTypeId = this.getStorageTypeId(type)
                    const providerName = this.getDocumentProviderName(type)
                    const ocrProviderName = this.getOcrDocumentProviderName(type)
                    if ( templateId && documentTypeId && storageTypeId) {
                        const payload = {
                            providerName: providerName,
                            ocrProviderName: ocrProviderName,
                            workFlowId: this.workflowId,
                            data: {
                                id: this[type].uuid,
                                templateId: templateId,
                                documentBase64: this[type].imageUri?.split(',')[1],
                                documentType: documentTypeId,
                                storageType: storageTypeId,
                                token: this.token
                            }
                        }
                        return await MdiClient.uploadDocument(type, payload)
                    }
                }
                notify("Missing Documents Configuration", "error", 5000)
                return null
            } catch (e) {
                this.log.error(e.message, "MdiClient@_uploadDocument Exception")
                return null
            }
        }
    }

    getTemplateId = (type) => {
        if ( ['IDFront', 'IDBack', 'selfie'].includes(type) ) {
            if ( this.selectedDocumentID && this.documentProperties[this.selectedDocumentID - 1]) {
                if ( type === 'IDFront' ) {
                    return this.documentProperties[this.selectedDocumentID - 1].frontSideTemplateId
                }
                if ( type === 'IDBack' ) {
                    return this.documentProperties[this.selectedDocumentID - 1].backSideTemplateId
                }
                if ( type === 'selfie' ) {
                    return selfieStorageDocumentProperties.templateId
                }
            }
        }
        return null
    }
    getDocumentTypeId = (type) => {
        if ( ['IDFront', 'IDBack', 'selfie'].includes(type) ) {
            if ( this.selectedDocumentID && this.documentProperties[this.selectedDocumentID - 1]) {
                if ( type === 'IDFront' || type === 'IDBack' ) {
                    return this.documentProperties[this.selectedDocumentID - 1].documentTypeId
                }
                if ( type === 'selfie' ) {
                    return selfieStorageDocumentProperties.templateId
                }
            }
        }
        return null
    }
    getStorageTypeId = (type) => {
        if ( ['IDFront', 'IDBack', 'selfie'].includes(type) ) {
            if ( this.selectedDocumentID && this.documentProperties[this.selectedDocumentID - 1]) {
                if ( type === 'IDFront' || type === 'IDBack' ) {
                    return this.documentProperties[this.selectedDocumentID - 1].storageTypeId
                }
                if ( type === 'selfie' ) {
                    return selfieStorageDocumentProperties.storageTypeId
                }
            }
        }
        return null
    }
    getDocumentProviderName = (type) => {
        if ( ['IDFront', 'IDBack', 'selfie'].includes(type) ) {
            if ( this.selectedDocumentID && this.documentProperties[this.selectedDocumentID - 1]) {
                if ( type === 'IDFront' || type === 'IDBack' ) {
                    return this.documentProperties[this.selectedDocumentID - 1].providerName
                }
                if ( type === 'selfie' ) {
                    return selfieStorageDocumentProperties.providerName
                }
            }
        }
        return null
    }
    getOcrDocumentProviderName = (type) => {
        if ( ['IDFront', 'IDBack', 'selfie'].includes(type) ) {
            if ( this.selectedDocumentID && this.documentProperties[this.selectedDocumentID - 1]) {
                if ( type === 'IDFront' || type === 'IDBack' ) {
                    return this.documentProperties[this.selectedDocumentID - 1].ocrProviderName
                }
                if ( type === 'selfie' ) {
                    return selfieStorageDocumentProperties.ocrProviderName
                }
            }
        }
        return null
    }
    setWorkflowId = (workflowId) => {
        this.workflowId = workflowId
        this.save()
    }

    save = () => {
        this.saveChanges(type);
    };

    /** SELECTORS **/
    static getMdiClient = createSelector(mdiClient, mdiClientState => {
        return mdiClientState?.data ? new MdiClient(mdiClientState.data) : null;
    });
    static isLoading = createSelector(mdiClient, mdiClientState => {
        return mdiClientState?.isLoading;
    });
    static getOcrDataMobile = createSelector(mdiClient, mdiClientState => {
        return mdiClientState.data?.workFlowDataMobile || ''
    });

    setShowSubmitModal = (show) => {
        if ( show === true || show === false ) {
            if ( this.showSubmitModal !== show ) {
                this.showSubmitModal = show
                this.save()
            }
        } else {
            this.showSubmitModal = !this.showSubmitModal
            this.save()
        }
    }
    setShowConfirmDialog = (show) => {
        if ( show === true || show === false ) {
            if ( this.showConfirmDialog !== show ) {
                this.showConfirmDialog = show
                this.save()
            }
        } else {
            this.showConfirmDialog = !this.showConfirmDialog
            this.save()
        }
    }
    redirectToMaster = () => {
        const host = process.env.REACT_APP_MDV_HOST
        const url = `${host}/DocumentView?documentid=${this.workflowId}`
        if ( this.isWorkflowClosed ) {
            window.location.href = url;
            // window.open(
            //   url,
            //   '_blank',
            //   'location=yes,height=570,width=520,scrollbars=yes,status=yes'
            // );
        } else {
            this.notify('Workflow is not closed!', 'error', false)
        }
    }

    /** ACTIONS **/
    static getToken = () => {
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(type, () => MdiService.getToken(), {
                subtype: type.GET_TOKEN,
            }),
        );
    };
    static getOcrData = (workflowId) => {
        const state = store.getState();
        const mdiClient = state.mdiClient
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(type, () => MdiService.getOcrData(workflowId, mdiClient?.data?.token), {
                subtype: type.GET_OCR_DATA,
            }),
        );
    };
    static getWorkFlow = (workflowId) => {
        const state = store.getState();
        const mdiClient = state.mdiClient
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(type, () => MdiService.getWorkflow(workflowId, mdiClient?.data?.token), {
                subtype: type.GET_WORKFLOW,
            }),
        );
    };
    static getFacematchResult = (payload) => {
        const state = store.getState();
        const mdiClient = state.mdiClient

        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(type, () => MdiService.getFacematchResult(payload, mdiClient?.data?.token), {
                subtype: type.GET_FACEMATCH_RESULT,
            }),
        );
    };
    static uploadDocument = (documentType, payload) => {
        const state = store.getState();
        const mdiClient = state.mdiClient

        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(type, async () => {
                const result = await MdiService.uploadDocument(payload, mdiClient?.data?.token)
                return {
                    documentType,
                    result
                }
            }, {
                subtype: type.UPLOAD_DOCUMENT,
            }),
        );
    };
    static sendDocumentsToAgent = () => {
        const state = store.getState();
        const token = state.mdiClient?.data?.token
        const mdiClient = new MdiClient(state?.mdiClient?.data)

        if ( mdiClient?.workflowId ) {
            const documentIds = [mdiClient?.IDFront?.uuid, mdiClient?.selfie?.uuid]
            if ( mdiClient.getSelectedDocumentProperty().twoSide ) {
                documentIds.push(mdiClient?.IDBack?.uuid)
            }
            let idvOcrExtracted = {}
            if ( mdiClient?.facematch?.data?.processResult?.documentResult?.ocrTextFields ) {
                idvOcrExtracted = mdiClient.facematch.data.processResult.documentResult.ocrTextFields
            }
            idvOcrExtracted.IssueAuthority = mdiClient?.selectedCountryCode
            const useUnikenOcr = mdiClient?.getSelectedDocumentProperty()?.frontSideTemplateId === 1

            return this.dispatchAsync(
              dispatchHelper.dispatchRequest(type,
                () => MdiService.sendDocumentsToAgent(documentIds, idvOcrExtracted, mdiClient.workflowId, useUnikenOcr, token), {
                    subtype: type.SEND_DOCUMENTS_TO_AGENT,
                }),
            );
        }
    };

    static sendExtraData = () => {
        const state = store.getState();
        const token = state.mdiClient?.data?.token
        const mdiClient = new MdiClient(state?.mdiClient?.data)
        if ( mdiClient?.workflowId ) {
            const {workflowId, livenessPassed, stepsCompleted, rejectReason} = mdiClient
            return this.dispatchAsync(
              dispatchHelper.dispatchRequest(type,
                () => MdiService.sendExtraData(workflowId, livenessPassed, stepsCompleted, rejectReason, token),
                {subtype: type.SEND_EXTRA_DATA}
              ),
            );
        }
    };

    static closeWorkflow = async () => {
        const state = store.getState();
        const token = state.mdiClient?.data?.token
        const mdiClient = new MdiClient(state?.mdiClient?.data)
        if ( mdiClient?.workflowId ) {
            const result = await this.dispatchAsync(
              dispatchHelper.dispatchRequest(type,
                () => MdiService.closeWorkflow(mdiClient.workflowId, token),
                {subtype: type.CLOSE_WORKFLOW}
              ),
            );
            // mdiClient.closeWorkflowResult = result;
            // mdiClient.save();
            return result
        }
    };
    static startRecording = async () => {
        if (  process.env.REACT_APP_ENABLE_VIDEO_RECORDING === 'true' ) {
            try {
                const state = store.getState();
                const token = state.mdiClient?.data?.token
                const mdiClient = new MdiClient(state?.mdiClient?.data)
                if ( mdiClient?.workflowId ) {
                    // TODO: Check recording response and use it to show recording light
                    const recordingResponse =  await this.dispatchAsync(
                      dispatchHelper.dispatchRequest(type,
                        () => MdiService.startRecording(mdiClient.workflowId, token),
                        {subtype: type.START_RECORDING}
                      ),
                    );
                    Logger.console("Start Recording Result", recordingResponse)
                    notify("Recording started", "warn", 5000)
                    return recordingResponse
                }
                notify("Could not start recording! No workflow ID provided", "error")
                return null
            } catch (e) {
                Logger.console("MdiClient@startRecording() Exception", e)
                notify("There was an error starting recording!", "error")
                return null
            }
        }

    };
    static setSelfieImageUri = (val) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(type.SET_SELFIE_IMAGE_URI, val || '')
        );
    };
    static setIDFrontImageUri = (val) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(type.SET_IDFRONT_IMAGE_URI, val || '')
        );
    };
    static setIDBackImageUri = (val) => {
        return store.dispatch(
            dispatchHelper.dispatchCustomAction(type.SET_IDBACK_IMAGE_URI, val || '')
        );
    };
}

export default MdiClient
